/*
 * @Author: xwn 3299704183@qq.com
 * @Date: 2022-11-08 14:56:18
 * @LastEditors: pyl m17798041915@163.com
 * @LastEditTime: 2024-12-23 18:29:21
 * @FilePath: \cloud_campus_Front-end\school_end\src\models\ClassSchedule.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { HTTP } from "@/libs/https.js"
import config from "@/libs/config.js"

class classScheduleModel extends HTTP {

    // warehouseDelete(data) {
    //     return new Promise((resolve, reject) => {
    //         this.axiosPostArray({
    //             url: config.API.WAREHOUSE_DELETE,
    //             data: data,
    //             header: 'application/json',
    //             success(data) {
    //                 resolve({
    //                     data,
    //                     status: 0
    //                 });
    //             },
    //             error(error) {
    //                 resolve({
    //                     error,
    //                     status: - 1
    //                 })
    //             }
    //         });
    //     });
    // }

    getschoolCalendarList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_CALENDAR_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolSchedule(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_SCHEDULE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getBehaviorLabelList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_BEHAVIOR_LABEL_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getBehaviorDataList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_BEHAVIOR_DATA_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }


    getClassroomRecordList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CLASSSROOM_RECORD_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }


    getSchoolCalendar(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_CALENDAR,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getBehaviorLook(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_BEHAVIOR_LOOK,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }


    getAbnormalDetail(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ABNORMAL_DETAIL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }


    getAbnormalSave(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ABNORMAL_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getBehaviorDataSave(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_BEHAVIOR_DATA_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getBehaviorLabelSave(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_BEHAVIOR_LABEL_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getschoolCalendarSave(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_CALENDAR_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolScheduleSave(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_SCHEDULE_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    /**
     * @Description: 巡课记录列表  GET_PATROL_RECORD_LIST
     * @Author: mrz
     * @Date: 2024-08-24 08:49:38
     */
    getPatrolRecordList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PATROL_RECORD_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    /**
     * @Description: 巡课记录-巡课方案列表接口
     * @Author: mrz
     * @Date: 2024-08-24 09:12:40
     */
    getProgrammeList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PROGRAMME_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description: 根据方案id查询巡课对象
     * @Author: mrz
     * @Date: 2024-08-24 16:32:27
     */
    getTargetByProId(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TARGET_BY_PROID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description: 查询所有巡课内容分组信息
     * @Author: mrz
     * @Date: 2024-08-25 16:35:02
     */
    getGroupList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CONTENT_GROUP_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description:  根据方案id获取方案详情
     * @Author: mrz
     * @Date: 2024-08-26 09:40:34
     */
    getPatrolDetailsById(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PATROL_DETAILS_BY_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description:巡课对象班级的下拉列表
     * @Author: mrz
     * @Date: 2024-08-26 20:37:05
     */
    getOrganTreeByClassIds(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ORGAN_TREE_BY_CLASSIDS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description: 保存巡课记录
     * @Author: mrz
     * @Date: 2024-08-27 14:50:19
     */
    savePatrolRecordList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_PATROL_RECORD_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description: 删除巡课记录
     * @Author: mrz
     * @Date: 2024-08-27 15:30:24
     */
    delPatrolRecordList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DEL_PATROL_RECORD_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description: 获取巡课记录详情
     * @Author: mrz
     * @Date: 2024-08-27 19:21:17
     */
    getRecordDetailsById(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_RECORD_DETAILS_BY_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description: 获取学期名称列表
     * @Author: pyl
     * @Date: 2024-11-30 11:19:02
     * @param {*} data
     */    
    getSemesterNameList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SEMESTER_NAME_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description: 获取已设置校历时间的校历id集合
     * @Author: pyl
     * @Date: 2024-11-30 14:53:51
     * @param {*} data
     */    
    getCalendarIdList (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CALENDAR_ID_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    /**
     * @Description: 作息时间导入
     * @Author: pyl
     * @Date: 2024-11-30 15:58:45
     * @param {*} data
     */    
    restTimeUpload (data){
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.REAST_TIME_UPLOAD,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export { classScheduleModel }
