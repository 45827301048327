<!-- 德育管理/德育档案/指标管理 -->
<template>

    <div>
        <tabbed-page
        :tabList="tabList"
        @changeTabs="changeTabs"
        ></tabbed-page>
        <TargetManage  v-if="type===1"/>
        <TargetManageDormitory  v-else/>
    </div>
</template>

<script>
import TabbedPage from '@/components/scrollWrapper/Sub/TabbedPage/index.vue'
import TargetManage from "@/components/scrollWrapper/SManagerEduMoralAssessment/TargetManage.vue";
import TargetManageDormitory from "@/components/scrollWrapper/SManagerEduDormitoryAssessment/TargetManage.vue";
export default {
    name: "SManagerEduMoralTarget",
    components: {
        TargetManage,
        TargetManageDormitory,
        TabbedPage
    },
    props:{
        studentData:{
            type:Object
        }
    },
    data() {
        return {
            currentComp: "TargetManage",
            // studentData: {},
            type: 1,
            detailPageTitle: '',
            tabList: [
                {
                    id: 1,
                    title: '德育指标',
                    vHasPermi: ['moralmanage:moraldocument:rule'],
                    sel: true
                },
                {
                    id: 2,
                    title: '宿舍指标',
                    vHasPermi: ['moralmanage:moraldocument:room:rule'],
                    sel: false
                }
            ],
        }
    },
    activated () {
        this.changeComp({name: this.currentComp, data: this.studentData})
    },
    created () {
        // if (this.$store.state.permissions.includes('moralmanage:moralreport:room')) {
        //     this.tabList.push({
        //         id: 2,
        //         title: '宿舍报表',
        //         sel: false
        //     })
        // }
        this.type = 1;
    },
    methods: {
        changeTabs (data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, 'sel', true)
                    this.type = data.id;
                } else {
                    this.$set(item, 'sel', false)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.tab-switch {
    margin-bottom: 10px;
    margin-top: 1px;
    margin-right: 10px;
    display: flex;
    padding-left: 10px;
    border-radius: 5px;
    align-items: center;
    background-color: #FFFFFF;

    span {
        cursor: pointer;
        margin: 0 20px;
        font-size: 14px;
        line-height: 50px;
    }

    .item-ok {
        color: #3064BF;
        line-height: 48px;
        border-bottom: solid 2px #3064BF;
    }
    .item-oks {
        margin: 0 20px;
        width: 58px;
        height: 50px;
        color: #3064BF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: solid 2px #3064BF;
    }
    .not-ok {
        margin: 0 20px;
        width: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        height: 50px;
        color: #2b2f33;
    }
}
</style>
