<template>
    <div class="common-form">
        <!-- 标题 -->
        <div class="common-form-layout">
            <global-page-back
            detailPageTitle="添加课程表"
            @handlerGlobalType="cancellationForm"
            >
            </global-page-back>
            <div class="add-course-schedule-wrapper">
                <div v-if="!errorShow">
                    <loading :loadingShow="loadingShow"></loading>
                    <div v-if="!loadingShow" class="content-main">
                        <course-schedule-form
                            ref="courseScheduleForm"
                            :ruleForm="ruleForm"
                            :rules="rules"
                            :schedulingClassesList="schedulingClassesList"
                            :subjectList="subjectList"
                            :teacherList="teacherList"
                            :supervisingTeacherList="supervisingTeacherList"
                            :classWeekList="classWeekList"
                            :classTime="classTime"
                            :isRepeat="isRepeat"
                            @addLessonTime="addLessonTime"
                            @delClassWeekItem="delClassWeekItem"
                            @submitForm="submitForm"
                            @cancellationForm="cancellationForm"
                            @scheduleClassIdChange="scheduleClassIdChange"
                            @handlerRepeatClassChange="handlerRepeatClassChange"
                            @handlerScheduleDateChange="handlerScheduleDateChange"
                        >
                        </course-schedule-form>

                        <!-- 选择课时 -->
                        <dialog-wrapper :dialog-obj="lessonTime" @handleClose="closeAddLessonTime">
                            <div class="lesson-time-wrapper">
                                <div class="common-form">
                                    <span class="sel-time">选择课时:</span>
                                    <el-select v-model="lessonTimeVal" placeholder="请选择" class="input-width-medium">
                                        <el-option
                                            v-for="item in lessonTimeList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="item.disabled"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="btn-wrapper">
                                <el-button @click="handlerCancellation">取消</el-button>
                                <el-button type="primary" @click="handlerChangeLessonTimeVal">确认</el-button>
                            </div>
                        </dialog-wrapper>

                        <!-- 选择重复 -->
                        <dialog-wrapper :dialog-obj="repeatResults" @handleClose="closeRepeatResults">
                            <div class="lesson-time-wrapper">
                                <div v-if="isTeaRepeat == '1'" style="color: #363A40">{{teacherName}}教师</div>
                                <template>
                                    <div class="tips" v-for="(item, index) of tips" :key="index">
                                        {{ item.scheduleTime }}  {{' ' + item.className}} {{' ' + item.restTimeName}} {{' ' + item.subjectName}} {{'重复排课'}}
                                    </div>
                                </template>
                                <div style="display: flex">
                                    <span class="sel-time">重复处理:</span>
                                    <el-radio-group v-model="ruleForm.repeatType" @change="handlerRepeatTypeChange" class="custom-lineHeight">
                                        <el-radio label="1">忽略重复信息，覆盖原有排课信息</el-radio>
                                        <el-radio label="2">不覆盖重复排课信息</el-radio>
                                        <el-radio label="3">取消本次排课</el-radio>
                                        <el-radio v-if="isTeaRepeat == '1'" label="4">保留重复排课信息</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="btn-wrapper">
                                <el-button @click="handlerRepeatResultsCancellation">取消</el-button>
                                <el-button type="primary" @click="handlerRepeatResults">确认</el-button>
                            </div>
                        </dialog-wrapper>
                    </div>
                </div>
                <error v-else :errorShow="errorShow"></error>
            </div>
        </div>
    </div>
</template>
<script>
import Title from './CourseScheduleView/Sub/BackTitle.vue';
import CourseScheduleForm from './CourseScheduleView/CourseScheduleForm.vue';
import {
    Error,
    Loading,
    DialogWrapper
} from 'common-local';

import { mapState } from 'vuex';
import {formatTreeData} from "@/libs/utils";

import { TeachingResearchCourseScheduleTempModel } from '@/models/TeachingResearchCourseScheduleTemp.js'
import { CourseScheduleTemp } from '@/operations/teachingResearchCourseScheduleTemp.js'
import GlobalPageBack from '../Sub/GlobalPageBack/index';
import disable$ from 'dingtalk-jsapi/api/ui/pullToRefresh/disable';

export default {
    name: "AddCourseSchedule",
    props: {
        isRepeat: Boolean,
    },
    components: {
        Error,
        Loading,
        Title,
        CourseScheduleForm,
        DialogWrapper,
        GlobalPageBack
    },
    data () {
        return {
            errorShow: false,
            loadingShow: false,
            ruleForm: {
                schoolId: '',
                productId: '',
                classArrangement: '', // 排课方式
                scheduleClassId: '', // 排课班级
                repeatClass: '', // 重复排课方式
                subjectId: '', // 科目
                classTeacherId: '', // 上课教师
                supervisingTeacherId: [], // 督导教师
                startScheduleTime: '', // 开始排课日期
                endScheduleTime: '', // 结束排课日期
                repeatType: '1', // 重复数据处理1覆盖原有2不覆盖3不重复4保留重复排课信息
                list: [],
                scheduleDate: ''
            },
            rules: {
                classArrangement: [{ required: true, message: '请选择', trigger: 'blur' }],
                scheduleClassId: [{ required: true, message: '请选择', trigger: 'change' }],
                repeatClass: [{ required: true, message: '请选择', trigger: 'change' }],
                subjectId: [{ required: true, message: '请选择', trigger: 'change' }],
                classTeacherId: [{ required: true, message: '请选择', trigger: ['blue','change'] }],
                startScheduleTime: [{ required: true, message: '请选择', trigger: 'change' }],
                endScheduleTime: [{ required: true, message: '请选择', trigger: 'change' }],
                scheduleDate: [{ required: true, message: '请选择', trigger: 'change' }],
            },
            /**
             * 表单数据 下拉数据
             * schedulingClassesList 排课班级
             * subjectList 科目
             * teacherList 教师
             * supervisingTeacherList 督导教师
             * classWeekList 上课星期
             * lessonTimeList 课程时间列表
             *
             * lessonTimeVal 选择的课程时间
             * lessonTimePointer 选择课程指针
             *
             * classTime 重复课程指针
             *
             * conditionList 排课星期数据请求
             *
             * */
             schedulingClassesList: [],
             subjectList: [],
             teacherList: [],
             supervisingTeacherList: [],
             classWeekList: [
                {
                    week: '1',
                    label: '周一',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '2',
                    label: '周二',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '3',
                    label: '周三',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '4',
                    label: '周四',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '5',
                    label: '周五',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '6',
                    label: '周六',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '7',
                    label: '周日',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                }
             ],
            /**
             * 上课班级
             * */
             lessonTimeList: [],
             lessonTimeVal: '',
             lessonTimePointer: '',
             conditionList: {
                schoolId: '',
                productId: '',
                classId: '',
                isArrange: ''
             },
             classTime: '7',
            /**
             *  lessonTime 添加课时
             *
             * */
            lessonTime: {
                title: '新增课时',
                dialogVisible: false,
                width: 'auto',
                key: 0,
            },
            /**
             * repeatResults 重复后选择
             *
             * */
             repeatResults: {
                title: '排课时间冲突处理',
                dialogVisible: false,
                width: '760px',
                key: 0,
             },
            /**
             * 校验所选时间段是否在一个校历时间内
             *
             * */
             checkCalendar: {
                schoolId: '',
                productId: '',
                startScheduleTime: '',
                endScheduleTime: ''
             },
            /**
             * saveRepeat 是否 保存并继续排课 指针
             * */
             saveRepeat: '',
            //  排课重复
             tips: [],
            teacherName: '',
            isTeaRepeat: ''
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        })
    },
    created () {
        this.init()
        this.$eventDispatch('setGlobalPageType', 'form')
    },
    mounted() {
        if (!this.isRepeat) {
            this.ruleForm.repeatType = '4'
        }
    },
    methods: {
        /**
         * @Description: 初始化函数
         * @DoWhat: 初始化函数
         * @UseScenarios: 初始化函数
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 09:16:41
         */
        init () {
            this.initPageAssignment()
            this.initPageData()
        },
        /**
         * @Description: 初始化页面赋值
         * @DoWhat: 初始化页面赋值
         * @UseScenarios: 初始化页面赋值
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 09:17:50
         */
        initPageAssignment () {
            this.loadingShow = true;
            this.ruleForm.classArrangement = '1'; // 手工排课
            // 课程表保存接口
            this.ruleForm.schoolId = this.schoolId;
            this.ruleForm.productId = 'cloud_campus';
            // 排课星期请求赋值
            this.conditionList.schoolId = this.schoolId;
            this.conditionList.productId = 'cloud_campus';
            //   校验所选时间段是否在一个校历时间内请求赋值
            this.checkCalendar.schoolId = this.schoolId;
            this.checkCalendar.productId = 'cloud_campus';
        },
        /**
         * @Description: 初始化页面数据
         * @DoWhat: 初始化页面数据
         * @UseScenarios: 初始化页面数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 09:17:46
         */
        async initPageData () {
            // 科目列表
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            await teachingResearchCourseScheduleTempModel.getSchoolSubjectsList({'schoolId': this.schoolId, 'dictKey':"teachingSubjects"}).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'res')
                    this.subjectList = res.data.data
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
            // 班级列表
            await teachingResearchCourseScheduleTempModel.getGradeClassListData({'schoolId': this.schoolId,jobScope:["1","2","3"]}).then((res) => {
                if (res.data.code == '200') {
                    const list = res.data.data.list.map(i => {
                        return {
                            ...i,
                            disabled: i.organType == '3' ? true : false
                        }
                    })
                    let localData = formatTreeData(list, "id", "parentOrg")
                    let newlocaldata =[];
                    for (let index = 0; index < localData.length; index++) {
                        const item = localData[index];
                        if(item.children){
                            newlocaldata.push(item)
                        }
                    }
                    this.schedulingClassesList = newlocaldata
                    console.log('爱上你但', this.schedulingClassesList)
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
            // 教师列表
            await teachingResearchCourseScheduleTempModel.getSchoolTeacherList({'schoolId': this.schoolId, "applyPerson": "1"}).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'督导教师');
                    this.setDisabledOption(res.data.data);
                    this.teacherList = res.data.data;
                    this.supervisingTeacherList = res.data.data;
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })


            await this.finishAjax();
        },
        /**
         * @Description: 写入禁用项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-28 10:09:29
         */
         setDisabledOption (list) {
            list.forEach((item) => {
                if (item.key !== 'teacher') {
                    this.$set(item, 'disabled', true)
                } else {
                    this.$set(item, 'disabled', false)
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption (item.children)
                }
            })
         },
        finishAjax () {
            this.loadingShow = false;
        },
        /**
         * @Description: 新增课时
         * @DoWhat: 添加课程表，新增课时
         * @UseScenarios: 点击打开新增课时
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-08 09:48:03
         */
        addLessonTime (data) {
            this.lessonTimePointer = data.week;

            this.lessonTime.dialogVisible = true;
        },
        /**
         * @Description: 删除排课
         * @DoWhat: 删除排课星期某一项
         * @UseScenarios: 删除排课
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-08 16:04:49
         */
         delClassWeekItem (data) {
            console.log(data,'删除上课星期')
            this.classWeekList.forEach((item) => {
                if(data.dataId === item.week) {
                    item.restTimeId = '';
                    item.scheduleDate = '';
                    item.title = '';
                }
            })
            console.log(this.classWeekList,'this.classWeekList')
         },
        /**
         * @Description: 关闭新增课时
         * @DoWhat: 添加课程表，关闭课时
         * @UseScenarios: 点击关闭课时
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-08 09:46:26
         */
         closeAddLessonTime () {
            this.lessonTime.dialogVisible = false;
            this.lessonTimeVal = '';
            this.lessonTimeList.forEach((item) => {
                this.$set(item, 'disabled', false)
            })
         },

        /**
         * @Description: 重复选择
         * @DoWhat: 重复选择
         * @UseScenarios: 重复选择
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-15 17:20:34
         */
         handlerRepeatTypeChange () {
            if(this.ruleForm.repeatType == '3') {
                this.handlerRepeatResultsCancellation();
                // this.ruleForm.repeatType = ''
            }
         },
        /**
         * @Description: 新增课程表，选择重复
         * @DoWhat: 新增课程表，选择重复
         * @UseScenarios: 新增课程表，选择重复
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 14:46:52
         */
         closeRepeatResults () {
            this.handlerRepeatResultsCancellation();
         },
        /**
         * @Description: 排课信息重复，确认
         * @DoWhat: 排课信息重复，确认
         * @UseScenarios: 排课信息重复，确认
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 14:54:35
         */
         handlerRepeatResults () {
            let cloneData = JSON.parse(JSON.stringify(this.ruleForm));

            let listOri = cloneData['list'].filter((item) => { return item.restTimeId })
            if (listOri.length === 0) {
                this.$message.error('请选择上课排期')
                return false;
            };

            cloneData.list = listOri;
            cloneData.list[0].scheduleDate = cloneData.scheduleDate;
            cloneData.supervisingTeacherId = cloneData.supervisingTeacherId.toString();
            if (Array.isArray(cloneData.scheduleClassId)) {
                cloneData.scheduleClassId = cloneData.scheduleClassId.toString()
            }

            console.log(cloneData, '保存最终发送数据')
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getSchoolCourseSave(cloneData).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data, '保存接口')
                    if (Object.keys(res.data.data).length == 0) {
                        this.$message.success('保存成功');

                        this.handlerRepeatResultsCancellation()

                        if (this.saveRepeat) {
                            this.assemblingSendingData();
                        } else {
                            // 返回列表
                            this.$emit('backCourseSchedule')
                        }


                    }
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
         },
        /**
         * @Description: 排课信息重复，点击关闭，清楚已选择信息
         * @DoWhat: 排课信息重复，点击关闭，清楚已选择信息
         * @UseScenarios: 排课信息重复，点击关闭，清楚已选择信息
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 14:54:44
         */
         handlerRepeatResultsCancellation () {
            this.ruleForm.repeatType = '1';
            this.repeatResults.dialogVisible = false;
         },
        /**
         * @Description: 新增课时时，选择课时
         * @DoWhat: 新增课时时，选择课时
         * @UseScenarios: 新增课时时，选择课时
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-08 10:48:50
         */
         handlerChangeLessonTimeVal () {

            if (!this.lessonTimeVal) {
                this.$message.error('未选择课时')
                return;
            }

            this.classWeekList.forEach((item)=> {
                if (this.lessonTimePointer === item.week) {
                    this.lessonTimeList.forEach((subItem) => {
                        if (this.lessonTimeVal == subItem.id) {
                            item.restTimeId = subItem.id;
                            item.title = subItem.label;
                        }
                    })
                }
            });

            this.ruleForm.list = this.classWeekList;
            this.closeAddLessonTime();
         },
        /**
         * @Description: 新增课时时，选择课时关闭弹窗
         * @DoWhat: 关闭弹窗
         * @UseScenarios: 关闭了弹窗
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-08 11:25:51
         */
         handlerCancellation () {
            this.closeAddLessonTime()
         },

        /**
         * @Description: 取消表单，返回列表页面
         * @DoWhat: 返回列表页面
         * @UseScenarios: 返回列表页面
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-08 11:45:48
         */
         cancellationForm () {
            this.$emit('cancellationForm')
            this.$eventDispatch('setGlobalPageType', 'list')
         },

        /**
         * @Description: 排课班级发生变化，上课星期也变化
         * @DoWhat: 获取上课星期
         * @UseScenarios: 获取上课星期
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 11:56:45
         */
         scheduleClassIdChange () {
            console.log(this.ruleForm, this.classWeekList, 'this.ruleForm-=+')

            this.ruleForm.list.forEach((item) => {
                item['restTimeId'] = '';
                item['scheduleDate'] = '';
                item['title'] = '';
            })

            this.classWeekList.forEach((item) => {
                item['restTimeId'] = '';
                item['scheduleDate'] = '';
                item['title'] = '';
            })

            this.conditionList.classId = Array.isArray(this.ruleForm.scheduleClassId) ? this.ruleForm.scheduleClassId[0] : this.ruleForm.scheduleClassId;
            this.conditionList.isArrange = '1';

            this.emptyData();

            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getClassWeek(this.conditionList).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'上课星期数据')
                    res.data.data.forEach((item) => {
                        let obj = {
                            id: item.id,
                            label: `${item.name} ${item.startTime}-${item.endTime}`,
                            value: item.id,
                            disabled: false
                        };
                        this.lessonTimeList.push(obj)
                    })
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
         },
         emptyData () {
            this.lessonTimeList = [];
         },
        /**
         * @Description: 保存新增课程安排 1. 校验所选时间段是否在一个校历时间内 checkCalendarStatus 2.校验课程是否存在重复 getCourseDuplicationStatus
         * @DoWhat: 保存新增的课程安排
         * @UseScenarios:  保存新增课程安排
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-08 11:43:43
         */
         async submitForm (type) {
            this.saveRepeat = type;
            console.log(this.saveRepeat,'this.saveRepeat')
            if (await this.checkCalendarStatus()) {

                let status = await this.getCourseDuplicationStatus();
                console.log(status, typeof status, '校验结果')
                if (status == 'error') {
                    return false;
                } else if (typeof status == 'string' && status !== 'error') {
                    if (status === 'false') {
                        this.$message.error('请添加课时')
                    }
                } else if (status) {
                    this.ruleForm.repeatType = '1';
                    this.handlerRepeatResults();
                } else {
                    if (sessionStorage.getItem('isRepeat') == null || sessionStorage.getItem('isRepeat') == 'true' || this.isTeaRepeat == '') {
                        this.ruleForm.repeatType = '1'
                    } else {
                        this.ruleForm.repeatType = '4'
                    }
                    this.repeatResults.dialogVisible = true;
                }




            } else {
                console.log('true2')
                this.$message.error('所选时间段未在校历时间内')
            }
            console.log('true3')


            if (type) {

            }
         },
         async checkCalendarStatus () {
            if (this.ruleForm.repeatClass != '0') {
                this.checkCalendar.startScheduleTime = this.ruleForm.startScheduleTime
                this.checkCalendar.endScheduleTime = this.ruleForm.endScheduleTime
            } else {
                this.checkCalendar.startScheduleTime = this.ruleForm.scheduleDate
                this.checkCalendar.endScheduleTime = this.ruleForm.scheduleDate
            }

            let status = false;
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            await teachingResearchCourseScheduleTempModel.getCalibrateCalendarTime(this.checkCalendar).then((res) => {
                if (res.data.code == '200') {
                    status = res.data.data;
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })

            return status;
         },
         async getCourseDuplicationStatus () {
            let cloneData = JSON.parse(JSON.stringify(this.ruleForm)),
                result = false;

            let listOri = cloneData['list'].filter((item) => { return item.restTimeId })
            if (listOri.length === 0) {
                return 'false';
            };

            cloneData.list = listOri;
            cloneData.list[0].scheduleDate = cloneData.scheduleDate;
            cloneData.supervisingTeacherId = cloneData.supervisingTeacherId.toString();
            if (Array.isArray(cloneData.scheduleClassId)) {
                cloneData.scheduleClassId = cloneData.scheduleClassId.toString()
            }
            console.log(listOri,'listOri')
            console.log(cloneData,'cloneData')
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            await teachingResearchCourseScheduleTempModel.getCourseDuplication(cloneData).then((res) => {
                if (res.data.code == '200') {
                    if (typeof res.data.data.flag == 'boolean' && !res.data.data.flag) {
                        console.log('重复排课信息', res.data.data.list)
                        this.tips = res.data.data.list;
                        this.teacherName = res.data.data.list[0].teacherName
                        this.isTeaRepeat = res.data.data.isTeaRepeat
                    }
                    result = res.data.data.flag;
                } else if (res.data.code == '100301') {
                    result = 'error';
                    this.$message.error(res.data.msg);
                }else if (res.data.msg){
                    result = res.data.msg;
                    this.$message.error(res.data.msg)
                }
            })

            return result;
         },
        /**
         * @Description: 重复排课方式。当选择不重复时，修改上课星期
         * @DoWhat: 修改上课星期展示
         * @UseScenarios:
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 16:58:28
         */
         handlerRepeatClassChange (repeatClass) {

            console.log(repeatClass,'repeatClass')
            if (repeatClass == '0') {
                this.classTime = '1'
                // 重置数据
                this.ruleForm.startScheduleTime = '';
                this.ruleForm.endScheduleTime = '';

                let ar1 =  [
                    {
                        week: '',
                        label: '',
                        title: '',
                        scheduleDate: '',
                        restTimeId: '',
                    },
                ];
                ar1[0].label = this.ruleForm.scheduleDate;
                this.classWeekList = ar1;
            } else {

                this.ruleForm.scheduleDate = '';
                this.classTime = '7';

                let ar2 =  [
                    {
                        week: '1',
                        label: '周一',
                        title: '',
                        scheduleDate: '',
                        restTimeId: '',
                    },
                    {
                        week: '2',
                        label: '周二',
                        title: '',
                        scheduleDate: '',
                        restTimeId: '',
                    },
                    {
                        week: '3',
                        label: '周三',
                        title: '',
                        scheduleDate: '',
                        restTimeId: '',
                    },
                    {
                        week: '4',
                        label: '周四',
                        title: '',
                        scheduleDate: '',
                        restTimeId: '',
                    },
                    {
                        week: '5',
                        label: '周五',
                        title: '',
                        scheduleDate: '',
                        restTimeId: '',
                    },
                    {
                        week: '6',
                        label: '周六',
                        title: '',
                        scheduleDate: '',
                        restTimeId: '',
                    },
                    {
                        week: '7',
                        label: '周日',
                        title: '',
                        scheduleDate: '',
                        restTimeId: '',
                    }
                ];

                this.classWeekList = ar2;
            }

            this.ruleForm.list = [];

         },
        /**
         * @Description: 获取排课日期
         * @DoWhat: 获取排课日期后赋值
         * @UseScenarios: 获取排课日期后赋值
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 17:57:31
         */
         handlerScheduleDateChange () {
            console.log(this.ruleForm.scheduleDate, 'ruleForm.scheduleDate')
            this.classWeekList[0].label = this.ruleForm.scheduleDate;
         },
        /**
         * @Description: 重置所有数据
         * @DoWhat: 重置所有数据
         * @UseScenarios: 重置所有数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-14 23:42:38
         */
         assemblingSendingData () {
            this.ruleForm.classArrangement = '1';
            this.ruleForm.scheduleClassId = '';
            this.ruleForm.repeatClass = '';
            this.ruleForm.subjectId = '';
            this.ruleForm.classTeacherId = '';
            this.ruleForm.supervisingTeacherId = [];
            this.ruleForm.startScheduleTime = '';
            this.ruleForm.endScheduleTime = '';
            this.ruleForm.repeatType = '1';
            this.ruleForm.list = [];
            this.ruleForm.scheduleDate = '';

            this.classWeekList = [
                {
                    week: '1',
                    label: '周一',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '2',
                    label: '周二',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '3',
                    label: '周三',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '4',
                    label: '周四',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '5',
                    label: '周五',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '6',
                    label: '周六',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                },
                {
                    week: '7',
                    label: '周日',
                    title: '',
                    scheduleDate: '',
                    restTimeId: '',
                }
             ];
             this.classTime = '7';
             this.$refs.courseScheduleForm.resetForm();
             this.init()
         }
    },
    watch: {
        ruleForm: {
            handler () {
                console.log(this.ruleForm,'ruleForm更改')
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
    .add-course-schedule-wrapper {
        box-sizing: border-box;
        // padding: 10px;
        .content-main {
            background-color: #fff;
            box-sizing: border-box;
            // padding: 0px 20px 16px;
        }
    }
    .lesson-time-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tips {
            display: flex;
            justify-content: center;
            padding: 10px 0 20px 0;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808487;
        }
        .sel-time {
            display: inline-block;
            width: 75px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #363A40;
            padding: 0 10px;
        }
        .custom-lineHeight {
            line-height: 35px;
        }
    }
    .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
</style>
