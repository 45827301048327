<!-- 校园安全-出入校管理-出入记录 -->
<template>
    <div class="center-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <div class="filter-wrap">
                    <expand-filter
                        :closeWidth="1190"
                        :formData="formData"
                        marginBottom="0px"
                        label-width="90px"
                        @clickBtn="clickBtn"
                        @treeClick="filterTreeClick"
                        @treeClear="treeClear"
                        @changeBtnFormType="changeBtnFormType"
                    ></expand-filter>
                    <div class="button-line" v-hasPermi="['accessRecord:export']"></div>
                    <el-button v-hasPermi="['accessRecord:export']" @click="clickBtn({ item: { fn: 'export' } })" :loading="exportLoading">导出</el-button>
                </div>
                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                    @handleSelectionChange="handleSelectionChange"
                >
                    <template #repairCode="{data}">
                        <span
                            style="color: #3c7fff; cursor: pointer"
                            @click="details(data)"
                            v-if="detailState"
                            >{{ data.personName }}</span
                        >
                        <span v-else>{{ data.personName }}</span>
                    </template>
                    <template #img="{data}">
                        <div class="img-list" v-if="data.snapshotImg && data.snapshotImg != ''">
                                <img
                                    :src="data.snapshotImg"
                                    alt=""
                                    :class="data.snapshotImg ? 'img2' : ''"
                                    @click="handleImg(data.snapshotImg)"
                                />
                        </div>
                        <span v-else>-</span>
                    </template>
                </table-data>
                <Pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
                <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
                    <p class="content"><span>姓名</span><span>{{ ruleForm.personName }}</span></p>
                    <p class="content"><span>身份</span><span>{{ ruleForm.personIdentity === '1' ? '学生' : '教职工' }}</span>
                    </p>
                    <p class="content"><span>班级</span><span>{{ ruleForm.organ }}</span></p>
                    <p class="content"><span>出入方式</span><span>{{ ruleForm.accessModeName }}</span></p>
                    <p class="content"><span>出入类型</span><span>{{
                            ruleForm.accessType === '1' ? '出校' : (ruleForm.accessType === '2' ? '入校' : '不区分出入校')
                        }}</span></p>
                    <p class="content"><span>设备位置</span><span>{{ ruleForm.equipmentAddress }}</span></p>
                    <p class="content"><span>出入时间</span><span>{{ ruleForm.signTime }}</span></p>
                    <p v-if="ruleForm.personIdentity === '1'" class="content">
                        <span>接送人</span><span>{{ ruleForm.pickName || ruleForm.pickDropName }}</span></p>
                    <div v-if="ruleForm.personIdentity === '1'" class="content">
                        <div>
                            <span>身份证号</span>
                            <el-tooltip class="item" effect="dark" content="接送人身份证号" placement="top">
                                <i class="el-icon-question" style="cursor: pointer;margin-left: 5px"></i>
                            </el-tooltip>
                        </div>
                        <span>{{ ruleForm.pickIdCard || ruleForm.pickDropIdCard }}</span>
                    </div>
                </dialog-wrapper>
            </div>
        </div>
        <!-- 点击查看图片 -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="handleCloseImg"
            :url-list="[viewPhoto]"
        />
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    Pagination,
    DialogWrapper,
    // 功能组件
    Error,
    Loading,
    debounce
} from 'common-local';
import TableData from "@/components/scrollWrapper/Sub/TableData"
import {CampusSafetyModel} from "@/models/CampusSafety"
import {
    mapState
} from 'vuex';
import ExpandFilter from "./Sub/ExpandFilter";
import {downloadFile, formatTreeData} from "@/libs/utils";
import {TemperatureMonitoringModel} from "@/models/TemperatureMonitoring";
import onResize from "@/mixins/onResize";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
    name: "CampusSafetyAccessManageAccessRecordScroll",
    mixins: [onResize],
    components: {
        TableData,
        Pagination,
        DialogWrapper,
        Error,
        Loading,
        ExpandFilter,
        ElImageViewer
    },
    data() {
        return {
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '出入方式',
                        key: 'accessModeName',
                        list: []
                    }, {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '身份',
                        key: 'personIdentity',
                        list: [
                            {label: '学生', value: '1'},
                            {label: '教职工', value: '2'},
                        ]
                    }, {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '姓名',
                        key: 'personName',
                        list: []
                    }, {
                        type: 'datePick',
                        dateType: 'datetimerange',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        label: '',
                        value: '',
                        key: 'time',
                        width: '340px',
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                    }, {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '出入类型',
                        key: 'accessType',
                        list: [
                            {label: '出校', value: '1'},
                            {label: '入校', value: '2'},
                        ]
                    }, {
                        type: 'treeSelect',
                        label: '',
                        value: '',
                        placeholder: '组织',
                        key: 'organName',
                        list: [],
                        defaultProps: {
                            children: 'children',
                            label: 'name'
                        },
                        expandAll: true
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '设备位置',
                        key: 'equipmentAddress',
                    },{
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '时间标识',
                        key: 'timeFlag',
                        list: [
                            {label: '工作时间', value: '1'},
                            {label: '非工作时间', value: '2'},
                        ]
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'submit',
                        auth:['accessRecord:list']
                    },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset'
                    },
                    // {
                    //     type: "enquiry",
                    //     text: '导出',
                    //     fn: 'export',
                    //     auth:['accessRecord:export']
                    // },
                ]
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "姓名",
                        type: "slot",
                        slotName: "repairCode",
                        align: 'center',
                        labelWidth: '120px',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "身份",
                        prop: "personIdentity",
                        type: 'function',
                        align: 'center',
                        callBack(row) {
                            return {
                                1: '学生',
                                2: '教职工'
                            } [+row.personIdentity]
                        },
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "组织",
                        prop: "organ",
                        type:'popoverText',
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "出入类型",
                        prop: "accessType",
                        type: "function",
                        align: 'center',
                        callBack(row) {
                            return {
                                1: '出校',
                                2: '入校',
                                3: '不区分出入校'
                            } [+row.accessType]
                        }
                    },
                    {
                        label: "抓拍照片",
                        type: "slot",
                        slotName: "img",
                    },
                    {
                        label: "出入方式",
                        prop: "accessModeName",
                        align: 'center',
                    },
                    {
                        label: "设备位置",
                        prop: "equipmentAddress",
                        align: 'center',
                        labelWidth: 140,
                        tooltip: true,
                    },
                    {
                        label: "出入时间",
                        prop: "signTime",
                        type: 'function',
                        align: 'center',
                        callBack(row) {
                            return row.signTime ? row.signTime.slice(0,16):'-'
                        }
                        // labelWidth: '160'
                    },
                    {
                        label: "标识",
                        align: 'center',
                        prop: "timeFlagName",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: true,
                height: ''
            },
            // 表格数据
            table_data: [],
            // 表格选中
            tableListSel: [],
            // 分页器
            total: 0,
            listQuery: {
                schoolId: '',
                pageNum: 1,
                pageRow: 20,
                leaveTime: '',
                admissionTime: '',
                accessModeName: '',
                personIdentity: '',
                personName: '',
                accessType: '',
                organName: '',
                organId: '',
                equipmentAddress: '',
                timeFlag: '',
            },
            // 弹出框配置
            dialogObj: {
                title: '详情',
                dialogVisible: false,
                width: '500px'
            },

            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,

            // 表头
            backgroundHeader: '#FAFBFC',
            // 表头字体
            headerColor: '',
            ruleForm: {
                personName: '',
                personIdentity: '',
                organ: '',
                accessType: '',
                accessModeName: '',
                pickName: '',
                pickIdCard: '',
                signTime: '',
                signTime: '',
                equipmentAddress: ''
            },
            detailState: false,
            showViewer: false,
            viewPhoto: "",
            exportLoading: false
        }
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
            templateInfo: state => state.templateInfo,
            schoolId: state => state.schoolId
        })
    },
    created() {
        this.init()
    },
    mounted() {
    },
    methods: {
        init() {
            if (this.permissions.indexOf("accessRecord:info") !== -1) {
                this.detailState = true;
            }
            this.listQuery.schoolId = this.schoolId
            this.getList()
            this.getTreeOrganData()
            this.getAccessMode()
        },
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            this.loadingTable = true
            let campusSafety = new CampusSafetyModel();
            campusSafety.getAccessRecordList(this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.total = res.data.data.totalCount
                    this.table_data = res.data.data.list.filter(i => i.displayStatus !== '0')
                    this.loadingTable = false
                })
            })
        },
        getTreeOrganData() {
            const temperatureMonitoringModel = new TemperatureMonitoringModel()
            temperatureMonitoringModel.getSchoolOrganList({
                schoolId:this.schoolId
            }).then((res) => {
                this.handleRes(res,()=>{
                    this.formData.data.forEach((item) => {
                        if (item.key === 'organName') {
                            item.list = formatTreeData(res.data.data.list, 'id', 'parentOrg')
                        }
                    })
                })
            })
        },
        getAccessMode() {
            this._fet('/accessSchool/accessRecord/getAccessModeList', {
                schoolId: this.schoolId
            }).then((res) => {
                this.handleRes(res, () => {
                    this.formData.data.forEach((item) => {
                        if (item.key === 'accessModeName') {
                            item.list = res.data.data.map(i => ({
                                label: i,
                                value: i
                            }))
                        }
                    })
                })
            })
        },
        // 选中
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        details(data) {
            console.log(data, this.ruleForm, 'this.ruleForm')
            this.$nextTick(() => {
                Object.keys(this.ruleForm).forEach((key) => {
                    Object.keys(data).forEach((subKey) => {
                        if (key === subKey) {
                            this.ruleForm[subKey] = data[subKey]
                        }
                    })
                })
            })

            this.dialogObj.dialogVisible = true;
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case 'submit': // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    if (this.listQuery.time && this.listQuery.time.length > 0) {
                        this.listQuery.leaveTime = this.listQuery.time[0]
                        this.listQuery.admissionTime = this.listQuery.time[1]
                    } else {
                        this.listQuery.leaveTime = ''
                        this.listQuery.admissionTime = ''
                    }
                    this.getList(1);
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        item.value = '';
                        this.listQuery[item.key] = ''
                    })
                    this.listQuery.organId = ''
                    this.listQuery.leaveTime = ''
                    this.listQuery.admissionTime = ''
                    this.getList(1);
                    break;
                case 'export':
                    this.handleExport()
                    break;
            }
        },
        handleExport() {
            let obj = {
                schoolId: this.schoolId,
                exportType: '2',
            }
            // 表格选中数据
            if (this.tableListSel.length > 0) {
                obj.ids = this.tableListSel.map(i => i.id)
                obj.identification = 2
            } else {
                let filter = this.formData.data.filter(i => i.value !== '')
                // 未选中筛选条件，导出全部
                if (filter.length === 0) {
                    obj.identification = 3
                } else {
                    obj.identification = 1
                    // 选中筛选
                    filter.forEach(item => {
                        if (item.key === 'time') {
                            obj.leaveTime = item.value[0]
                            obj.admissionTime = item.value[1]
                        } else {
                            obj[item.key] = item.value;
                        }
                    })
                }
            }
            this.exportLoading = true;
            downloadFile({url: '/accessSchool/accessRecord/export', form: obj}, () => {
                this.$message.success('导出成功')
                this.exportLoading = false;
            }, () => {

            })
        },
        handleClose() {
            this.dialogObj.dialogVisible = false
        },
        treeClear() {
            this.listQuery.organId = ''
        },
        filterTreeClick(data) {
            this.listQuery.organId = data.data.id
            data.dt.value = data.data.name
            data.selRef.blur()
        },
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
        initTabColor() {
            this.template_info = this.templateInfo;
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    this.headerColor = p.textColor;
                }
            })
        },
        //点击图片放大
         handleImg(data) {
            this.viewPhoto = data;
            this.showViewer = true;
        },
        handleCloseImg() {
            this.showViewer = false;
        },
    },
    watch: {
        'templateInfo': {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.img2 {
    height: 45px;
    border-radius: 8px;
    cursor: pointer;
    object-fit: contain;
}
.center-main {
    padding: 0;
    padding-right: 10px;
}
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}
.content {
    border-bottom: 1px solid #f2f6fe;
    display: flex;
    justify-content: space-between;
    padding: 15px;
}
::v-deep .el-range-editor.el-input__inner{
    padding: 3px;
}
</style>
