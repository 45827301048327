<template>
    <div class="kits-visitor-application">
        <div class="title">
            <img class="transfer-icon" :src="transferIcon" alt="调休图标"/>
            <span class="label">访客申请套件</span>
        </div>
        <div>
            <el-form-item label="对接人姓名" required :label-width="labelWidth">
                <el-select v-model="ruleForm.dockingStation" filterable placeholder="请选择" style="width: 100%">
                    <el-option 
                        v-for="item in dockingStationList" 
                        :key="item.value" 
                        :label="item.label" 
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="对接人手机号" required :label-width="labelWidth">
                根据选择的对接人查询的手机号
            </el-form-item>
            <el-form-item label="出入校方式" required :label-width="labelWidth">
                <el-select v-model="ruleForm.accessStatus" filterable placeholder="请选择" style="width: 100%">
                    <el-option 
                        v-for="item in accessStatusList" 
                        :key="item.value" 
                        :label="item.label" 
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="到访时间" required :label-width="labelWidth">
                <el-time-picker v-model="ruleForm.arrivalTime" placeholder="请选择" style="width: 100%"></el-time-picker>
            </el-form-item>
            <el-form-item label="结束时间" required :label-width="labelWidth">
                到访时间日期的23:59:59
            </el-form-item>
            <el-form-item label="访客姓名" required :label-width="labelWidth">
                <el-input style="width: 100%" v-model="ruleForm.visitorName" placeholder="请输入"></el-input>  
            </el-form-item>
            <el-form-item label="访客手机号" required :label-width="labelWidth">
                <el-input style="width: 100%" v-model="ruleForm.phoneNumber" placeholder="请输入"></el-input>  
            </el-form-item>
               <!-- 人脸识别 -->
            <template v-if="currentState == '1'">
                <el-form-item label="人脸照片" required :label-width="labelWidth">
                    <el-upload
                        class="upload-demo"
                        :action="''"
                        accept=".jpg,.jpeg,.png"
                    >
                        <el-button size="small" type="primary" icon="el-icon-plus">上传人脸照片</el-button>
                    </el-upload>
                </el-form-item> 
            </template>
            <!-- 刷本人身份证 -->
            <template v-if="currentState == '2'">
                <el-form-item label="访客身份证号" required :label-width="labelWidth">
                    <el-input v-model="ruleForm.idNum" placeholder="请输入"></el-input>
                </el-form-item>   
                <el-form-item label="访客身份证照片" required :label-width="labelWidth">
                    <el-upload
                        class="upload-demo"
                        :action="''"
                        accept=".jpg,.jpeg,.png"
                    >
                        <el-button size="small" type="primary" icon="el-icon-plus">上传身份证照片</el-button>
                    </el-upload>
                </el-form-item> 
            </template>
            <!-- 生成二维码 -->
            <template v-if="currentState == '3'"></template>
            <el-form-item label="入校事由" prop="reasonLeave" :required="reasonJoinRequiredType" :label-width="labelWidth">
                <el-input v-model="ruleForm.reasonJoin" style="width: 100%" type="textarea" :rows="2" ></el-input>
            </el-form-item>
        </div>
    </div>
</template>
<script>
    const kitName = 'kitsVisitorApplication'
    export default {
        name: 'KitsVisitorApplication',
        props: {
            reasonsAdmission: {
                type: Number,
                default: 1
            },
            entryExitModes: {
                type: Array,
                default: ['1', '2', '3']
            }
        },
        data() {
            return {
                labelWidth: '126px',
                ruleForm: {
                    dockingStation: '', // 对接人
                    accessStatus: '', // 出入方式
                    arrivalTime: '', // 到访时间
                    visitorName: '', // 访客姓名
                    phoneNumber: '', // 访客手机号
                    reasonJoin: '', // 入校事由
                    idNum: '', // 身份证号
                },
                dockingStationList: [], // 对接人数据
                accessStatusObj: {
                    1: {
                        label: '刷本人身份证',
                        value: '1'
                    },
                    2: {
                        label: '人脸识别',
                        value: '2'
                    },
                    3: {
                        label: '生成二维码',
                        value: '3'
                    }
                }, // 出入校数据
            }
        },
        computed: {
            transferIcon() {
                return require("./img/transfer-icon.png");
            },
            currentState() {
                return this.ruleForm.accessStatus
            },
            reasonJoinRequiredType() {
                return this.reasonsAdmission === 1 ? true : false;
            },
            accessStatusList() {
                let array = [];
                this.entryExitModes.map(item => {
                    array.push(this.accessStatusObj[item]);
                });
                if (!this.entryExitModes.includes(this.ruleForm.accessStatus)) {
                    this.ruleForm.accessStatus = '';
                };
                return array;
            },
            abstaractList() {
                const _this = this;
                let abstaractList = [
                    {
                        label:'对接人姓名',
                        id:'dockerName',
                        required:true,
                        isBelongKit:true,
                        kitKey:kitName
                    },
                    {
                        label:'访客姓名',
                        id:'visitorName',
                        required:true,
                        isBelongKit:true,
                        kitKey:kitName
                    },
                    {
                        label:'到访时间',
                        id:'arrivalTime',
                        required:true,
                        isBelongKit:true,
                        kitKey:kitName
                    },

                ]

                return abstaractList;
            }
        },
        mounted() {
            this.sendAbstract();
        },
        methods: {
            sendAbstract() {
                this.$eventDispatch('handlerKitAbstract', this.abstaractList)
            }
        }
    }
</script>
<style lang="scss" scoped>
.title {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
        display: inline-block;
    }
    .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.el-form-item {
    padding-right: 0 !important;
}
</style>