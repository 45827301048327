import Menu from './pages/Menu/index';
import Button from './pages/Button/index';
import TreeShape from './pages/TreeShape/index';
import KitsLeaveAbsence from './pages/KitsLeaveAbsence/index';
import EvaluateTeachers from './pages/EvaluateTeachers/index';
import EvaluateTeachersBan from './pages/EvaluateTeachersBan/index';
import kitsSpecialLeave from './pages/kitsSpecialLeave/index';
import KitsShiftChange from './pages/KitsShiftChange/index';
import KitsDayStudent from './pages/KitsDayStudent/index';
import KitsVisitorApplication from './pages/KitsVisitorApplication/index';
import Guiding from './pages/Guiding/index'

const components = [
    Menu,
    Button,
    TreeShape,
    KitsLeaveAbsence,
    kitsSpecialLeave,
    KitsShiftChange,
    KitsDayStudent,
    KitsVisitorApplication,
    Guiding,
    EvaluateTeachers,
    EvaluateTeachersBan
]

const install = function (Vue) {
    components.map((comp) => {
        Vue.component(comp.name, comp)
    })
}

export default { install }
