/*
 * @Author: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @Date: 2023-10-10 09:47:10
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-16 16:55:09
 * @FilePath: \cloud_campus_Front-end\school_end\src\assets\data\formDesign\register.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//在此注册组件
const components = [
  'applicant', // 接送人
  'applicantStu', // 接送人
  'pickDropPersons', // 接送人
  'entryExitSelect', // 出入类型
  'approvalFormSelect', // 关联审批单

  'personnelInput', // 人员
  'singleLineText', // 单行文本
  'rate', // 满意度
  'EvaluateTeachers', // 学生评价老师
  'EvaluateTeachersBan', // 学生评价班主任
  'multiLineText', // 多行文本
  'multipleSelect', // 下拉多选
  'radio', // 单选
  'departureTime', // 出校时间
  'returnTime', // 返校时间
  'checkbox', // 复选框
  'phoneNumber', // 手机号
  'number', // 数字
  'IDCard', // 身份证
  'address', // 地址
  'upload', // 图片
  'dateTime', // 日期
  'descriptionText', // 说明文字
  'attachmentUpload', // 附件上传

  'kitsLeaveAbsence', // 套件 请假/调休
  'kitsSpecialLeave', // 套件 特殊请假
  'kitsShiftChange', // 套件 换班
  'kitsDayStudent', // 套件 走读申请
  'kitsVisitorApplication' // 套件 访客申请套件
];


let comps = [];
for (let i in components) {
  const comp = {
    name: components[i],
    content : null
  }
  comps.push(comp);
}
export default comps;
