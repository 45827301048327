/*
    套件-请假/调休
*/
export let kitsLeaveAbsence = {
    id:'',
    _id:'',
    // 是否可拖拽
    draggable: true,
    key: 'kitsLeaveAbsence',
    compType: 'kitsLeaveAbsence',
    ele: 'kits-leave-absence',
    //控件名称(左侧显示)
    compName:'请假/调休套件',
    //展示表单的模式
    viewType:'text',
    // 选项
    options: '1',
    // 可选范围
    selectableRange: '1',
    // 是否可配置
    config: true,
    showLabel:true,
    label: '',
    labelWidth: '0',
    placeholder: '',
    // 最大长度
    maxLength: 50,
    //栅格间隔
    gutter:15,
    //默认栅格
    span:24,
    // 是否必填
    required: true,
    //组件长度
    width: '95%',
    status:'normal',
    value: '',
    hideStatus: true,
    kitLibraryStatus: true,
    isInSetting: 1, // 关联门禁 1是 0否
    reasonLeaveRequired: 0, // 请假事由是否为必填默认为0（非必填）
    fixedTime:{
        'on-off': 0,
        'timeSetting':[{time: ''}]
    },
    showLimit: 0, // 请假时长限制 1是 0否
    limitDuration: 2, // 请假时长
    openInSchool: '0', // 1是 0否 提交时标记学生是否在校（仅针对学生请假）
    openNotCrossDay: '0', // 1是 0否 请假时间不能跨天（仅针对学生请假）
    autoRevoke: 0, // 未审批自动撤销 1是 0否
    autoRevokeTime: 24, // 自动撤销时间
  }

