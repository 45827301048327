<template>
    <div class="subject-upload-score">
        <div class="head">导入方法</div>
        <div class="steps">
            <div class="step-item second">
                <div class="step-item-head">1、请<el-button type="text" @click="downloadTemplate">下载模板</el-button>按照模板填充数据，上传传好的文件</div>
            </div>
            <div class="step-item">
                <div class="step-item-head upload">
                    <el-upload
                        ref="upload"
                        :action="action"
                        :headers="headers"
                        :data="{
                            schoolId: $store.state.schoolId,
                        }"
                        :file-list="importFileList"
                        accept=".xlsx,.xls"
                        :on-change="uploadChange"
                        :on-remove="handleImportRemove"
                        :show-file-list="true"
                        :auto-upload="false"
                        :limit="1"
                    >
                        <span>2、</span>
                        <el-button
                            type="text"
                            margin-left="0"
                            style="font-size: 14px;"
                            >上传文件
                        </el-button>
                    </el-upload>

                </div>
            </div>
        </div>

        <div class="footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button
                type="primary"
                :loading="importLoading"
                @click="handleSubmit"
            >确定</el-button>
        </div>
    </div>
</template>

<script>
import { downloadFile } from "@/libs/utils.js";
import { getToken } from '@/libs/auth'
import { classScheduleModel }from "@/models/ClassSchedule.js";
export default {
    // props: {
    //     data: {
    //         type: Object,
    //         default: () => {}
    //     },
    //     afterSubmit: {
    //         type: [Function, Boolean],
    //         default: false
    //     }
    // },
    data() {
        return {
            importLoading: false,
            importFileList: [],
        }
    },
    computed: {
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    mounted() {
        this.handleImportRemove()
    },
    methods: {
        /**
         * @Description: 下载模板
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 张冰华
         * @Date: 2024-07-22 15:29:04
         */        
        downloadTemplate() {
            this._fet("/school/schoolRestTime/template").then(
                (res) => {
                    if (res.data.code === "200") {
                        downloadFile(
                            {
                                url: res.data.data,
                                method: "get",
                            },
                            () => {
                                this.$message.success("下载模板成功");
                            },
                            () => {},
                        );
                    }
                },
            );
        },
        /**
         * @Description: 处理上传文件变化
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 张冰华
         * @Date: 2024-07-22 15:55:24
         */
        uploadChange(file) {
            // 上传文件前的验证
            const beforeUpload = (file) => {
                // 上传文件前的验证
                const fileType = file.name.substring(
                    file.name.lastIndexOf("."),
                );
                const isXLSXorXLS = [".xlsx", ".xls"].includes(fileType);
                const isShortName = file.name.length < 30;
                const isSizeUnder10M = file.size / 1024 / 1024 < 10;

                if (!isXLSXorXLS) {
                    this.$message.error(`上传文件格式仅支持xlsx和xls!`);
                    return false;
                }

                if (!isShortName) {
                    this.$message.error("上传文件名称不能超过30个字符!");
                    return false;
                }

                if (!isSizeUnder10M) {
                    this.$message.error(`上传文件的大小不能超过10M!`);
                    return false;
                }
            };
            const flag = beforeUpload(file);
            if (flag !== false) {
                this.importFileList = [file];
            } else {
                this.importFileList = [];
            }
        },
        handleImportRemove() {
            this.importFileList = [];
        },
        handleSubmit() {
            if (this.importFileList.length === 0) {
                return this.$message.warning("请上传文件");
            }
            this.importLoading = true;
            let formData = new FormData()
            formData.append('file', this.importFileList[0].raw)
            formData.append('schoolId', this.$store.state.schoolId)
            console.log('刹那的不是', this.importFileList[0].raw)
            console.log('wqedqwdwqdqddwqw', this.$store.state.schoolId)
            console.log('wqedqwdwqdqddwqw', formData)
            const classSchedule = new classScheduleModel()
            classSchedule.restTimeUpload(formData).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success('导入成功');
                    this.$emit("success");
                    this.handleCancel();
                    !!this.afterSubmit && this.afterSubmit();
                } else if (res.data.code === "400") {
                    this.$message.error(res.data.msg);
                } else {
                    this.downloadErrorList(res.data)
                }
            }).finally(() => {
                this.importLoading = false;
            });
        },
        downloadErrorList(data) {
            this.$confirm(data.msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                downloadFile({
                    url: '/school/schoolRestTime/errorExport',
                    method: 'post',
                    fileName: '导入错误信息表.xlsx',
                    form: {paramData: data.info.paramData}
                }, () => {
                }, () => {

                })
            }).catch(() => {
            })
        },
        handleCancel() {
            this.$emit("cancel");
        }
    },
}
</script>

<style lang="scss" scoped>
    .subject-upload-score{
        .steps {
            .step-item {
                .step-item-head {

                    &.upload {
                        ::v-deep .el-upload {
                            display: inline-flex;
                            align-items: center;;
                        }
                    }
                    ::v-deep .el-button--text{
                        padding: 0 5px !important;
                    }
                }
                &.second {
                    margin-top: 10px;
                    .step-item-head {
                        padding-top: 0px;
                    }
                }
            }
        }
        .footer {
            display: flex;
            justify-content: flex-end;
        }
    }
</style>