<template>
    <div class="results-wrapper">
        <img
            class="results-img"
            :src="resultsImg"
            alt="结果"
        />
        <div class="results-info">
            {{ today }}核对结果与检查结果{{ result }}
        </div>
        <div class="results-detail">
            <el-popover
                placement="bottom-start"
                title=""
                width="400"
                trigger="hover"
                :popper-class="checkConsistencyType"
                @show="show"
                @hide="hide"
            >
                <div>
                    <div style="line-height: 24px;">核对结果：共{{resultsInfo.checkTotalNum}}人，应到{{resultsInfo.shouldArriveNum}}人，请假{{resultsInfo.leaveNum}}人，未到{{resultsInfo.notArriveNums}}人</div>
                    <div style="line-height: 24px;">检查结果：实到{{resultsInfo.arriveNum}}人，未到{{resultsInfo.notPresentNames}}人</div>
                </div>
                <div slot="reference">
                    查看详情 <i v-if="visible" class="el-icon-arrow-down"></i>
                    <i v-else class="el-icon-arrow-up"></i>
                </div>
            </el-popover>
        </div>
        <div>{{atBedtimeText}}</div>
    </div>
</template>
<script>
export default {
    name: "Results",
    props: {
        resultsInfo: {
            type: Object,
        },
        listQuery: {
            type: Object,
        },
        atBedtimeText: {
            type: String,
        }
    },
    data() {
        return {
            visible: true,
        };
    },
    computed: {
        resultsImg() {
            if (this.resultsInfo.isFit) {
                return require(`@/assets/images/right.png`)
            } else {
                return require(`@/assets/images/error.png`)
            }
        },
        result () {
            if (this.resultsInfo.isFit) {
                return '一致'
            } else {
                return '不一致'
            }
        },
        today () {
            if (this.listQuery.schedulingDate) {
                return this.listQuery.schedulingDate
            } else {
                let date = new Date();
                return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            }

        },
        checkConsistencyType () {
            return this.resultsInfo.isFit ? 'check-consistency' : 'check-consistency-err'
        }
    },
    methods: {
        show () {
            this.visible = true;
        },
        hide () {
            this.visible = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.results-wrapper {
    display: flex;
    align-items: center;
    height: 52px;
    box-sizing: border-box;
    padding: 0 20px;
    background-color: #fff;
    .results-img {
        width: 18px;
        height: 18px;
    }
    .results-info {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
        margin-left: 9px;
    }
    .results-detail {
        width: 100px;
        margin-left: 24px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #808487;
    }
}
.el-popover {
    padding: 0;
}
.el-icon-arrow-down {
	transition: all ease 1s;
}
.el-icon-arrow-up {
	transition: all ease 1s;
}
</style>
<style>
.check-consistency {
    background: #ebfaf7 !important;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2b3332;
    box-shadow: 0px 6px 12px 0px #E0E0E0;
    border-radius: 6px;
}

.check-consistency[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: #ebfaf7 !important;
}

.check-consistency[x-placement^="bottom"] .popper__arrow {
    border-bottom-color: #ebfaf7 !important;
}

.check-consistency-err {
    background: #faf1f0 !important;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2b3332;
    box-shadow: 0px 6px 12px 0px #E0E0E0;
    border-radius: 6px;
}

.check-consistency-err[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: #faf1f0 !important;
}

.check-consistency-err[x-placement^="bottom"] .popper__arrow {
    border-bottom-color: #faf1f0 !important;
}


</style>
