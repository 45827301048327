<template>
    <div v-show="props.compType === 'kitsVisitorApplication'">
        <div class="doc">
            <img class="transfer-icon" :src="transferIcon" alt="调休图标" />
            <p class="title">访客申请套件</p>
        </div>
        <el-form label-position="top">
            <el-form-item>
                <el-switch v-model="props.showWechat" active-color="#3C7FFF" inactive-color="#CCCCCC" :active-value="1"
                    :inactive-value="0" @change="handlerChangeShowWechat"></el-switch>
                <span class="label">小程序对访客显示</span>
            </el-form-item>
            <el-form-item label="出入校方式配置">
                <el-checkbox-group v-model="props.entryExitModes" @change="handlerChangeEntryExitModes">
                    <el-checkbox label="1">刷身份证</el-checkbox>
                    <el-checkbox label="2">人脸识别</el-checkbox>
                    <el-checkbox label="3">生成二维码</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item>
                <el-switch v-model="props.reasonsAdmission" active-color="#3C7FFF" inactive-color="#CCCCCC"
                    :active-value="1" :inactive-value="0" @change="handlerChangeReasonsAdmission"></el-switch>
                <span class="label">入校事由必填</span>
            </el-form-item>
            <el-form-item>
                <el-switch v-model="props.admissionLimitStatus" active-color="#3C7FFF" inactive-color="#CCCCCC"
                    :active-value="1" :inactive-value="0" @change="handlerChangeAdmissionLimitStatus"></el-switch>
                <span class="label">入校次数限制</span>
            </el-form-item>
            <el-form-item v-if="props.admissionLimitStatus" label="该审批单被审批通过后，可以入校次数">
                <el-input-number v-model="props.admissionLimitNum" :min="1" :max="99" :precision="0"></el-input-number>
            </el-form-item>
            <el-form-item>
                <el-switch v-model="props.leaveSchoolStatus" active-color="#3C7FFF" inactive-color="#CCCCCC"
                    :active-value="1" :inactive-value="0" @change="handlerChangeLeaveSchoolStatus"></el-switch>
                <span class="label">出校次数限制</span>
            </el-form-item>
            <el-form-item v-if="props.leaveSchoolStatus" label="该审批单被审批通过后，可以出校次数">
                <el-input-number v-model="props.leaveSchoolNum" :min="1" :max="99" :precision="0"></el-input-number>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'kitsVisitorApplication',
    props: ["props", "getFormId"],
    data() {
        return {
            value: [],
        }
    },
    computed: {
        transferIcon() {
            return require("@/assets/images/transfer-icon.png");
        },
    },
    methods: {
        /**
         * @Description: 更改 小程序对访客显示
         * @Author: 武东超
         * @Date: 2024-12-17 10:44:44
         */
        handlerChangeShowWechat() {

        },
        /**
         * @Description: 入校事由必填
         * @Author: 武东超
         * @Date: 2024-12-17 11:04:47
         */
        handlerChangeReasonsAdmission() {
            
        },
        /**
         * @Description: 入校次数限制
         * @Author: 武东超
         * @Date: 2024-12-17 11:04:31
         */
        handlerChangeAdmissionLimitStatus() {

        },
        /**
         * @Description: 出校次数限制
         * @Author: 武东超
         * @Date: 2024-12-17 11:04:08
         */
        handlerChangeLeaveSchoolStatus() {

        },
        change() {
            console.log(this.value)
        },
        /**
         * @Description: 出入校方式配置
         * @Author: 武东超
         * @Date: 2024-12-23 16:40:26
         */        
        handlerChangeEntryExitModes() {
            console.log(this.props.entryExitModes,'[props.entryExitModes]')
            if (this.props.entryExitModes.length === 0) {
                this.$message.info('出入校方式为必选项，已为您勾选生成二维码！')
                this.props.entryExitModes = ['3']
            }
        }
    },
    watch: {
        props: {
            handler(newValue) {
                console.log(newValue, '[props结果]')
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
.doc {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
    }

    .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.label {
    font-size: 14px;
    color: #606266;
    margin-left: 16px;
}
</style>