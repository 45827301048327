<template>
    <div class="right-panel">
        <global-page-back
            :detailPageTitle="detailPageTitle"
            @handlerGlobalType="handlerGlobalType"
        ></global-page-back>
        <div class="content-main content-bg">
            <!-- 头部筛选 -->
            <div class="filter-wrap">
                <filter-data
                    :closeWidth="510"
                    :formData="formData"
                    @clickBtn="clickBtn"
                    marginBottom="0px"
                    @changeBtnFormType="changeBtnFormType"
                ></filter-data>
                <div
                    class="button-line"
                    v-has-permi="[
                        'moralmanage:moraldocument:detail:export',
                        'moralmanage:moraldocument:detail:add',
                    ]"
                ></div>
                <div>
                    <el-button
                        :loading="exportLoading"
                        v-has-permi="[
                            'moralmanage:moraldocument:detail:export',
                        ]"
                        @click="handleExport()"
                    >
                        导出
                    </el-button>
                    <el-button
                        v-if="isMaster"
                        v-has-permi="['moralmanage:moraldocument:detail:add']"
                        type="primary"
                        class="filter-right-button"
                        style="background-color: #668cff; color: white"
                        @click="addComments()"
                    >
                        添加评语
                    </el-button>
                </div>
            </div>
            <div class="notice-content">
                <div
                    :style="{
                        height: `${content.height}px`,
                        overflow: 'auto',
                    }"
                >
                    <div
                        id="pdfPreviewDom"
                        ref="content"
                    >
                        <!-- 学生信息 -->
                        <div class="student_info">
                            <div class="info_img">
                                <img
                                    :src="studentData.stuImg || avatarUrl"
                                    alt=""
                                    class="img_style"
                                />
                            </div>
                            <div class="info_nameSex">
                                <div class="info_name">
                                    <div>姓名：</div>
                                     <div class="text"> {{ studentData.studentName || "-" }}</div>
                                </div>
                                <div class="info_name">
                                    <div>性别：</div>
                                    <div class="text">{{ studentData.sexName || "-" }}</div>
                                </div>
                            </div>
                            <div class="info_nameSex">
                                <div class="info_name">
                                    <div> 班级：</div>
                                   <div class="text">{{ studentData.className || "-" }}</div>
                                </div>
                                <div class="info_name">
                                    <div>班主任：</div>
                                    <div class="text">{{ studentData.masterName || "-" }}</div>
                                </div>
                            </div>
                            <div class="info_nameSex">
                                <div class="info_name">
                                    <div>学号：</div>
                                    <div class="text">{{ studentData.studyNo || "-" }}</div>
                                </div>
                                <div class="info_name">
                                    <div>唯一号：</div>
                                    <div class="text">{{ studentData.onlyCode || "-" }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- 列表 -->
                        <div>
                            <table-data
                                v-loading="loadingTable"
                                :config="tableConfig"
                                :tableData="studentBehaviorList"
                                ref="table"
                                style="width: 100%"
                            >
                                <template #behaviorName="{data}">
                                    <div
                                        style="
                                            display: flex;
                                            align-items: center;
                                        "
                                    >
                                        <img
                                            :src="
                                                data.ruleType === '2'
                                                    ? praiseUrl
                                                    : criticizeUrl
                                            "
                                            alt=""
                                            class="img1"
                                        />
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :disabled="
                                                data.behaviorName.length <= 5
                                            "
                                            :content="data.behaviorName"
                                            placement="top"
                                        >
                                            <span class="ellipsis">{{
                                                data.behaviorName
                                            }}</span>
                                        </el-tooltip>
                                    </div>
                                </template>
                                <template #img="{data}">
                                    <div class="img-list">
                                        <div v-if="data.img.length > 0">
                                            <div
                                                class="itemContext"
                                                v-for="(
                                                    item, index
                                                ) in data.img"
                                                :key="index"
                                            >
                                                <video
                                                    v-if="
                                                        item
                                                            .toLowerCase()
                                                            .indexOf('mp4') >
                                                            -1 ||
                                                        item
                                                            .toLowerCase()
                                                            .indexOf('mov') > -1
                                                    "
                                                    :class="item ? 'img2' : ''"
                                                    disablePictureInPicture
                                                    @click="handlePreview(item)"
                                                >
                                                    <source :src="item" />
                                                </video>
                                                <img
                                                    v-else
                                                    :src="item"
                                                    alt=""
                                                    :class="item ? 'img2' : ''"
                                                    @click="handleImg(item)"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style="text-align: center"
                                            v-else
                                        >
                                            -
                                        </div>
                                    </div>
                                </template>
                            </table-data>
                            <div class="top_row">
                                <div class="score_img">
                                    <img
                                        :src="exportUrl"
                                        alt=""
                                        style="width: 14px; height: 14px"
                                    />
                                </div>
                                <div class="all_score">总计：</div>
                                <div>{{ totalScore }}</div>
                            </div>
                        </div>
                        <!-- 班主任评语 -->
                        <div
                            class="comments"
                            v-if="isTeacherRemark"
                        >
                            <div class="comments_dl">
                                <div class="comments_dt">班主任评语</div>
                                <div
                                    class="comments_dd"
                                    v-if="commentList.length > 0"
                                >
                                    <div
                                        class="comments_eachLi"
                                        v-for="(item, index) in commentList"
                                        :key="index"
                                    >
                                        <div class="comments_time">
                                            {{ item.displayCommentDate }}
                                        </div>
                                        <div
                                            class="disFlex"
                                            id="disFlex"
                                        >
                                            <div class="comments_content">
                                                {{ item.commentContent }}
                                            </div>
                                            <div class="comments_btn">
                                                <div
                                                    v-if="isMaster"
                                                    class="btn_edit"
                                                    @click="handleEdit(item)"
                                                    v-has-permi="[
                                                        'moralmanage:moraldocument:detail:edit',
                                                    ]"
                                                >
                                                    编辑
                                                </div>
                                                <div
                                                    v-if="isMaster"
                                                    class="btn_delet"
                                                    @click="handleDelete(item)"
                                                    v-has-permi="[
                                                        'moralmanage:moraldocument:detail:del',
                                                    ]"
                                                >
                                                    删除
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="empty-text"
                                >
                                    暂无数据
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dialog-wrapper
                    :dialog-obj="dialogObj"
                    @handleClose="handleClose"
                    class="simple-dialog-form"
                >
                    <el-form
                        ref="formRef"
                        :model="ruleForm"
                        :rules="rules"
                        label-width="auto"
                    >
                        <el-form-item
                            label="评语内容"
                            prop="commentContent"
                        >
                            <el-input
                                v-model="ruleForm.commentContent"
                                type="textarea"
                                rows="10"
                                placeholder="请填写评语内容"
                                clearable
                                show-word-limit
                                maxlength="500"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="footer-button-group">
                        <xk-button @click="handleClose">取消</xk-button>
                        <xk-button
                            :loading="btnLoading"
                            type="primary"
                            @click="handleSave"
                        >
                            确定
                        </xk-button>
                    </div>
                </dialog-wrapper>
                <!-- 点击查看图片 -->
                <el-image-viewer
                    v-if="showViewer"
                    :on-close="handleCloseImg"
                    :url-list="[viewPhoto]"
                />
                <!-- mov视频预览 -->
                <dialog-wrapper
                    :dialog-obj="dialogMov"
                    @handleClose="handleCloseVideo"
                    class="dialog-form-video"
                >
                    <video
                        v-if="videoMovSrc"
                        ref="videoElement"
                        autoplay
                        style="width: 100%; height: 100%"
                        controls
                        disablePictureInPicture
                    >
                        <source :src="videoMovSrc" />
                    </video>
                </dialog-wrapper>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {DialogWrapper} from "common-local";
import TableData from "../Sub/TableData";
import GlobalPageBack from "../Sub/GlobalPageBack/index.vue";
// import BackNav from "@/components/scrollWrapper/SManagerEduMoralAssessment/Sub/BackNav";
import FilterData from "../Sub/ExpandFilter.vue";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import onResize from "@/mixins/onResize";
export default {
    name: "ArchivesManage",
    mixins: [onResize],
    components: {
        FilterData,
        DialogWrapper,
        GlobalPageBack,
        TableData,
        ElImageViewer,
    },
    props: {
        studentData: {
            type: Object,
        },
        detailPageTitle: String,
    },
    data() {
        return {
            exportLoading: false,
            isTeacherRemark: false,
            isMaster: false,
            schoolCalendar: [],
            title: "",
            queryForm: {
                schoolId: "",
                studentId: "",
                ruleType: "",
                assessDateStart: "",
                assessDateEnd: "",
            },
            loadingTable: true,
            btnLoading: false,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择类型",
                        key: "ruleType",
                        list: [
                            {
                                label: "表扬",
                                value: "2",
                            },
                            {
                                label: "批评",
                                value: "1",
                            },
                        ],
                    },
                    {
                        type: "datePick",
                        key: "dateArr",
                        value: [],
                        dateType: "datetimerange",
                        format: "yyyy-MM-dd HH:mm",
                        valueFormat: "yyyy-MM-dd HH:mm",
                        startPlaceholder: "请选择开始时间",
                        endPlaceholder: "请选择结束时间",
                        clearable: false,
                        defaultTime: ["00:00:00", "23:59:59"],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["moralmanage:classmanage:studata:list"],
                    },
                    // {
                    //     type: "enquiry",
                    //     text: "重置",
                    //     fn: "reset",
                    //     auth: ["moralmanage:classmanage:studata:list"],
                    // },
                ],
                btnFormType: true,
            },
            // 表格配置
            tableConfig: {
                thead: [
                    {
                        type: "slot",
                        slotName: "behaviorName",
                        fixed: false,
                        label: "考核行为",
                        align: "left",
                    },
                    {
                        type: "function",
                        label: "分数",
                        prop: "score",
                        align: "center",
                        // labelWidth: "120",
                        callBack: (row, prop) => {
                            return (
                                (row.ruleType === "1" ? "-" : "+") + row.score
                            );
                        },
                    },
                    {
                        label: "凭证",
                        type: "slot",
                        slotName: "img",
                        align: "center",
                        // labelWidth: "120",
                        fixed: false,
                    },
                    {
                        label: "备注",
                        prop: "remark",
                        align: "center",
                    },
                    {
                        label: "考核时间",
                        prop: "assessDate",
                        align: "center",
                        labelWidth: "150",
                    },
                ],
                check: false,
                border: true,
            },
            content: {
                height: "",
            },

            totalScore: 0,
            studentBehaviorList: [],

            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "520px",
            },
            dialogMov: {
                title: "视频预览",
                dialogVisible: false,
                width: "520px",
            },
            videoMovSrc: "",
            // 评语列表
            commentList: [],
            ruleForm: {
                id: "",
                studentId: "",
                schoolId: "",
                commentContent: "",
            },
            rules: {
                commentContent: [
                    {
                        required: true,
                        message: "请添加评语",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            showViewer: false,
            viewPhoto: "",
        };
    },
    created() {
        this.initIsTeacherRemark();
        this.init();
    },
    mounted() {
        this.resizeHandler();
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            permissions: (state) => state.permissions,
        }),
        // 学生头像图片
        avatarUrl() {
            return require("@/assets/images/upload-avatar.png");
        },
        // 表扬图标
        praiseUrl() {
            return require("@/assets/images/moralAsstessment/honour-icon.png");
        },
        // 批评图标
        criticizeUrl() {
            return require("@/assets/images/moralAsstessment/smile-down-icon.png");
        },
        // 统计图标
        exportUrl() {
            return require("@/assets/images/moralAsstessment/summary-icon.png");
        },
    },
    methods: {
        init() {
            this.initTableConfig();
            this.initBaseData();
        },
        // 获取是否有班主任评语权限
        initIsTeacherRemark() {
            this._fet("/operate/operateSchoolMenu/getSchoolMenu", {
                schoolId: this.schoolId,
                productId: "cloud_campus",
                ids: ["010206010806"],
            }).then((res) => {
                if (res.data.code === "200") {
                    if (res.data.data && res.data.data.length > 0) {
                        this.isTeacherRemark = true;
                    } else {
                        this.isTeacherRemark = false;
                    }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        initTableConfig() {
            let moralmanageMoraldocumentDetailCheckPerson =
                this.permissions.some((item) => {
                    return (
                        "moralmanage:moraldocument:detail:checkPerson" == item
                    );
                });
            if (!moralmanageMoraldocumentDetailCheckPerson) return;

            let tableConfig = {
                    label: "检查人",
                    prop: "createByName",
                    align: "center",
                    labelWidth: "150",
                },
                theadIdx = 0,
                theadBol = false;

            this.tableConfig.thead.forEach((item, index) => {
                if (item.prop == "remark") {
                    theadIdx = index + 1;
                }

                if (item.prop == "createByName") {
                    theadBol = true;
                }
            });

            if (!theadBol) {
                this.tableConfig.thead.splice(theadIdx, 0, tableConfig);
            }
            console.log(this.tableConfig.thead, "this.tableConfig.thead");
        },
        handleImg(data) {
            this.viewPhoto = data;
            this.showViewer = true;
        },
        /**
         * @Description: 预览视频
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-04 15:55:59
         */
        handlePreview(data) {
            console.log("预览视频", data);
            if (data.toLowerCase().indexOf("mov") > -1) {
                this.videoMovSrc = data;
                this.dialogMov.dialogVisible = true;
            } else {
                let videoType = [
                    "swf",
                    "avi",
                    "flv",
                    "mpg",
                    "rm",
                    "mov",
                    "wav",
                    "asf",
                    "3gp",
                    "mkv",
                    "rmvb",
                    "mp4",
                ];
                let url = data.split("/");
                url = url[url.length - 1].split(".");
                url = url[url.length - 1];

                if (
                    data.indexOf("minio") !== -1 &&
                    videoType.indexOf(url) == -1
                ) {
                    let checkFilePreview = filePreview();
                    checkFilePreview.openNewPage(data);
                } else {
                    let url = decodeURIComponent(data); // 要预览视频文件的访问地址
                    let Base64 = require("js-base64").Base64;
                    let previewUrl = `http://124.71.235.241:8012/onlinePreview?url=${encodeURIComponent(
                        Base64.encode(url),
                    )}`;
                    window.open(previewUrl);
                }
            }
        },
        handleCloseVideo() {
            this.$refs.videoElement.pause();
            this.dialogMov.dialogVisible = false;
            this.videoMovSrc = "";
        },
        handleCloseImg() {
            this.showViewer = false;
        },
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-04 15:48:28
         */
        initBaseData() {
            this.schoolCalendar = this.studentData.schoolCalendar;
            this.title = this.studentData.studentName + "的德育档案";
            this.ruleForm.schoolId = this.schoolId;
            this.queryForm.schoolId = this.schoolId;
            Object.keys(this.queryForm).forEach((key) => {
                if (this.studentData[key]) {
                    this.queryForm[key] = this.studentData[key];
                }
            });
            Object.keys(this.ruleForm).forEach((key) => {
                if (this.studentData[key]) {
                    this.ruleForm[key] = this.studentData[key];
                }
            });
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [
                        this.queryForm.assessDateStart,
                        this.queryForm.assessDateEnd,
                    ];
                }
            });
            this.getList();
            this.getIsMaster();
        },
        /**
         * @Description: 返回
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-03 15:16:31
         */
        handlerGlobalType() {
            if (this.studentData.sourceType) {
                this.$emit("changeComp", {
                    name: this.studentData.sourceType,
                });
            } else {
                this.$emit("changeComp", {
                    name: "AssessmentList",
                });
            }
        },
        /**
         * @Description: 点击查询/重置按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 11:07:57
         * @param {*} data
         */
        clickBtn(data) {
            switch (data.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key === "dateArr") {
                            if (item.value && item.value.length > 0) {
                                this.queryForm.assessDateStart = item.value[0];
                                this.queryForm.assessDateEnd = item.value[1];
                            } else {
                                this.queryForm.assessDateStart = "";
                                this.queryForm.assessDateEnd = "";
                            }
                        } else {
                            this.queryForm[item.key] = item.value;
                        }
                    });
                    this.getList(1);
                    break;
                // case "reset": // 重置
                //     this.formData.data.forEach((item) => {
                //         item.value = "";
                //         this.queryForm[item.key] = "";
                //     });
                //     this.setDefaultDate();
                //     this.getList(1);
                //     break;
                default:
                    break;
            }
        },
        setDefaultDate() {
            this.queryForm.assessDateStart = this.$moment(new Date())
                .subtract(7, "days")
                .format("YYYY-MM-DD HH:mm");
            this.queryForm.assessDateEnd =
                this.$moment().format("YYYY-MM-DD HH:mm");
            if (this.schoolCalendar.length > 0) {
                // 根据校历设置默认查询时间
                let {startTime, endTime} = this.schoolCalendar[0];
                startTime = this.formatDate(startTime);
                endTime = this.formatDate(endTime);
                if (
                    this.queryForm.assessDateEnd < endTime &&
                    this.queryForm.assessDateEnd > startTime
                ) {
                    this.queryForm.assessDateStart = startTime.concat(" 00:00");
                }
            }
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [
                        this.queryForm.assessDateStart,
                        this.queryForm.assessDateEnd,
                    ];
                }
            });
        },
        formatDate(date) {
            return date.replace(/[年月]/g, "-").replace(/[日]/, "");
        },
        // 条件筛选的 展开 / 收起
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.resizeHandler();
            });
        },
        /**
         * @Description: 添加评语
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-04 14:16:46
         */
        addComments() {
            this.dialogObj.title = "添加评语";
            this.dialogObj.dialogVisible = true;
            Object.keys(this.ruleForm).forEach((key) => {
                if (this.studentData[key]) {
                    this.ruleForm[key] = this.studentData[key];
                }
            });
            this.ruleForm.id = "";
            console.log(this.studentData, this.ruleForm, "-this.ruleForm");
        },
        /**
         * @Description: 编辑评语
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-04 15:25:08
         * @param {*} data
         */
        handleEdit(data) {
            this.dialogObj.title = "编辑评语";
            this.dialogObj.dialogVisible = true;
            Object.keys(this.ruleForm).forEach((key) => {
                this.ruleForm[key] = data[key];
            });
        },
        /**
         * @Description: 删除评语
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-04 15:25:48
         * @param {*} data
         */
        handleDelete(data) {
            console.log("删除的data", data);
            this.$confirm("是否确定删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet(
                        "/school/schoolMoralStudentMasterComment/delete",
                        {id: data.id},
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.getList();
                            this.$message.success("删除成功");
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        /**
         * @Description: 保存评语
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-04 14:22:01
         */
        handleSave() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.btnLoading = true;
                    this._fet(
                        "/school/schoolMoralStudentMasterComment/save",
                        this.ruleForm,
                    )
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("操作成功");
                                this.getList();
                                this.handleClose();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                }
            });
        },
        /**
         * @Description: 关闭添加评语弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-04 14:20:30
         */
        handleClose() {
            this.dialogObj.dialogVisible = false;
            this.ruleForm = this.$options.data.call().ruleForm;
            this.$nextTick(() => {
                this.$refs.formRef.clearValidate();
            });
        },
        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 17:55:29
         */
        handleExport() {
            this.exportPdf(document.getElementById("pdfPreviewDom"), true);
        },
        exportPdf(targetDom, singlePage) {
            this.exportLoading = true;
            this.$nextTick(() => {
                let cloneDom = targetDom.cloneNode(true);
                // 隐藏操作按钮
                let btnList = cloneDom.getElementsByClassName("comments_btn");
                Array.prototype.forEach.call(btnList, function (el) {
                    el.style.display = "none";
                });
                // 设置所选dom节点的white-space属性
                let setWhiteSpace = (dom, className, style) => {
                    let list = dom.getElementsByClassName(className);
                    Array.prototype.forEach.call(list, function (el) {
                        el.style.whiteSpace = style;
                    });
                }
                console.log('cloneDom处理之前的', targetDom.scrollHeight);
                // 处理cloneDom和targetDom的white-space属性
                setWhiteSpace(cloneDom, "ellipsis", "wrap");
                setWhiteSpace(targetDom, "ellipsis", "wrap");
                setWhiteSpace(cloneDom, "text-ellipsis", "wrap");
                setWhiteSpace(targetDom, "text-ellipsis", "wrap");

                // 更新targetDom的scrollHeight
                console.log('cloneDom处理之后的', targetDom.scrollHeight);
                // 处理tableBody宽度显示错误问题
                let tableBody =
                    cloneDom.getElementsByClassName("el-table__body")[0];
                tableBody.style.width = "100%";
                let tableHeader = cloneDom.getElementsByClassName("el-table__header")[0];
                tableHeader.style.width = "100%" ;
                let headerWrapper = cloneDom.getElementsByClassName("el-table__header-wrapper")[0];
                headerWrapper.style.width = "100%" ;
                // 设置最小宽度，防止页面宽度过小，导致内容显示不完整
                const minWidth = 1130;
                const targetWidth = targetDom.scrollWidth;
                cloneDom.style.width =
                    (targetWidth < minWidth ? minWidth : targetWidth) + "px";
                cloneDom.style.height = targetDom.scrollHeight + "px";
                cloneDom.style.background = "#FFFFFF";
                cloneDom.style.paddingTop = "30px";
                // 处理短暂出现滚动条问题
                cloneDom.style.position = "fixed";
                cloneDom.style.zIndex = "-9999";
                cloneDom.style.top = "-9999px";
                document.body.appendChild(cloneDom);
                html2canvas(cloneDom, {
                    allowTaint: true,
                    useCORS: true,
                    scale: 3, // 提升画面质量，但是会增加文件大小
                }).then((canvas) => {
                    document.body.removeChild(cloneDom);
                    // a4纸的尺寸[592.28,841.89]
                    const A4_WIDTH = 592.28;
                    const A4_HEIGHT = 841.89;
                    let contentWidth = canvas.width;
                    let contentHeight = canvas.height;

                    let imgWidth = A4_WIDTH;
                    let imgHeight = (A4_WIDTH / contentWidth) * contentHeight;

                    let pageData = canvas.toDataURL("image/jpeg", 1.0);
                    let pdf;
                    if (singlePage) {
                        // 导出单页
                        pdf = new JsPDF("", "pt", [
                            imgWidth,
                            Math.max(imgHeight, A4_HEIGHT),
                        ]);
                        pdf.addImage(
                            pageData,
                            "JPEG",
                            0,
                            0,
                            imgWidth,
                            imgHeight,
                        );
                    } else {
                        // 导出多页
                        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                        //当内容未超过pdf一页显示的范围，无需分页
                        //一页pdf显示html页面生成的canvas高度;
                        let pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT;
                        //未生成pdf的html页面高度
                        let htmlHeight = contentHeight;
                        //pdf页面偏移
                        let position = 0;
                        pdf = new JsPDF("", "pt", "a4");
                        if (htmlHeight < pageHeight) {
                            pdf.addImage(
                                pageData,
                                "JPEG",
                                0,
                                0,
                                imgWidth,
                                imgHeight,
                            );
                        } else {
                            while (htmlHeight > 0) {
                                pdf.addImage(
                                    pageData,
                                    "JPEG",
                                    0,
                                    position,
                                    imgWidth,
                                    imgHeight,
                                );
                                htmlHeight -= pageHeight;
                                position -= A4_HEIGHT;
                                //避免添加空白页
                                if (htmlHeight > 0) {
                                    pdf.addPage();
                                }
                            }
                        }
                    }
                    let fileName = this.title + ".pdf";
                    pdf.save(fileName);
                    this.exportLoading = false;
                    // 处理targetDom的white-space属性
                    setWhiteSpace(targetDom, "ellipsis", "nowrap");
                    setWhiteSpace(targetDom, "text-ellipsis", "nowrap");
                });
            });
        },
        /**
         * @Description: 获取学生档案列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-04 18:16:35
         * @param {*} t
         */
        getList(t) {
            if (t === 1) {
                this.queryForm.pageNum = 1;
            }
            this.loadingTable = true;
            this._fet(
                "/school/schoolMoralStudentDetail/getStuDetail",
                this.queryForm,
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        this.totalScore = 0;
                        this.studentBehaviorList =
                            res.data.data.studentBehaviorList;
                        this.commentList = res.data.data.commentList;
                        this.commentList.forEach((item) => {
                            if (item.commentDate) {
                                item.displayCommentDate =
                                    item.commentDate.substring(0, 10);
                            }
                        });

                        this.studentBehaviorList.forEach((item) => {
                            if (item.ruleType === "1") {
                                this.totalScore -= Number(item.score);
                            }
                            if (item.ruleType === "2") {
                                this.totalScore += Number(item.score);
                            }
                        });
                        this.studentBehaviorList.forEach((items) => {
                            console.log("items", items);
                            if (items.img) {
                                items.img = items.img.split(",");
                            } else {
                                items.img = [];
                            }
                        });
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch(() => {
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 判断是否是班主任
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/12 14:22
         */
        getIsMaster() {
            this._fet("/school/schoolOrgan/getIsMasterTeacher", {
                schoolId: this.schoolId,
                gradeId: this.studentData.gradeId,
                classId: this.studentData.classId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.isMaster = res.data.data;
                }
            });
        },
        /**
         * @Description: 初始化班主任评语列表高度
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-06 10:12:04
         */
        resizeHandler() {
            this.content.height =
                document.body.clientHeight - this.$refs.content.offsetTop - 158;
        },
    },
};
</script>

<style scoped lang="scss">
.content-main {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    height: calc(100vh - 140px);
    border-radius: 4px;
    .back-nav {
        border-bottom: 1px solid #edf0f2;
    }

    .notice-content {
        width: 70%;
        font-size: 14px;
        margin: 0 auto;
        background-color: #fff;
        padding: 10px;
        border-radius: 8px;
    }
    #pdfPreviewDom {
        overflow: auto; // 超出这个最大高度的数据，会被隐藏起来，上下滑动
        overflow-x: hidden; //隐藏x轴滚动条
    }
}

.filter-wrap {
    display: flex;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    margin-bottom: 10px;
    // border-radius: 4px;
    overflow: hidden;
    border-bottom: 1px solid #edf0f2;

    .expand-filter {
        padding: 0;
    }
}

.student_info {
    height: 80px;
    background: #f5f7fa;
    border-radius: 4px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    position: relative;

    .info_img {
        position: absolute;
        left: 16px;
        top: -10px;

        .img_style {
            width: 96px;
            height: 96px;
            border-radius: 4px;
        }
    }

    .info_nameSex {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        color: #6d7073;
        line-height: 32px;
        margin-left: 110px;
    }
}

.top_row {
    border: 1px solid #ebeef5;
    border-top: none;
    height: 40px;
    display: flex;
    background: #fafbfc;
    padding-left: 10px;
    line-height: 40px;

    .score_img {
        margin-top: 2px;
    }

    .all_score {
        margin-left: 7px;
    }

    .add_score {
        color: #f99d5e;
        margin-left: 4px;
    }

    .reduce_score {
        color: #71ce66;
        margin-left: 4px;
    }
}

.comments {
    margin-top: 16px;
    border: 1px solid #edf0f2;

    .comments_dl {
        padding: 0;
        margin: 0;
    }

    .comments_dt {
        border-bottom: 1px solid #edf0f2;
        height: 45px;
        line-height: 45px;
        font-size: 15px;
        font-weight: bold;
        color: #2b2f33;
        padding-left: 20px;
    }
    .empty-text {
        text-align: center;
        padding: 50px;
        color: #909399;
    }
    .comments_dd {
        // height: 90px;
        .comments_eachLi {
            // background-color: #71ce66;
            border-bottom: 1px solid #edf0f2;
            margin-left: 20px;
            padding: 16px 0;

            .comments_time {
                font-size: 12px;
                color: #808487;
                margin-bottom: 5px;
            }

            .comments_content {
                color: #2b2f33;
                line-height: 22px;
            }

            .comments_btn {
                display: flex;
                font-size: 14px;
                padding: 0 16px 0 40px;

                .btn_edit {
                    color: #3c7fff;
                    cursor: pointer;
                    width: 30px;
                }

                .btn_delet {
                    color: #e63a2e;
                    margin-left: 20px;
                    cursor: pointer;
                    width: 30px;
                }
            }
        }

        .disFlex {
            display: flex;
            justify-content: space-between;
        }
    }
}

.simple-dialog-form {
    ::v-deep .el-dialog {
        border-radius: 8px;

        .el-dialog__header {
            background-color: #ebf1fa;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
        }

        .el-dialog__body {
            padding: 40px 40px 28px 40px;
        }

        .footer-button-group {
            text-align: right;
        }
    }
}
.dialog-form-video {
    ::v-deep .el-dialog {
        width: 700px !important;
        height: 600px !important;
    }
}
.table-data {
    padding-bottom: 0;
}

.img1 {
    width: 14px;
    height: 14px;
    margin-right: 7px;
}

.img2 {
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px;
    cursor: pointer;
}
.img-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
#pdfPreviewDom {
    padding: 20px 20px 20px 20px;
    box-sizing: border-box;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

::v-deep .el-table__body .el-table__row {
    height: 68px;
}
.itemContext {
    width: 60px;
    height: 50px;
    margin: 4px 0px;
    background-color: #333;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text{
    color: #333;
    text-align: left;
    flex: 1;
}
.info_sex{
    display: flex;
    &>div:nth-of-type(1){
        flex-shrink: 0;
        width: 60px;
        text-align: right;
        margin-right: 5px;
    }
}
.info_name{
    display: flex;
    &>div:nth-of-type(1){
        flex-shrink: 0;
        width: 60px;
        text-align: right;
    }
}
</style>
