<template>
    <div>
        <!-- 家长管理列表 -->
        <SummaryParent 
          v-if="pageType === 'parentManage'"
          @changePage="changePage"
        ></SummaryParent>
        <!-- 绑定审批列表 -->
        <SummaryParentApprove
          v-if="pageType === 'parentApprove'"
          @changePage="changePage"
        ></SummaryParentApprove>
    </div>
</template>

<script>
import SummaryParent from "@/components/scrollWrapper/SummaryParent.vue";
import SummaryParentApprove from "@/components/scrollWrapper/SummaryParentApprove.vue";
export default {
  components: {
    SummaryParent,
    SummaryParentApprove
  },
  data() {
    return {
      pageType: "parentManage",
    }
  },
  methods: {
    changePage(page) {
      this.pageType = page;
    }
  }
}
</script>

<style scoped>

</style>