<template>
    <div>
        <el-col :span="data.fn ? 20 : 24">
            <el-autocomplete
                class="inline-input"
                v-model="data.value"
                :fetch-suggestions="querySearch"
                :placeholder="data.placeholder"
                @input="inputInput(data)"
                clearable
            ></el-autocomplete>
        </el-col>
    </div>
</template>
<script>
    export default {
        name: 'InputAutocomplete',
        props: {
            data: {
                type: Object
            }
        },
        methods:{
            querySearch(queryString, cb){
                var results = queryString ? this.data.list.filter(this.createFilter(queryString)) : this.data.list;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (item) => {
                    return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            inputInput(data) {
                this.$emit("autocompleteInput", data);
            },
        }
    }
</script>
<style scoped>

</style>
