<template>
    <div>
        <el-form-item label-width="115px" label="对接人姓名" :rules="dockerNameRule" prop="dockerName">
            <el-input v-model="dockerName" style="width: 673.12px" readonly placeholder="请选择对接人姓名" @focus="handlerClickGetDockerName"></el-input>
        </el-form-item>
        <el-form-item label-width="115px" label="对接人手机号" :rules="dockerPhoneNumber" prop="dockerPhoneNumber">
            <el-input v-model="ruleForm.dockerPhoneNumber" style="width: 673.12px" disabled placeholder="选择对接人姓名后自动回显"></el-input>
        </el-form-item>
        <el-form-item label-width="115px" label="出入校方式" :rules="entryExitModesRules" prop="entryExitModes">
            <el-select v-model="ruleForm.entryExitModes" style="width: 673.12px" placeholder="请选择出入校方式" @change="handlerChangeEntryExitModes">
                <el-option v-for="item in entryExitModesList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label-width="115px" label="到访时间" :rules="arrivalTime" prop="arrivalTime">
            <el-date-picker v-model="ruleForm.arrivalTime" type="datetime" placeholder="请选择到访时间" style="width: 673.12px" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="handlerChangeArrivalTime">
            </el-date-picker>
        </el-form-item>
        <el-form-item label-width="115px" label="结束时间" :rules="endTime" prop="endTime">
            <el-date-picker v-model="ruleForm.endTime" type="datetime" placeholder="结束时间为到访时间的当晚23:59:59" style="width: 673.12px" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" disabled>
            </el-date-picker>
        </el-form-item>
        <el-form-item label-width="115px" label="访客姓名" :rules="visitorName" prop="visitorName">
            <el-input v-model="ruleForm.visitorName" style="width: 673.12px" placeholder="请输入访客姓名" maxlength="10" @change="handlerChangeVisitorName"></el-input>
        </el-form-item>
        <el-form-item label-width="115px" label="访客手机号" :rules="phoneRules" prop="visitorPhoneNumber">
            <el-input v-model="ruleForm.visitorPhoneNumber" style="width: 673.12px" placeholder="请输入访客手机号" @change="handlerChangeVisitorPhoneNumber"></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.entryExitModes == '2'" label-width="115px" label="人脸照片" :rules="facePhotoRules" prop="facePhoto">
            <el-upload 
                size="medium" 
                ref="upload" 
                action="/" 
                :http-request="httpRequest" 
                :headers="headers" 
                accept=".jpg,.jpeg,.png"
                :file-list="facePhotoFileList" 
                :show-file-list="false"
                list-type="picture" 
                :with-credentials="true"
                :limit="1" 
                :multiple="false" 
                :auto-upload="false"
                :on-exceed="onExceedFacePhoto"
                :on-change="handleFileChange"
            >
                <el-button type="primary" size="medium">上传人脸照片</el-button>
                <div class="avatar-upload-img" @click.stop v-if="ruleForm.facePhoto[0]">
                    <img :src="ruleForm.facePhoto[0]" alt="">
                    <div class="avatar-upload-img-operate">
                        <i class="el-icon-delete" @click.stop="handleRemoveFacePhoto"></i>
                    </div>
                </div>
            </el-upload>
        </el-form-item>
        <el-form-item v-if="ruleForm.entryExitModes == '1'" label-width="115px" label="访客身份证号" :rules="visitorIDRules" prop="visitorID">
            <el-input v-model="ruleForm.visitorID" style="width: 673.12px" placeholder="请输入访客身份证号" @change="handlerChangeVisitorID"></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.entryExitModes == '1'" label-width="115px" label="访客身份证照片" :rules="visitorIDPhotoRules" prop="visitorIDPhoto">
            <el-upload 
                size="medium" 
                ref="upload" 
                :action="action" 
                :headers="headers" 
                accept=".jpg,.jpeg,.png"
                :file-list="fileList" 
                list-type="picture" 
                :before-upload="(file) => beforeUpload(file)"
                :on-success="(res, file, fileList) => handleSuccess(res, file, fileList)"
                :before-remove="(file, fileList) => handleRemove(file, fileList)" 
                :on-exceed="onExceedVisitorIDPhoto"
                :on-preview="handlePictureCardPreview"
                :with-credentials="true"
                :limit="1"
            >
                <el-button type="primary" size="medium">上传身份证照片</el-button>
            </el-upload>
        </el-form-item>
        <el-form-item label-width="115px" label="入校事由" :rules="reasonsAdmission" prop="reasonsAdmission">
            <el-input v-model="ruleForm.reasonsAdmission" type="textarea" style="width: 673.12px" placeholder="请输入入校事由" :rows="2" maxlength="50" show-word-limit @change="handlerChangeReasonsAdmission"></el-input>
        </el-form-item>
        <xk-tree-shape
            ref="treeShape"
            v-if="dialogObj.dialogVisible"
            :title="dialogObj.title"
            :multiple="multiple"
            :check-user-only="true"
            :show="dialogObj.dialogVisible"
            :list="selTreeOptList"
            :selectObjData="selectObjData"
            @getNextLevelTreeList="getNextLevelTreeList"
            
            @determine="determine"
            @closeDialog="closeDialog"
        >
        </xk-tree-shape>
        <!-- 上传头像 -->
        <dialog-wrapper
            :dialogObj="cropperDialogObj"
            @handleClose="handleCropperClose"
        >
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :info="option.info"
                        :fixed-box="option.fixedBox"
                        :can-scale="option.canScale"
                        :auto-crop="option.autoCrop"
                        :info-true="option.infoTrue"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :outputSize="option.size"
                        :high="false"
                    />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCropperClose">取 消</el-button>
                <el-button :loading="cropperLoading" type="primary" @click="finish">确定</el-button>
            </span>
        </dialog-wrapper>
        <!-- 查看身份证照片大图 -->
        <dialog-wrapper
            :dialogObj="cardPhotoPreviewDialogObj"
            @handleClose="cardPhotoPreviewDialogObj.dialogVisible = false;"
        >
            <img width="100%" :src="cardPhotoImageUrl" alt="">
        </dialog-wrapper>
    </div>
</template>
<script>
import TreeShape from "../../../CoworkingCampusOAOAApprovalSubmit/TreeShape/src/index.vue";
import { uploadAction } from "@/libs/settings";
import { getToken } from "@/libs/auth";
import { CommonModel } from "@/models/Common.js";
import {parseTime, removeTreeShapeDialog} from "@/libs/utils.js";
import {mapMutations, mapState} from "vuex";
import { debounce } from '@/components/scrollWrapper/Sub/libs/fnProcessing.js';
import { VueCropper } from 'vue-cropper'
import { DialogWrapper } from "common-local";
export default {
    name: 'KitsVisitorApplicationCom',
    components: {
        TreeShape,
        VueCropper,
        DialogWrapper
    },
    props: {
        ruleFormData: Array,
        ruleForm: Object
    },
    data() {
        return {
            dialogObj: {
                title: "选择年级/班级/学生",
                dialogVisible: false,
                width: "600px",
                key: 1,
            },
            treeData: {
                schoolId: '',
                type: '',
                parentId: '',
                name: '',
                organType: '',
                haveDatePermission: '1',
                peopleStatus: '1', // 教师：1在职，2离职；学生：1在校，2毕业，3转学
                isMask: '' // 是否要脱敏 1脱敏 不传不脱敏
            },
            multiple: false,
            selTreeOptList: [],
            selectObjData: [],
            assembliesPersonType: "",
            setTreeAuxiliaryData: {
                key: "",
                data: [],
                selList: "",
            },
            dockerName: '',
            dockerNameRule: [
                { required: true, message: '请填写对接人姓名', trigger: ['change', 'blur'] },
            ],
            dockerPhoneNumber: [
                { required: true, message: '请填写对接人手机号', trigger: ['change', 'blur'] },
            ],
            entryExitModesRules: [
                { required: true, message: '请选择出入校方式', trigger: ['change', 'blur']  },
            ],
            arrivalTime: [
                { required: true, message: '请填写到访时间', trigger: ['change', 'blur'] },
            ],
            endTime: [
                { required: true, message: '请填写结束时间', trigger: ['change', 'blur'] },
            ],
            visitorName: [
                { required: true, message: '请填写访客姓名', trigger: ['change', 'blur'] },
            ],
            phoneRules: [
                { required: true, message: '请填写手机号', trigger: ['change', 'blur'] },
                { min: 11, max: 11, message: '手机号长度必须为11位', trigger: ['blur', 'change'] }
            ],
            visitorIDRules: [
                { required: true, message: '请填写身份证', trigger: ['change', 'blur'] },
                { min: 18, max: 18, message: '身份证号码长度必须为18位', trigger: ['blur', 'change'] }
            ],
            reasonsAdmission: [
                { required: true, message: '请填写入校事由', trigger: ['change', 'blur'] },
            ],
            facePhotoRules: [
                { type: 'array', required: true, message: '请上传人脸照片', trigger: ['change', 'blur'] },
            ],
            visitorIDPhotoRules: [
                { type: 'array', required: true, message: '请上传身份证照片', trigger: ['change', 'blur'] },
            ],
            entryExitModesList: [
                {
                    label: '刷本人身份证',
                    value: '1'
                },
                {
                    label: '人脸识别',
                    value: '2'
                },
                {
                    label: '生成二维码',
                    value: '3'
                }
            ],
            facePhotoFileList: [],
            fileList: [],
            // 裁剪图片
            cropperDialogObj: {
                title: '裁剪图片',
                dialogVisible: false,
                width: '800px'
            },
            option: {
                img: '', // 裁剪图片的地址
                file: '',
                info: false, // 裁剪框的大小信息
                fixedBox: true,
                autoCropWidth: 540,
                autoCropHeight: 720,
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                outputType: 'jpg',
                size: 0.9
            },
            cropperLoading: false,
            cardPhotoPreviewDialogObj: {
                title: '图片预览',
                dialogVisible: false,
                width: '800px'
            },
            cardPhotoImageUrl: '',
        }
    },
    created() {
        this.init();
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        action() {
            return uploadAction
        },
        headers() {
            return {
                Authorization: getToken(),
            }
        },
    },
    methods: {
        init() {
            this.initPageData();
            this.getPageData();
        },
        initPageData() {
            let reasonsAdmissionObj = this.ruleFormData.find(item => item.compType === 'reasonsAdmission');
            this.reasonsAdmission[0].required = reasonsAdmissionObj.required;
            console.log(this.reasonsAdmission[0].required,'[this.reasonsAdmission[0].required]')
            this.treeData.schoolId = this.schoolId;
            this.treeData.type = 'teacher,organ';
            this.treeData.organType = '1,5';
        },
        async getPageData() {
            await this.getTreeDataList()
        },
        /**
         * @Description: 获取对接人
         * @Author: 武东超
         * @Date: 2024-12-19 14:14:35
         * @param {*} data
         */        
        async getNextLevelTreeList(data) {
            const {id, name, type} = data;
            this.treeData.parentId = id || null;
            this.treeData.name = name;
            if(this.dialogObj.dialogVisible){
                await this.getTreeDataList();
                this.$eventBroadcast("getNewTreeList");
            }
        },
        /**
         * @Description: 获取对接人
         * @Author: 武东超
         * @Date: 2024-12-18 16:16:01
         * @param {*} type
         */        
        async getTreeDataList() {
            const commonModel = new CommonModel();
            let o = {
                ...this.treeData,
            }
            await commonModel.getAddressBookList(o).then((res) => {
                console.log(res,'[reshuoqu shuju]')
                if (res.data.code == "200") {
                    this.selTreeOptList = res.data.data;
                    console.log(this.selTreeOptList,'[this.selTreeOptList]')
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        closeDialog() {
            this.dialogObj.dialogVisible = false;
            removeTreeShapeDialog();
            this.treeData.parentId = "";
            this.treeData.name = "";
        },
        resetAuxiliaryData() {
            this.setTreeAuxiliaryData.key = "";
            this.setTreeAuxiliaryData.data = [];
            this.setTreeAuxiliaryData.selList = "";
        },
        async handlerClickGetDockerName() {
            this.treeData.name = '';
            this.treeData.parentId = '';
            this.treeData.haveDatePermission = '1';
            this.dialogObj.title = '选择对接人';
            await this.getTreeDataList();
            this.dialogObj.dialogVisible = true;
        },
        /**
         * @Description: 访客身份证号
         * @Author: 武东超
         * @Date: 2024-12-20 14:52:28
         */        
        handlerChangeVisitorID() {
            console.log(this.ruleForm.visitorID,'[ruleForm.visitorID]')
            this.checkViewRequiredFields();
        },
        handleFileChange(file) {
            let flag = this.beforeAvatarUpload(file);
            if (flag !== false) {
                this.$nextTick(() => {
                    // 上传预览图片  是个临时的缓存地址
                    this.option.img = URL.createObjectURL(file.raw);
                    this.option.file = file;
                    this.cropperDialogObj.dialogVisible = true;
                });
            }
        },
        beforeAvatarUpload(file) {

            const types = [".jpg", ".JPG", ".png", ".PNG", ".jpeg", ".JPEG", ".bmp", ".BMP", ".gif", ".GIF"];
            const isImg = types.includes(file.name.substring(file.name.lastIndexOf(".")));
            const isLt2M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            // 判断图像上传大小
            let riseze = file.size / 1024;
            console.log(riseze,'图片真实大小')
            if (riseze < 50) {
                this.$message.error("上传图片不能小于50kb!");
                return false;
            }

            if (riseze < 200) {
                console.log('图像小于200')
                this.option.size = 0.99;
            } else {
                this.option.size = 0.9;
                console.log('图像大于200')
            }

            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
                return false;
            }
            if (!isImg) {
                this.$message.error("上传图片不符合格式!");
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过20MB!");
                return false;
            }
        },
        /**
         * @Description: 人脸照片
         * @Author: 武东超
         * @Date: 2024-12-20 14:06:23
         */        
         handleSuccessFacePhoto(url) {
            this.handleCropperClose();
            this.ruleForm.facePhoto = [];
            this.ruleForm.facePhoto.push(url);
            this.checkViewRequiredFields();
            this.$emit('facePhotoVerification')
            console.log(this.ruleForm.facePhoto,'this.ruleForm.facePhoto')
            
            setTimeout(() => {
                this.cropperLoading = false;
            }, 300);
        },
        /**
         * @Description: 人脸照片上传覆盖
         * @Author: 武东超
         * @Date: 2024-12-26 11:01:57
         */        
         httpRequest() {},
        /**
         * @Description: 超出图片限制
         * @Author: 武东超
         * @Date: 2024-12-26 11:34:05
         */        
        onExceedFacePhoto(files, fileList) {
            this.$message.info('最多支持一张图片，如需更换图片，请先删除～')
        },
        /**
         * @Description: 人脸照片删除
         * @Author: 武东超
         * @Date: 2024-12-20 14:07:43
         */        
         handleRemoveFacePhoto(file, fileList) {
            this.facePhotoFileList = [];
            this.ruleForm.facePhoto = [];
            this.checkViewRequiredFields();
        },
        /**
         * @Description: 身份证上传文件前置
         * @Author: 武东超
         * @Date: 2024-12-18 15:38:07
         * @param {*} file
         * @param {*} data
         */        
         beforeUpload(file) {
            const type = ['jpg', 'jpeg', 'png'];
            console.log(file, file.type.split('/')[1],'----')
            const isImg = type.includes(file.type.split('/')[1])
            const isLt10M = file.size / 1024 / 1024 < Number(10)
            const is50 = file.name.length < 50
            if (!isImg) {
               this.$message.error(`上传文件格式仅支持'jpg', 'jpeg', 'png'!`);
                return false
            }
            if (!is50) {
                this.$message.error('上传文件名称不能超过50个字符!');
                return false
            }
            if (!isLt10M) {
                this.$message.error(`上传文件的大小不能超过10M!`)
                return false
            }
        },
        /**
         * @Description: 身份证上传成功
         * @Author: 武东超
         * @Date: 2024-12-18 15:38:53
         * @param {*} res
         * @param {*} data
         * @param {*} file
         * @param {*} fileList
         */        
        handleSuccess(res, data, file, fileList) {
            this.ruleForm.visitorIDPhoto = [];
            this.ruleForm.visitorIDPhoto.push(res.data.url);
            this.checkViewRequiredFields();
            this.$emit('IDUploadVerification')
        },
        /**
         * @Description: 身份证删除图片
         * @Author: 武东超
         * @Date: 2024-12-18 15:39:52
         * @param {*} file
         * @param {*} fileList
         * @param {*} item
         */        
        handleRemove(file, fileList) {
            const index = fileList.findIndex(i => i.uid === file.uid);
            this.ruleForm.visitorIDPhoto.splice(index, 1);
            this.checkViewRequiredFields();
        },
        /**
         * @Description: 身份证照片超出限制提示
         * @Author: 武东超
         * @Date: 2024-12-26 11:37:29
         */        
        onExceedVisitorIDPhoto() {
            this.$message.info('最多支持一张图片，如需更换图片，请先删除～')
        },
        /**
         * @Description: 身份证照片预览
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-12-31 11:08:54
         * @param {*} file
         */        
        handlePictureCardPreview(file) {
            console.log(file, 'handlePictureCardPreview')
            this.cardPhotoImageUrl = file.url;
            this.cardPhotoPreviewDialogObj.dialogVisible = true;
        },
        // 表单每一项事件触发
        /**
         * @Description: 获取对接人姓名
         * @Author: 武东超
         * @Date: 2024-12-19 14:28:18
         * @param {*} data
         */        
        determine(data) {
            this.selectObjData = data;
            this.dockerName = data[0].name;
            this.ruleForm.dockerName = data;
            this.ruleForm.dockerPhoneNumber = data[0].phone;
            removeTreeShapeDialog();
            this.dialogObj.dialogVisible = false;
            this.treeData.parentId = '';
            this.treeData.name = '';

            this.checkViewRequiredFields();
        },
        /**
         * @Description: 更换出入校方式
         * @Author: 武东超
         * @Date: 2024-12-19 14:27:14
         */        
        handlerChangeEntryExitModes() {
            switch(this.ruleForm.entryExitModes) {
                case '1':
                    this.ruleForm.facePhoto = [];
                    break;
                case '2':
                    this.ruleForm.visitorID = '';
                    this.ruleForm.visitorIDPhoto = [];
                    break;
                case '3':
                    this.ruleForm.facePhoto = [];
                    this.ruleForm.visitorID = '';
                    this.ruleForm.visitorIDPhoto = [];
                    break;
                default:
                    break
            };

            this.checkViewRequiredFields()
        },
        /**
         * @Description: 到访时间
         * @Author: 武东超
         * @Date: 2024-12-19 14:15:12
         */        
        handlerChangeArrivalTime() {
            let time = this.ruleForm.arrivalTime.split(' ')[0];
            this.ruleForm.endTime = `${time} 23:59:59`;

            this.checkViewRequiredFields();
        },
        /**
         * @Description: 访客姓名
         * @Author: 武东超
         * @Date: 2024-12-19 14:32:35
         */        
        handlerChangeVisitorName() {
            console.log(this.ruleForm.visitorName,'this.ruleForm.visitorName')
            if (this.ruleForm.visitorName) {
                this.checkViewRequiredFields();
            }
        },
        /**
         * @Description: 访客手机号
         * @Author: 武东超
         * @Date: 2024-12-19 14:33:48
         */        
        handlerChangeVisitorPhoneNumber() {
            console.log(this.ruleForm.visitorPhoneNumber,'this.ruleForm.visitorPhoneNumber')
            if (this.ruleForm.visitorPhoneNumber) {
                this.checkViewRequiredFields();
            }
        },
        /**
         * @Description: 入校事项
         * @Author: 武东超
         * @Date: 2024-12-20 15:02:50
         */        
        handlerChangeReasonsAdmission() {
            if (this.ruleForm.reasonsAdmission) {
                this.checkViewRequiredFields();
            }
        },
        /**
         * @Description: 查看所有必填项是否已经填好
         * @Author: 武东超
         * @Date: 2024-12-19 14:21:05
         */   
        checkViewRequiredFields:debounce(function(){
            console.log(this.ruleForm,'this.ruleForm')
            this.$emit('checkViewRequiredFields', this.ruleForm)
        }, 200),
        /**
         * @Description: 关闭弹窗
         * @Author: 武东超
         * @Date: 2024-12-27 09:54:18
         */        
        handleCropperClose() {
            this.cropperDialogObj.dialogVisible = false;
        },
        finish() {
            this.$refs.cropper.getCropBlob((data) => {
                console.log(data,'[data头像数据]')
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onload = function() {
                    if (reader.readyState == 2) {
                        const img = new Image();
                        img.src = reader.result;
                        img.onload = function() {
                            console.log(img.width, img.height,'width-height')
                        };
                    }
                };

                this.cropperLoading = true;
                let newFile = new window.File([data], this.option.file.name, { type: data.type });
                
                let formData = new FormData();
                    formData.append("file", newFile);
                    formData.append('personType' ,'teacher');
                    formData.append("dataType", 'add');

                    let commonModel = new CommonModel();
                    commonModel.uploadByPeople(formData).then((res) => {
                        if (res.data.code !== '200') {
                            this.$message.error(res.data.msg);
                            this.handleCropperClose();
                            this.ruleForm.facePhoto = [];
                            this.facePhotoFileList = []
                            setTimeout(() => {
                                this.cropperLoading = false;
                            }, 300);
                        } else {
                            this.handleSuccessFacePhoto(res.data.data.url)
                        }
                    })
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.cropper-content {
    display: flex;
    justify-content: center;

    .cropper {
        text-align: center;
        width: 540px;
        height: 720px;
    }

    ::v-deep .cropper-crop-box {
        background-color: #fff;
    }
}

.avatar-upload-img {
    position: relative;
    width: 80px;
    height: 106px;

    img {
        width: 100%;
        height: 100%;
    }

    .avatar-upload-img-operate {
        display: flex;
        cursor: default;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: rgba(0, 0, 0, 0.5);
        transition: opacity .3s;

        .el-icon-delete{
            cursor: pointer;
            color: #ffffff;
            margin: 0 5px;
            font-size: 16px;
        }
    }

    &:hover .avatar-upload-img-operate {
        opacity: 1;
    }
}
</style>