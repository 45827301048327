<!--
 * @Author: 武东超
 * @Date: 2022-11-04 08:54:20
 * @LastEditors: pyl m17798041915@163.com
 * @LastEditTime: 2024-12-23 17:59:40
 * @FilePath: /cloud_campus_Front-end/cloud_campus_Front-end/school_end/src/components/scrollWrapper/TeachingResearchCourseScheduleTemp.vue
 * @Description: 课程表容器
-->
<template>
    <component
        :is="comp"
        :tourDetails="tourDetails"
        :isRepeat="isRepeat"
        @handlerAddClick="handlerAddClick"
        @backCourseSchedule="backCourseSchedule"
        @classPatrolBack="classPatrolBack"
        @cancellationForm="cancellationForm"
        @handlerGoClassPatrolClick="handlerGoClassPatrolClick"
        @setPageInitTabs = "setPageInitTabs"
    >
    </component>
</template>
<script>

import CourseScheduleTemp from './TeachingResearchCourseScheduleTemp/index.vue';
import AddCourseSchedule from './TeachingResearchCourseScheduleTemp/AddCourseSchedule.vue';
import ClassPatrolTemp from './TeachingResearchCourseScheduleTemp/ClassPatrolTemp.vue';
export default {
    name: "TeachingResearchCourseScheduleTempScrollWrapper",
    components: {
        CourseScheduleTemp,
        AddCourseSchedule,
        ClassPatrolTemp
    },
    data () {
        return {
            idx: 0,
            comArr: ['CourseScheduleTemp', 'AddCourseSchedule', 'ClassPatrolTemp'],
            tourDetails: {},
            type:0,
            isRepeat: true
        }
    },
    computed: {
        comp () {
            return this.comArr[this.idx];
        }
    },
    activated() {
        this.setGlobalPageType(this.type)
    },
    methods: {
        /**
         * @Description: 添加课程
         * @DoWhat: 切换页面
         * @UseScenarios: 点击头部筛选
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-07 10:46:08
         */
        handlerAddClick (isRepeat) {
            this.idx = 1;
            this.setGlobalPageType('detail')
            this.isRepeat = isRepeat
        },
        /**
         * @Description: 返回课程列表
         * @DoWhat: 切换页面
         * @UseScenarios: 点击头部筛选
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-07 10:55:08
         */
        backCourseSchedule () {
            this.idx = 0;
        },
        /**
         * @Description: 添加课程表，返回
         * @DoWhat: 返回列表页面
         * @UseScenarios:  返回列表页面
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-08 11:50:38
         */
        cancellationForm () {
            console.log('添加课程表，返回');
            this.idx = 0;
            this.setGlobalPageType(this.idx)
        },
        /**
         * @Description: 巡课列表, 返回。
         * @DoWhat: 返回列表页面
         * @UseScenarios: 返回列表页面
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-09 10:17:39
         */
        classPatrolBack () {
            this.idx = 0;
            this.setGlobalPageType(this.idx)
        },
        /**
         * @Description: 巡课
         * @DoWhat: 巡课
         * @UseScenarios: 巡课
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 10:53:39
         */
         handlerGoClassPatrolClick (data) {
            this.tourDetails = data;
            console.log(this.tourDetails,'data')
            this.idx = 2;
            this.setGlobalPageType('detail')
         },
         setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
         },
         setPageInitTabs(){
            this.$eventDispatch('setGlobalPageType', 'list')
            this.idx = 0
        }
    }
}
</script>
