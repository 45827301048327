<template>
    <div class="week-list-item">
        <table-th wh="171px">
            <div class="week-label">
                {{ data.label }}
            </div>
        </table-th>
        
        <div v-for="(item, index) of data.child" :key="index" class="week-item" :class="index%2==1 ? 'active':''">
            <!-- 教师有重复课程 -->
            <div v-if="Array.isArray(item)" class="has-repeat-course">
                <div class="check-state" v-show="item[0].checkState && !item[0].operateShow">
                    <img :src="require('@/assets/images/select-course.png')" alt="" srcset="">
                </div>
                <div class="week-item-content" @mouseover="operateBtnShow(item[0])" @mouseleave="operateBtnHide(item[0])">
                    
                    <div v-show="item[0].operateShow" class="item-operate item-operate2" :class="item.length <= 3 ? 'item-operate3' : ''">
                    <!-- <div class="item-operate" v-show="item.operateShow"> -->
                        <div v-if="isTourClass != '0'" :class="['class-patrol', { current: item[0].isTourClass == '0'}]" @click.stop="lectureSetting(data, item)">{{ item[0].isTourClass | isTourClassName }}</div>
                        <div class="operate-btn">
                            <div class="del-btn" @click.stop="onceDelClick(data, item)">
                                <img src="@/assets/images/del-course.png" alt="" srcset="">
                            </div>
                            <div class="check-btn" @click.stop="changeCheckClick(data, item)">
                                <img src="@/assets/images/nomal-course.png" alt="" srcset="" v-show="!item[0].checkState">
                                <img :src="require('@/assets/images/select-course.png')" alt="" srcset="" v-show="item[0].checkState">
                            </div>
                        </div>
                    </div>
                    <div 
                        v-for="(childItem, childIndx) in item"
                        :key="childItem.id"
                        style="width: 100%"
                        :class="(item[0].operateShow && item.length > 3 && childIndx == 0) ? 'margin-top-distance' : (item[0].operateShow && item.length > 2 && childIndx == 0) ? 'margin-top-distance2' : ''"
                    >
                        <div class="item-content" :class="item[0].operateShow?'active':''">
                                <div class="label" @click.stop="goToDetail(data, childItem)">{{ childItem.subjectName }}</div>
                                <div class="single-operate">
                                    <img v-if="childIndx != activeDetailIndex" :src="nDetail" alt="" class="show-tips" @mouseover="hiddenOperate(childIndx, 'detail')">
                                    <el-tooltip v-if="childIndx == activeDetailIndex" placement="top">
                                        <div slot="content">{{ childItem.subjectName }}<br/>{{ childItem.className }}</div>
                                        <img :src="yDetail" alt="" class=" show-tips" @mouseleave="showOperate()">
                                    </el-tooltip>
                                    <img v-if="childIndx == activeDelIndex" :src="yDel" alt="" class="single-del" @mouseleave="showOperate()" @click.stop="onceDelClick(data, childItem)">
                                    <img v-else :src="nDel" alt="" class="single-del" @mouseover="hiddenOperate(childIndx, 'del')" @click.stop="onceDelClick(data, childItem)">
                                </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- 教师无重复课程 -->
            <div v-else class="no-repeat-course">
                <div class="check-state" v-show="item.checkState && !item.operateShow">
                    <img :src="require('@/assets/images/select-course.png')" alt="" srcset="">
                </div>
                <div v-if="item.subjectName" class="week-item-content" @mouseover="operateBtnShow(item)" @mouseleave="operateBtnHide(item)">
                    
                    <div class="item-operate" v-show="item.operateShow">
                        <div v-if="isTourClass != '0'" :class="['class-patrol', { current: item.isTourClass == '0'}]" @click.stop="lectureSetting(data, item)">{{ item.isTourClass | isTourClassName }}</div>
                        <div class="operate-btn">
                            <div class="del-btn" @click.stop="onceDelClick(data, item)">
                                <img src="@/assets/images/del-course.png" alt="" srcset="">
                            </div>
                            <div class="check-btn" @click.stop="changeCheckClick(data, item)">
                                <img src="@/assets/images/nomal-course.png" alt="" srcset="" v-show="!item.checkState">
                                <img :src="require('@/assets/images/select-course.png')" alt="" srcset="" v-show="item.checkState">
                            </div>
                        </div>
                    </div>
                    <div class="item-content-multiLine" :class="item.operateShow?'active':''"  v-if="item.subjectName.length>4 ||item.teacherName.length>3 || (item.subjectName.length+item.teacherName.length)>6">
                    <!-- <div class="item-content-multiLine" :class="item.operateShow?'active':''"  v-if="'马克思列宁毛概三个'.length>4"> -->
                        <div class="class-info">
                            <div class="label" @click.stop="goToDetail(data, item)">{{ item.subjectName }}</div>
                            <!-- <div class="label" @click.stop="goToDetail(data, item)">马克思列宁毛概三个</div> -->
                        </div>
                        <div class="teachers-info">
                            <div class="teachers"  v-if="type!='2'">
                                ({{ item.teacherName }})
                        </div>
                            <div class="classname" v-else>
                                ({{ item.className }})
                                
                                <!-- <el-tooltip class="item" effect="dark" :content="item.className" placement="top-start">                              
                                <span style="margin-left:10px; width: 50px;"> ({{ item.className }})</span>
                                </el-tooltip> -->

                        </div>
                    </div>
                    </div>
                    <div class="item-content" :class="item.operateShow?'active':''" v-else>
                        
                        <div class="class-info">
                            <div class="label" @click.stop="goToDetail(data, item)">{{ item.subjectName }}</div>
                            <!-- <div class="label" @click.stop="goToDetail(data, item)">马克思</div> -->
                        </div>
                        <div class="teachers-info">

                        <div class="teachers"  v-if="type!='2'">({{ item.teacherName }})
                        </div>
                            <div class="classname" v-else>
                                <!-- ({{ item.className }}) -->
                                
                                <el-tooltip class="item" effect="dark" :content="item.className" placement="top-start">                              
                                <span > ({{ item.className }})</span>
                                </el-tooltip>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div v-else-if="showDownloadPDFType" class="add-course-schedule" v-hasPermi="['teachingResearchCourseScheduleTemp:save']" @click.stop="addWeekCourseSchedule(data, item)">
                    <i class="el-icon-plus"></i>
                    <span class="add">添加课程</span>
                </div>
                <div v-else class="add-course-schedule">
                    <span class="no-scheduling">暂无排课</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableTh from './Sub/TableTh.vue';
import TeachingResearchCourseScheduleTempFilter from '@/filters/TeachingResearchCourseScheduleTemp.js'
export default {
    name: "WeekListItem",
    filters: TeachingResearchCourseScheduleTempFilter,
    components: {
        TableTh
    },
    props: {
        data: Object,
        index: Number,
        isTourClass: String,
        courseList: Array,
        showDownloadPDFType: Boolean,
        advanceTime: Number,
        type:String
    },
    computed: {
        prohibitAdding () {
            return require('@/assets/images/prohibit-adding.png')
        },
        nDetail () {
            return require('@/assets/images/n-detail.png')
        },
        yDetail () {
            return require('@/assets/images/y-detail.png')
        },
        nDel () {
            return require('@/assets/images/n-del.png')
        },
        yDel () {
            return require('@/assets/images/y-del.png')
        }
    },
    data() {
        return {
            activeDetailIndex: -1,
            activeDelIndex: -1,
        }
    },
    methods: {
        showError (time) {
            return time._timestamp >= new Date().getTime()
        },
        goToDetail (data, item) {
            this.$eventDispatch('goToDetail', {data: data, item:item, index: this.index })
        },
        lectureSetting (data, item) {
            let advanceTime = this.advanceTime;
            if (item._timestamp - new Date().getTime() <= advanceTime) {
                this.$eventDispatch('lectureSetting', {data: data, item:item })
            } else {
                this.$message.error('未开始的课程不允许巡课操作!')
            }
        },
        addWeekCourseSchedule (data, item) {
            console.log("新增课程吗",data,item)
            this.$eventDispatch('addWeekCourseSchedule', {data: data, item:item })
        },
        onceDelClick(data, item){
            console.log("---数据",data)
            this.$eventDispatch('onceDelCourse', {data: data, item:item })
        },
        changeCheckClick(data,item){
            if (Array.isArray(item)) {
                item.forEach(m => {
                    m.checkState = !m.checkState
                })
            } else {
                item.checkState = !item.checkState
            }
            this.$forceUpdate()
            this.$eventDispatch('changeCheck', {item:item })
        },
        operateBtnShow(item){
            item.operateShow=true
            this.$forceUpdate()
        },
        operateBtnHide(item){
            item.operateShow=false
            this.$forceUpdate()
        },
        /**
         * @Description: 教师课表下课程提示操作状态切换
         * @Author: pyl
         * @Date: 2024-11-29 15:11:35
         */        
        hiddenOperate(index, type) {
            if (type == 'detail') {
                this.activeDetailIndex = index
            } else {
                this.activeDelIndex = index
            }
        },
        showOperate() {
            this.activeDetailIndex = -1
            this.activeDelIndex = -1
        }
    }

}
</script>
<style lang="scss" scoped>
    .week-list-item {
        .week-label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            // color: #808487;
            color: #ffffff;
        }
        .week-item.active{
            background-color: #EDF4FA;
        }

        .week-item {
            width: 172px;
            height: 106px;
            box-sizing: border-box;
            // padding: 5px;
            // border-right: 1px solid #EDF0F2;
            // border-bottom: 1px solid #EDF0F2;
            border-right:  1px solid #61AFFF;
            border-bottom: 1px solid #61AFFF;
            position: relative;
            .has-repeat-course {
                height: 100%;
                .check-state{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    img{
                        width: 16px;
                        height: 16px;
                    }  
                }
                
                .week-item-content {
                    background-color: #fff;
                    box-sizing: border-box;
                    padding: 9px 10px 2px 20px;
                    width: 170.5px;
                    height: 104.5px;
                    overflow-y: auto;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    .item-operate{
                        width: 100%;
                        height: 22px;
                        padding-top: 9px;
                        // background-color: rebeccapurple;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .operate-btn{
                            display: flex;
                            justify-content: space-between;
                            
                            .del-btn{
                                img{
                                    width: 15px;
                                    height: 16px;
                                }
                            }
                            .check-btn{
                                margin-left: 10px;
                                img{
                                    width: 15px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                    .item-operate2 {
                        position: absolute;
                        right: 18px;
                        top: 2px;
                        left: 2px;
                        background-color: #fff;
                        width: calc(100% - 20px);
                    }
                    .item-operate3 {
                        width: calc(100% - 12px);
                    }
                    .margin-top-distance {
                        margin-top: 46px;
                    }
                    .margin-top-distance2 {
                        margin-top: 25px;
                    }
                    .class-patrol {
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #9DA2A6;
                            cursor: pointer;
                        }
                        .class-patrol.current {
                            color: #3C7FFF;
                        }   

                    .item-content.active{
                        margin-top: 2.15px; 
                    }
                    .item-content{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 20px;
                        margin-top: 2.15px;  
                        
                        .label {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #363B40;
                            cursor: pointer;
                        }
                        .single-operate {
                            display: flex;
                            align-items: center;
                            .show-tips {
                                width: 14px;
                                height: 14px;
                                margin-right: 15px;
                            }
                            .show-tips-n:hover {
                                display: none;
                            }
                            .show-tips-y {
                                display: none;
                            }
                            .show-tips-y:hover {
                                display: block;
                            }
                            .single-del {
                                width: 12px;
                                height: 12px;
                            }
                            .single-del-n:hover {
                                display: none;
                            }
                            .single-del-y {
                                display: none;
                            }
                            .single-del-y:hover {
                                display: block;
                            }
                        }

                    }        

                    
                }
                .week-item-content:hover {
                    // border-style:dashed;
                    border: 2px dashed #499DF2;
                    cursor: pointer;
                }
            }
            .no-repeat-course {
                height: 100%;
                .check-state{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    img{
                        width: 16px;
                        height: 16px;
                    }  
                }
                
                .week-item-content {
                    background-color: #fff;
                    box-sizing: border-box;
                    padding: 9px 10px 10px 20px;
                    width: 170.5px;
                    height: 104.5px;
                    .item-operate{

                        height: 24px;
                        // background-color: rebeccapurple;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .operate-btn{
                            display: flex;
                            justify-content: space-between;
                            
                            .del-btn{
                                img{
                                    width: 15px;
                                    height: 16px;
                                }
                            }
                            .check-btn{
                                margin-left: 10px;
                                img{
                                    width: 15px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                    .class-patrol {
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #9DA2A6;
                            cursor: pointer;
                        }
                        .class-patrol.current {
                            color: #3C7FFF;
                        }
                        .item-content-multiLine.active{
                            margin-top: 2.5px; 
                        }
                        .item-content-multiLine{
                            margin-top: 16px;  
                            .class-info {
                                .label {
                                    font-size: 20px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #363B40;
                                    // cursor: pointer;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap
                                }
                            }
                            .teachers-info {
                                display: flex;
                                align-items: center;
                                .teachers {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #6D7073;
                                    width: 100px;
                                }
                                .classname{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #6D7073;
                                width: 90px;
                                margin-left: 10px;
                        }
                            }
                        }    

                    .item-content.active{
                        margin-top: 12px; 
                    }
                    .item-content{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        height: 20px;
                        margin-top: 30px;  


                    .class-info {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        
                        .label {
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #363B40;
                            cursor: pointer;
                        }
                    }
                    .teachers-info {
                        display: flex;
                        align-items: center;
                        .teachers {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #6D7073;
                            margin-left: 10px;
                        }  
                        .classname{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #6D7073;
                            width: 85px;
                            margin-left: 10px;
                        }
                    }

                    }        

                    
                }
                .week-item-content:hover {
                    // border-style:dashed;
                    border: 2px dashed #499DF2;
                    cursor: pointer;
                }
                .add-course-schedule {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .el-icon-plus {
                        color: #C8CACC;
                    }
                    .add {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #B6BABF;
                    }
                    .no-scheduling {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #B6BABF;
                    }

                }
                .add-course-schedule:hover {
                    .el-icon-plus {
                        color: #3C7FFF;
                    }
                    .add {
                        color: #3C7FFF;
                    }
                }
            }

        }
    }

</style>
