<template>
    <div class="content-main">
        <div v-if="!errorShow" >
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <el-autocomplete
                        class="inline-input"
                        v-model="semesterName"
                        clearable
                        :fetch-suggestions="querySearch"
                        placeholder="学期名称"
                        @select="handleSelect"
                    ></el-autocomplete>
                    <expand-filter
                        :formData="formData"
                        marginBottom="0px"
                        @changeBtnFormType="changeBtnFormType"
                        @clickBtn="clickBtn"
                    ></expand-filter>
                    <div class="button-line" v-has-permi="['schoolCalendarManagement:save']"></div>
                    <el-button
                        type="primary"
                        v-has-permi="['schoolCalendarManagement:save']"
                        @click="addCalendar"
                    >
                        添加
                    </el-button>
                    <el-button
                        type="primary"
                        :loading=exportLoading
                        v-has-permi="['schoolCalendarManagement:batchExport']"
                        @click="batchExportCalendar"
                    >
                        批量导出校历
                    </el-button>
                </div>
                <table-data
                    ref="table"
                    v-loading="loadingTable"
                    :config="table_config"
                    :tableData="table_data"
                    @handleSelectionChange="handleSelectionChange"
                >
                    <template #link="{data}">
                        <el-tag v-if="data.isSetEvent === '1'" type="primary">已设置</el-tag>
                        <el-tag v-else-if="data.isSetEvent === '0'" type="warning">未设置</el-tag>
                        <el-button
                            v-has-permi="['schoolCalendarManagement:calendar']"
                            type="text"
                            @click="lookCalendar(data, '1')"
                        >
                            查看
                        </el-button>
                    </template>
                    <template v-slot:operation="slotData">
                        <el-button
                            @click="lookCalendar(slotData.data, '2')"
                            v-has-permi="['schoolCalendarManagement:set']"
                            type="primary"
                        >
                            设置校历
                        </el-button>
                        <el-button
                            @click="edit(slotData.data)"
                            v-has-permi="['schoolCalendarManagement:edit']"
                            type="primary"
                        >
                            编辑
                        </el-button>
                        <el-button
                            @click="del(slotData.data)"
                            v-has-permi="['schoolCalendarManagement:del']"
                            type="danger"
                        >
                            删除
                        </el-button>
                        <el-button
                            v-if="slotData.data.isSetEvent === '1'"
                            @click="exportCalendar(slotData.data)"
                            v-has-permi="['schoolCalendarManagement:export']"
                            type="danger"
                        >
                            导出校历
                        </el-button>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                    />
                <dialog-wrapper :dialogObj="addCalendarDialogObj" @handleClose="addCalendarClose">
                <add-calendar
                    ref="addAsset"
                    :rule-form="addCalendarRulesForm"
                    :rules="addCalendarRules"
                    @submit="addCalendarSubmit"
                    @close="addCalendarClose"
                />
            </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
  // 业务组件
  DialogWrapper,
  Pagination,
  // 功能组件
  Error,
  Loading,
} from 'common-local';
import {
    classScheduleModel
} from '@/models/ClassSchedule.js';
// 添加校历
import AddCalendar from "./AddCalendar";
import TableData from "../Sub/TableData"
import onResize from "@/mixins/onResize";
import RecordsList from '@/libs/pageListOptimal.js';
import {downloadFile} from "@/libs/utils";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";
export default {
    mixins: [onResize],
    name: "List",
    components:{
        ExpandFilter,
        TableData,
        DialogWrapper,
        Pagination,
        // 功能组件
        Error,
        Loading,
        AddCalendar,
    },
    data(){
        return{
            loadingTable:false,
            listQuery:{
                schoolId:'',
                pageNum: 1,
                pageRow: 20,
                name: ''
            },
             // 添加
             addCalendarDialogObj: {
                title: '',
                dialogVisible: false,
                width: 'auto'
            },
            addCalendarRulesForm:{
                firstWeekd:'',
                endTime:'',
                startTime:'',
                name:'',
                id:'',
                schoolId:'',
                firstWeekStartTime:'',
                firstWeekEndTime:'',
            },
            addCalendarRules: {
                name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                startTime: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
                endTime: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
                firstWeekd: [{ required: true, message: '请选择', trigger: 'blur' },],
            },
            semesterName: '',
            semesterNameList: [],
            formData: {
                data: [],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'primary',
                        auth: ['schoolCalendarManagement:list']
                    },
                ],
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        prop: "name",
                        label: "学期名称",
                        align: 'center'
                    },
                    {
                        label:"开始日期",
                        prop:"newStartTime",
                        align: 'center'
                    },
                    {
                        label:"结束日期",
                        prop:"newEndTime",
                        align: 'center'
                    },
                    {
                        label:"第一周",
                        prop:"newFirstWeek",
                        align: 'center'
                    },
                    {
                        type: "slot",
                        prop: "calendar",
                        label: "校历",
                        className: 'text-spacing-reduction',
                        slotName: "link",
                        labelWidth: "150px"
                    },
                    {
                        label:"操作",
                        type:'slot',
                        slotName: 'operation',
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                ],
                check: true,
                height: '',
                rowkey: 'id',
                isExpandAll: true,
                leftEmpty:true,
                checkSelectAble: (row) => {
                    return row.isSetEvent === '1';
                },
            },
            // 表格数据
            table_data: [],
            // 表格选中
            tableListSel: [],
            allListSel: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            total:0,
            exportLoading: false
        }
    },
    created(){
        this.listQuery.schoolId = this.addCalendarRulesForm.schoolId = this.$store.state.schoolId;
        this.init()
    },
    methods:{
        querySearch(queryString, cb) {
            var semesterNameList = this.semesterNameList;
            var results = queryString ? semesterNameList.filter(this.createFilter(queryString)) : semesterNameList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (semesterNameList) => {
            return (semesterNameList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleSelect() {

        },
        /**
         * 初始化
         */
        init(){
            this.setPageInfo();
            this.getList()
            this.getSemesterNameList()
            this.getCalendarIdList()
        },
        /**
         * @Description: 获取已设置校历时间的校历id集合
         * @Author: pyl
         * @Date: 2024-11-30 14:57:02
         */        
        getCalendarIdList() {
            const classscheduleModel = new classScheduleModel();
            classscheduleModel.getCalendarIdList({schoolId: this.$store.state.schoolId}).then((res) =>{
                this.handleRes(res, () => {
                    this.allListSel = res.data.data
                })
            })
        },
        /**
         * @Description: 获取学期名称列表
         * @Author: pyl
         * @Date: 2024-11-30 11:23:59
         */        
        getSemesterNameList() {
            const classscheduleModel = new classScheduleModel();
            classscheduleModel.getSemesterNameList({schoolId: this.$store.state.schoolId}).then((res) =>{
                this.handleRes(res, () => {
                    this.semesterNameList = res.data.data.map(i => {
                        return {
                            value: i
                        }
                    })
                })
            })
        },
        /**
         * @Description: 批量导出校历
         * @Author: pyl
         * @Date: 2024-11-26 14:45:10
         */        
        batchExportCalendar() {
            let obj = {}
            let tips = ''
            if(this.semesterName && this.tableListSel.length == 0) {
                obj.idList = this.table_data.filter(i => i.isSetEvent === '1').map(i => i.id)
                tips = "是否确认导出筛选结果中所有已设置的校历数据？"
            } else if(!this.semesterName && this.tableListSel.length == 0) {
                obj.idList = this.allListSel
                tips = "是否确认导出所有已设置的校历数据？"
            } else {
                obj.idList = this.tableListSel.map(i => i.id)
                tips = "是否确认导出勾选学期对应的校历数据？"
            }
            if (obj.idList.length == 0) {
                return this.$message.error('暂无可导出校历数据')
            }
            this.$confirm(tips, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.exportLoading = true;
                        downloadFile({
                            url: '/school/schoolCalendar/export ',
                            form: obj,
                            fileName: "校历详情.xlsx",
                        }, () => {
                            this.$message.success('导出成功')
                            this.exportLoading = false;
                        }, () => {
                            this.exportLoading = false
                        })
                        // 确认
                    })
                    .catch(() => {
                        // 取消
                    });
        },
        /**
         * @Description: 单个导出校历
         * @Author: pyl
         * @Date: 2024-11-30 14:28:43
         */        
        exportCalendar(data) {
            downloadFile({
                url: '/school/schoolCalendar/export ',
                form: {idList: [data.id]},
                fileName: "校历详情.xlsx",
            }, () => {
                this.$message.success('导出成功')
            }, () => {
                
            })
        },
        /**
         * @Description: 选中的批量导出数据
         * @Author: pyl
         * @Date: 2024-11-26 14:47:50
         */        
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        savePageInfo(){
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
        },
        setPageInfo(){
            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
        },
        setTableScrollHeight(){
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                setTimeout(()=>{
                    if (this.$refs.table) {
                        this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                    }
                    //移除--存储筛选项及滚动条高度
                    recordsList.remove(this.$route.name) 
                },500)
            }
        },
        /**
         * @Description: 初始化列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-08 14:52:07
         */
        getList(){
            this.loadingTable = true;
            const classscheduleModel = new classScheduleModel();
            classscheduleModel.getschoolCalendarList(this.listQuery).then((res) =>{
                this.handleRes(res, () => {
                    let list = this.getNewList(res.data.data.list,'newStartTime', 'startTime', 'simpler');
                        list = this.getNewList(res.data.data.list,'newEndTime','endTime', 'simpler');
                        list = this.getNewList(res.data.data.list,'newFirstWeek','firstWeek', 'complicated');

                    this.table_data = list;
                    this.total = res.data.data.totalCount;
                })
                this.setTableScrollHeight();
                this.loadingTable = false;
            }).catch(err => {
                this.loadingTable = false;
            })
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case 'primary':
                    this.listQuery.name = this.semesterName
                    this.getList(1)
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 获取新对象
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-08-14 10:31:26
         */
         getNewList (list, tar, ori, type) {
            let methObj = {
                simpler() {
                    list.forEach((item) => {
                        let idx = 0;
                        item[tar] = item[ori].replaceAll(/[^\\\\x00-\\\\xff]+/g, function (val) {
                            idx++;
                            if (idx > 2) {
                                return ''
                            } else {
                                return '-'
                            }

                        })
                    })
                    return list;
                },
                complicated() {
                    list.forEach((item) => {
                        let idx = 0;
                        item[tar] = item[ori].replaceAll(/[^\\\\x00-\\\\xff]+/g, function (val) {
                            idx++;
                            if (idx === 3) {
                                return ' 至 '
                            } else if (idx === 6) {
                                return ''
                            } else {
                                return '-'
                            }
                        })
                    });

                    return list;
                }
            };
            return methObj[type]()
         },
        /**
         * 操作按钮
         * addCalendar      添加校历
         * lookCalendar  查看校历
         * edit   编辑
         * addCalendarSubmit    添加/编辑 【确定】
         * del      删除
         */
         addCalendar(){
            this.addCalendarDialogObj.title = '添加'
            this.addCalendarDialogObj.dialogVisible = true
         },
         lookCalendar(data, type = '1'){
            this.savePageInfo();
            // 1查看校历  2设置校历
            this.$emit('changeComp', {
                index: 1,
                data: {
                    type: type,
                    ...data
                }
            })
        },
        edit(data){
            console.log(data,'编辑的数据');
            this.addCalendarRulesForm.id = data.id
            this.addCalendarRulesForm.name = data.name
            let endTime = data.endTime.replace(/[年月]/g,"-")
            this.addCalendarRulesForm.endTime = endTime.replace(/[日]/,"")
            let start = data.startTime.replace(/[年月]/g,"-")
            this.addCalendarRulesForm.startTime = start.replace(/[日]/,"")

            const strArr = data.firstWeek.split('-', 2)
            this.addCalendarRulesForm.firstWeekStartTime = strArr[0]
            this.addCalendarRulesForm.firstWeekEndTime = strArr[1]


            let fstart = strArr[0].replace(/[年月]/g,"-")
            // this.addCalendarRulesForm.firstWeekStartTime = fstart.replace(/[日]/g,"")
            // let fend = strArr[1].replace(/[年月]/g,"-")
            // this.addCalendarRulesForm.firstWeekEndTime = fend.replace(/[日]/g,"")
            this.addCalendarRulesForm.firstWeekd = fstart.replace(/[日]/g,"")

            this.addCalendarDialogObj.title = '编辑'
            this.addCalendarDialogObj.dialogVisible = true
        },
        addCalendarSubmit(type) {
            let start = this.addCalendarRulesForm.firstWeekStartTime .replace(/[年月]/g,"-")
            this.addCalendarRulesForm.firstWeekStartTime = start.replace(/[日]/,"")
            let endTime = this.addCalendarRulesForm.firstWeekEndTime.replace(/[年月]/g,"-")
            this.addCalendarRulesForm.firstWeekEndTime = endTime.replace(/[日]/,"")
            this.addCalendarRulesForm.schoolId = this.$store.state.schoolId;
            const classscheduleModel = new classScheduleModel();
            classscheduleModel.getschoolCalendarSave(this.addCalendarRulesForm).then((res) =>{
                if (res.data.code === '200') {
                    this.$message.success(this.addCalendarDialogObj.title + '成功')
                    this.getList()
                    this.addCalendarClose()
                    if (type === 2) {
                        // 添加成功，跳转到设置校历页面
                        this.lookCalendar(res.data.data, '2')
                    }
                } else if(res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        addCalendarClose(){
            this.addCalendarRulesForm = this.$options.data().addCalendarRulesForm
            // this.$refs.addAsset.$refs.formRef.clearValidate()
            this.$refs.addAsset.$refs.formRef.resetFields()
            this.addCalendarDialogObj.dialogVisible = false
        },
        /**
         * @Description: 列表页面-删除列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-09 15:53:22
         */
        async del(data) {
            let msg = '此操作将永久删除, 是否继续?'
            const res = await this._fet('/school/schoolCalendarEvent/getIsAddCalendarEvent', {
                semesterId: data.id,
                productId: 'cloud_campus'
            })
            if (res.data.data) {
                msg = '已添校历事件，删除学期将同步删除校历，确定要删除？'
            }
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const param = {
                    id: data.id,
                    schoolId: this.$store.state.schoolId
                }
                this._fet('/school/schoolCalendar/delete', param).then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success('删除成功!')
                        this.getList()
                    })
                })
            }).catch(() => {
            })
        },
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },

    }
    }
</script>
<style  lang="scss" scoped>
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .inline-input {
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .expand-filter {
        padding: 0;
    }
}
</style>
