<template>
    <div class="summary-student-base">
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <expand-filter
                    ref="expandFilter"
                    marginBottom="0px"
                    :formData="formData"
                    :closeWidth="1360"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @dataChange="clickBtn({item:{fn: 'primary'}})"
                >
                    <query-filtering
                        :key="+new Date()"
                        ref="QueryFiltering"
                        slot="screening"
                        :maxLength="12"
                        :treeScreeningData="treeScreeningData"
                        :chaeckIdArr="chaeckIdArr"
                        :restaurantes="restaurantes"
                        @determine="handleSubmitData"
                    ></query-filtering>

                    <div slot="dropdown" style="flex:1;display:flex;justify-content:space-between">
                            <el-button
                                style="margin-left: 0px"
                                type="primary"
                                v-hasPermi="addBtn"
                                @click="add"
                                >添加</el-button
                            >
                <el-dropdown

                            trigger="click"
                            style="background-color: #ffffff"
                        >
                        <el-button
                            type="enquiry"
                            v-hasPermi="[exportBtn[0],exportHeadBtn[0],'studentBase:documentTemplate','studentBase:documentShar','studentBase:batchUploadFace','studentBase:infoUpdate']"
                            style="margin-left: 10px"
                        >
                        更多操作<i class="el-icon-caret-bottom el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu
                        slot="dropdown"
                        style="background-color: #ffffff"
                    >
                        <template v-if="identification == '1'">
                            <el-dropdown-item>
                                <el-popover
                                    placement="right-start"
                                    trigger="hover"
                                    width="110"
                                    popper-class="student-import-popover"
                                >
                                    <el-button
                                        type="text"
                                        class="hover import-button"
                                        @click="importAll"
                                    >
                                        基础信息
                                    </el-button>
                                    <el-button
                                        type="text"
                                        class="hover import-button"
                                        @click="importMore"
                                    >
                                        更多信息
                                    </el-button>
                                    <el-button
                                        slot="reference"
                                        style="width:100%"
                                        type="text"
                                        class="hover"
                                    >
                                        导入
                                    </el-button>
                                </el-popover>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-popover
                                    placement="right-start"
                                    trigger="hover"
                                    width="110"
                                    popper-class="student-import-popover"
                                >
                                    <el-button
                                        type="text"
                                        class="hover import-button"
                                        @click="exportAll(1)"
                                    >
                                        基础信息
                                    </el-button>
                                    <el-button
                                        type="text"
                                        class="hover import-button"
                                        @click="exportMore(1)"
                                    >
                                        更多信息
                                    </el-button>
                                    <el-button
                                        slot="reference"
                                        type="text"
                                        class="hover"
                                        v-hasPermi="exportBtn"
                                    >
                                        导出信息
                                    </el-button>
                                </el-popover>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    type="text"
                                    class="hover"
                                    @click="createHeadImg"
                                    v-hasPermi="createHeadBtn"
                                    :loading="createHeadImgButtonLoading"
                                >
                                    生成头像
                                </el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    type="text"
                                    class="hover"
                                    @click="exportHeadImg"
                                    v-hasPermi="exportHeadBtn"
                                >
                                    导出头像
                                </el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    type="text"
                                    class="hover"
                                    v-hasPermi="['studentBase:documentTemplate']"
                                    @click="editStuInfoTemplate"
                                >编辑模板</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    type="text"
                                    class="hover"
                                    v-hasPermi="['studentBase:documentShar']"
                                    @click="stuFillFormLinkShare"
                                >档案分享</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    :loading="uploadFaceLoading"
                                    type="text"
                                    class="hover"
                                    v-hasPermi="['studentBase:batchUploadFace']"
                                    @click="batchUploadFace"
                                >
                                    批量上传人脸库
                                </el-button>
                            </el-dropdown-item>
                        </template>
                        <el-dropdown-item>
                            <el-button
                                type="text"
                                class="hover"
                                @click="updateData"
                                v-hasPermi="['studentBase:infoUpdate']"
                            >
                                信息更新
                            </el-button>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                    </div>
                    <div
                        v-if="identification === '2'"
                        slot="dropdown"
                        class="operate-wrap"
                    >
                        <el-button
                            type="enquiry"
                            v-hasPermi="exportBtn"
                            @click="exportAll"
                            :loading="exportLoading"
                        >
                            导出
                        </el-button>
                        <el-button
                            style="margin-left: 10px;"
                            type="primary"
                            v-hasPermi="['studentBase:configMoneyCommit']"
                            @click="getSchoolBalance"
                        >余额提醒设置</el-button>
                        <el-button
                            style="margin-left: 10px;"
                            v-has-permi="['studentBase:editBalance']"
                            type="primary"
                            @click="showWithdrawalDialog"
                        >余额修改</el-button>
                    </div>
                </expand-filter>

                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :to-top="false"
                    :config="table_config"
                    :tableData="table_data"
                    :rowStyle="rowStyle"
                    @linkClick="handlerTableLinkClick"
                    @dropDownClick="dropDownClick(arguments)"
                    @handleSelectionChange="handleSelectionChange"
                    @tableSetting="showTableSetting"
                    @handleSortChange="handleSortChange"
                    >
                    <template v-slot:operation="slotData">
                        <el-button
                            v-hasPermi="['studentBase:edit']"
                            type="text"
                            :disabled="slotData.data.status == 2"
                            @click="edit(slotData.data, 'edit')"
                        >编辑</el-button>

                        <el-button
                            v-hasPermi="['studentBase:setClass']"
                            type="text"
                            size="mini"
                            :disabled="slotData.data.status == 2"
                            @click="handleShifts(slotData.data)"
                        >调整班级</el-button>

                        <el-button
                            v-hasPermi="['studentBase:resetDocPass']"
                            type="text"
                            :disabled="slotData.data.status == 2"
                            @click="resetInfoPassword(slotData.data)"
                        >重置档密</el-button>
                        <el-button
                            v-hasPermi="['studentBase:familyNumber']"
                            type="text"
                            :disabled="slotData.data.status == 2"
                            @click="familyCode(slotData.data)"
                        >亲情号</el-button>
                        <el-button
                            v-hasPermi="['studentBase:remove']"
                            type="text"
                            :disabled="slotData.data.status == 2"
                            @click="deleteInfo(slotData.data)"
                        >删除</el-button>

                    </template>
                </table-data>

                <div class="pagination-box">
                    <div class="pagination-left">
                        <span v-if="tableListSel.length > 0" class="pagination-left-label">批量操作</span>
                        <template v-if="tableListSel.length > 0">
                            <el-button
                                class="custom-button-primary"
                                @click="handleBatchShifts"
                            >
                                调班
                            </el-button>
                            <el-button
                                class="custom-button-danger"
                                @click="acts(2)"
                            >
                                毕业
                            </el-button>
                            <el-button
                                class="custom-button-warning"
                                @click="acts(3)"
                            >
                                转学
                            </el-button>
                            <el-button
                                class="custom-button-default"
                                @click="acts(1)"
                            >
                                在籍
                            </el-button>
                            <el-popover
                                placement="top"
                                trigger="hover"
                                width="110"
                                popper-class="student-import-popover"
                            >
                                <el-button
                                    type="text"
                                    class="hover import-button"
                                    @click="exportAll(2)"
                                >
                                    基础信息
                                </el-button>
                                <el-button
                                    type="text"
                                    class="hover import-button"
                                    @click="exportMore(2)"
                                >
                                    更多信息
                                </el-button>
                                <el-button
                                    slot="reference"
                                    v-hasPermi="exportBtn"
                                    class="custom-button-default"
                                    style="margin: 0 10px;"
                                >
                                    导出信息
                                </el-button>
                            </el-popover>
                            <el-button
                                v-hasPermi="createHeadBtn"
                                class="custom-button-default"
                                @click="createHeadImg"
                                :loading="createHeadImgButtonLoading"
                            >
                                生成头像
                            </el-button>
                            <el-button
                                v-hasPermi="exportHeadBtn"
                                class="custom-button-default"
                                @click="exportHeadImg"
                            >
                                导出头像
                            </el-button>
                        </template>
                    </div>
                    <pagination
                        v-if="tableListSel.length === 0"
                        :key="paginationIdx"
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="changeGetList"
                    />
                </div>

                <dialog-wrapper
                    :dialogObj="dialogObj"
                    @handleClose="handleClose"
                >
                    <el-form
                        :model="ruleForm"
                        :rules="rules"
                        ref="ruleForm"
                        label-position="right"
                        label-width="120px"
                    >
                        <div class="lt">
                            <label class="stu-info">学生头像</label>
                            <upload
                                v-model="ruleForm.stuImg"
                                :disabled="isHasStu"
                                :positionStatus="true"
                            ></upload>
                        </div>
                        <div class="rt">
                            <el-form-item
                                label="学生姓名"
                                prop="studentName"
                            >
                                <el-input
                                    v-model="ruleForm.studentName"
                                    :disabled="
                                        isHasStu && ruleForm.studentName !== ''
                                    "
                                    placeholder="请输入"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="学号"
                                prop="studyNo"
                            >
                                <el-input
                                    v-model="ruleForm.studyNo"
                                    placeholder="请输入"
                                    @change="stuNoChange"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="唯一号"
                                prop="onlyCode"
                            >
                                <el-input
                                    v-model="ruleForm.onlyCode"
                                    :disabled="pageType === 'edit'"
                                    placeholder="请输入"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item
                                label="性别"
                                prop="sex"
                            >
                                <el-radio
                                    v-model="ruleForm.sex"
                                    :disabled="isHasStu"
                                    label="1"
                                    >男</el-radio
                                >
                                <el-radio
                                    v-model="ruleForm.sex"
                                    :disabled="isHasStu"
                                    label="2"
                                    >女</el-radio
                                >
                            </el-form-item>
                            <el-form-item
                                label="身份证号"
                                prop="idCard"
                            >
                                <el-input
                                    v-model="ruleForm.idCard"
                                    :disabled="
                                        isHasStu && ruleForm.idCard !== ''
                                    "
                                    placeholder="请输入"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                v-show="showCourseSelection"
                                label="选科组合"
                            >
                                <el-select
                                    v-model="ruleForm.courseSelection"
                                    :disabled="
                                        isHasStu &&
                                        ruleForm.courseSelection !== ''
                                    "
                                    placeholder="请选择"
                                    clearable
                                    filterable
                                    style="width: 100%"
                                >
                                    <el-option
                                        v-for="item in courseSelectionList"
                                        :value="item.code"
                                        :label="item.name"
                                        :key="item.id"
                                    />
                                </el-select>
                                <div style="font-size: 12px">
                                    无组合可选？请点击
                                    <el-button
                                        type="text"
                                        @click="clickCourseSelection"
                                        >选科组合</el-button
                                    >
                                    开启状态
                                </div>
                            </el-form-item>
                        </div>

                        <div class="tac">
                            <xk-button
                                type="primary"
                                @click="submitForm('ruleForm')"
                                :loading="submitFormLoading"
                                v-if="pageType == 'add'"
                            >
                                确定
                            </xk-button>
                            <xk-button
                                type="primary"
                                @click="submitForm('ruleForm')"
                                :loading="submitFormLoading"
                                v-if="pageType == 'edit'"
                            >
                                确定
                            </xk-button>
                            <xk-button
                                type="info"
                                @click="handleClose('ruleForm')"
                                style="margin-left: 24px"
                            >
                                取消
                            </xk-button>
                        </div>
                    </el-form>
                </dialog-wrapper>
                <!-- 调班 -->
                <dialog-wrapper
                    :dialogObj="shiftsDialogObj"
                    @handleClose="handleShiftsClose"
                >
                    <el-form
                        class="common-form"
                        ref="changeRef"
                        :rules="shiftsRules"
                        :model="shiftsForm"
                        label-width="65px"
                    >
                        <el-form-item
                            label="班级"
                            prop="organId"
                        >
                            <el-cascader
                                v-model="shiftsForm.organId"
                                :options="shiftsOptions"
                                :props="shiftsProps"
                                class="input-width-medium"
                                :key="shiftsCascaderKey"
                            />
                        </el-form-item>
                        <div class="tac">
                            <xk-button
                                type="info"
                                @click="handleShiftsClose"
                            >取消</xk-button>
                            <xk-button
                                :loading="shiftsLoading"
                                type="primary"
                                @click="handleShiftsSubmit"
                            >确定</xk-button>
                        </div>
                    </el-form>
                </dialog-wrapper>

                <dialog-wrapper
                    :dialogObj="importDialogObj"
                    @handleClose="handleImportClose"
                >
                    <exam-import
                        ref="importRef"
                        :active="importActive"
                        :import-form="importForm"
                        :show-download="showDownload"
                        :upload-excel-header="uploadExcelHeader"
                        :system-excel-header="systemExcelHeader"
                        :key-map="keyMap"
                        :import-info="importInfo"
                        :success-data="successData"
                        :importLoading="importLoading"
                        @uploadChange="importUploadChange"
                        @uploadRemove="importUploadRemove"
                        @next="handleImportNext"
                        @before="handleImportBefore"
                        @close="handleImportClose"
                        @importSelChange="importSelChange"
                        @download="downloadImportTemplate"
                        @save="handleImportSave"
                    >
                        <div></div>
                    </exam-import>
                </dialog-wrapper>
                <!-- 余额提醒设置 -->
                <dialog-wrapper
                    :dialogObj="balanceDialog"
                    @handleClose="closeSchoolBalance"
                >
                    <el-form
                        ref="balanceRef"
                        :model="balanceForm"
                        :rules="balanceRules"
                        label-width="auto"
                    >
                        <el-form-item
                            prop="isSwitch"
                            label="提醒开关"
                        >
                            <el-switch
                                v-model="balanceForm.isSwitch"
                                active-value="1"
                                inactive-value="0"
                            ></el-switch>
                        </el-form-item>
                        <el-form-item
                            v-if="balanceForm.isSwitch === '1'"
                            prop="balance"
                            label="余额最低值"
                        >
                            <el-input
                                v-model="balanceForm.balance"
                                placeholder="请输入余额最低值"
                                clearable
                                maxlength="3"
                                show-word-limit
                            >
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div class="tac">
                        <xk-button
                            type="primary"
                            @click="saveSchoolBalance"
                            >保存</xk-button
                        >
                        <xk-button
                            type="info"
                            style="margin-left: 24px"
                            @click="closeSchoolBalance"
                            >取消</xk-button
                        >
                    </div>
                </dialog-wrapper>
                <!-- 修改余额 -->
                <dialog-wrapper
                    :dialogObj="withdrawalDialog"
                    @handleClose="closeWithdrawal"
                >
                    <withdrawal-form-data
                        ref="withdrawalRef"
                        :form="withdrawalForm"
                        :rules="withdrawalRules"
                        @submit="saveWithdrawal"
                        @close="closeWithdrawal"
                    ></withdrawal-form-data>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialogObj="importExistingDialogObj"
                    @handleClose="handleImportExistingClose"
                >
                    <ExamImportMore
                        ref="importRefMore"
                        :activeMore="activeMore"
                        :importMoreForm="importMoreForm"
                        :importMoreInfo="importMoreInfo"
                        :uploadExcelHeaderMore="uploadExcelHeaderMore"
                        :systemExcelHeaderMore="systemExcelHeaderMore"
                        :keyMapMore="keyMapMore"
                        :successDataMore="importSuccessDataMore"
                        @downloadMore="downloadMore"
                        @nextMore="nextMore"
                        @closeMore="closeMore"
                        @uploadChangeMore="uploadChangeMore"
                        @uploadRemoveMore="uploadRemoveMore"
                        @saveMore="saveMore"
                        @beforeMore="beforeMore"
                        @importSelChangeMore="importSelChangeMore"
                        @handlerSelectFileCategoryChange="
                            handlerSelectFileCategoryChange
                        "
                    />
                </dialog-wrapper>
                <!-- 生成头像弹框 -->
                <dialog-wrapper
                    :dialogObj="createHeadImgDialogObj"
                    @handleClose="handleHeadImgClose"
                >
                    <el-form
                        :model="headImgFormData"
                        :rules="headImgFormRules"
                        label-width="0px"
                        ref="formRef"
                        label-posiiton="right"
                    >
                        <!-- <el-form-item label="" :style="{marginBottom:'0px'}">
                            <span :style="{color:'red'}">提示: 学生较多时，请分年级多批次进行导出</span>
                        </el-form-item> -->
                        <el-form-item label="" prop="id">
                            <el-radio-group v-model="headImgFormData.id">
                                <el-radio
                                    v-for="item in headImgFileNameOptions"
                                    :key="item.id"
                                    :label="item.id"
                                >{{item.name}}</el-radio>
                            </el-radio-group>
                        </el-form-item>

				<div style="text-align: right;">
                		<el-button :loading="createHeadImgButtonLoading" @click="handleImgHeadSubmit" type="primary">确定</el-button>
                		<el-button @click="handleHeadImgClose">取消</el-button>
            	</div>
            </el-form>
                </dialog-wrapper>


                <!-- 数据更新弹窗 -->
                <!-- 批量更新 -->
                <DialogWrapper
                    :dialogObj="updateDialogObj"
                    @handleClose="handleUpdateClose"
                >
                    <div
                        slot="title"
                        style="position: relative"
                    >
                        <div style="text-align: center">{{ updateDialogObj.title }}</div>
                    </div>
                    <!-- 导入 -->
                    <Update
                        ref="updateRef"
                        :active="updateActive"
                        :options="updateOptions"
                        :schools="updateSchools"
                        :import-form="updateForm"
                        :show-download="updateShowDownload"
                        :upload-excel-header="updateUploadExcelHeader"
                        :system-excel-header="updateSystemExcelHeader"
                        :key-map="updateKeyMap"
                        :import-info="updateInfo"
                        :success-data="updateSuccessData"
                        @download="downloadUpdateTemplate"
                        @uploadChange="updateUploadChange"
                        @uploadRemove="updateUploadRemove"
                        @next="handleUpdateNext"
                        @before="handleUpdateBefore"
                        @close="handleUpdateClose"
                        @complete="handleComplete"
                        @importSelChange="updateSelChange"
                        @headerTypeChange="updateHeaderTypeChange"
                        @save="handleUpdateSave"
                        v-loading="loading"
                        element-loading-text="拼命加载中，请稍等"
                    />
                </DialogWrapper>

                <!-- 单学生添加亲情号弹窗 -->
                <DialogWrapper
                    :dialogObj="familyCodeObj"
                    @handleClose="familyCodeClose"
                >
                    <FamilyCodeDialogFormData
                        ref="testSubjectDialogFormData"
                        :ruleForm="familyCodeDialogFormData"
                        :rules="familyRules"
                        :familyCodeDialogFormDataList="familyCodeDialogFormDataList"
                        @openFamilyCode="openFamilyCode"
                        @add="familyCodeAdd"
                        @del="familyCodeDel"
                        @edit="editFamilyCode"
                        @cancel="familyCodeClose"
                    />
                </DialogWrapper>

                <!-- 导出更多信息弹窗 -->
                <dialog-wrapper
                    :dialogObj="exportMoreDialogObj"
                    @handleClose="exportMoreCancel"
                >
                    <el-form
                        :model="exportMoreDialogObj.formData"
                        :rules="exportMoreDialogObj.formRules"
                        label-width="150px"
                        ref="exportMoreFormRef"
                        label-posiiton="right"
                    >
                        <el-form-item
                            label="请选择导出的档案"
                            prop="templateId"
                            style="margin-bottom:120px"
                        >
                            <el-select v-model="exportMoreDialogObj.formData.templateId">
                                <el-option
                                    v-for="item in templateList"
                                    :key="item.id"
                                    :label="item.archivesName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
				        <div style="text-align: right;">
                            <el-button @click="exportMoreCancel">取消</el-button>
                            <el-button
                                :loading="exportMoreDialogObj.loading"
                                @click="exportMoreConfirm"
                                type="primary"
                            >确定</el-button>
                        </div>
                    </el-form>
                </dialog-wrapper>


                <!-- 表格设置面板 -->
                <el-drawer
                    class="table-setting"
                    :visible.sync="tableSettingShow"
                    :append-to-body="true"
                    size="320px"
                    direction="rtl"
                    @close="handlerTableSettingSave">
                    <table-setting
                        :density="tableDensity"
                        :draggingList="tableHeaderAll"
                        :disabledList="['studentName']"
                        @densityChange="handlerTableSettingDensityChange"
                        @reset="handlerTableSettingReset"
                        @sort="handlerTableSettingSort"
                        @close="handlerTableSettingClose"
                    ></table-setting>
                </el-drawer>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    FilterData,
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    throttle,
    debounce,
} from "common-local";
import TableData from "./Sub/TableData"
import {downloadFile, listToTree, hasPermission} from "@/libs/utils.js";

import {mapState} from "vuex";

// 自定义颜色
import btnSty from "@/mixins/btnSty.js";
import SelectTree from "./Summary/SelectTree.vue";
import OperationBtnView from "./Summary/OperationBtnView.vue";
import upload from "./Summary/upload.vue";
import ClassManageAnalysis from "@/components/scrollWrapper/ClassManageAnalysis";
import XLSX2 from "xlsx";
import {TestModel} from "@/models/test";
// 导入弹窗件
import FamilyCodeDialogFormData from "./FamilyCodeDialogFormData";
import examImport from "./Summary/ExamImport/index.vue";
import ExamImportMore from "./Summary/ExamImportMore/index.vue";
import ExpandFilter from "./Sub/ExpandFilter";
import {Desensitization} from "@/libs/desensitization.js";
import {SummaryTeacherBase} from "@/models/SummaryTeacherBase.js";
import { SummaryStudentBase } from "@/models/SummaryStudentBase.js";
import WithdrawalFormData from "@/components/scrollWrapper/Summary/vendingStudent/withdrawalFormData";
import regular from "@/libs/regularExpression";
import onResize from "@/mixins/onResize"
import QueryFiltering from "./Sub/QueryFiltering.vue";
import TableSetting from './Sub/TableSetting.vue';
import RecordsList from '@/libs/pageListOptimal.js';

// 信息更新弹窗
import Update from "./Summary/Update/index.vue"; // 信息更新弹窗

export default {
    mixins: [btnSty, onResize],
    inject: ['mainContainer'],
    components: {
        WithdrawalFormData,
        ClassManageAnalysis,
        FilterData,
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        SelectTree,
        OperationBtnView,
        upload,
        examImport,
        ExamImportMore,
        ExpandFilter,
        QueryFiltering,
        TableSetting,
        Update,
        FamilyCodeDialogFormData,
    },
    props: ["query"],
    data() {
        return {
            // -------------------- 数据更新 ----------------------------
            // 更新弹窗配置
			updateDialogObj: {
				title: "信息更新",
				dialogVisible: false,
				width: "900px",
			},
            // 更新步骤
			updateActive: 0,
            // 更新-下拉选项
			updateOptions: [
				{
					label: "唯一号",
					value: "onlyCode",
				},
				{
					label: "学生姓名",
					value: "studentName",
				},
				{
					label: "班级",
					value: "classes",
				},
				{
					label: "身份证号",
					value: "idCard",
				},
				{
					label: "学号",
					value: "studyNo",
				},
				{
					label: "物理卡号",
					value: "physicalCardNumber",
				},
				{
					label: "消费账号",
					value: "consumNumber",
				},
				{
					label: "学生类型",
					// value: 'studentStayType',
					value: "studentType",
				},
				{
					label: "班级",
					value: "studentClass",
				},
			],
            // 更新-学校列表
			updateSchools: [],
            // 更新数据
			updateForm: {
				schoolId: "",
				standardTextList: ["唯一号"],
				standardValueList: ["onlyCode"],
				renewTextList: ["学生姓名"],
				renewValueList: ["studentName"],
				file: "",
				errType: "1", // 1继续执行  2终止导入
			},
            updateShowDownload: true,
            // 更新弹窗上传表头数据
			updateUploadExcelHeader: [],
			// 更新弹窗系统标准字段名称
			updateSystemExcelHeader: [],
            updateKeyMap: {},
            updateInfo: {
				tips: "上传的文件必须包含：",
				tips2: "直接上传已有的学生更新信息，下一步建立对应关系",
				downloadTemplate: "下载学生信息更新导入表",
				uploadText: "上传填好的学生信息更新表",
			},
            updateSuccessData: 0,
            loading: false,

            // ----------------------------------------------------------
            uploadFaceLoading: false,
            paginationIdx: 0,
            identification: "1", //1表示普通学校；2表示售卖机学生列表
            listQuery: {
                id: "",
                studentName: "",
                schoolId: "",
                // organId: [],
                organIds: [],
                pageNum: 1,
                pageRow: 20,
                status: "1",
                studyNo: "",
                onlyCode: "",
                idCard: "",
                sex: "",
                startTime: "",
                endTime: "",
                archivesCompleteness: "",
                sortFiled: '',//排序字段 1 姓名 2 唯一号 3 年级班级
                sortType: '',//排序方式 1 升序 2 降序
                schoolNumList: []
            },
            treeShowData: "",
            // 调班
            shiftsLoading: false,
            shiftsCascaderKey: + new Date(),
            shiftsProps: {
                label: "name",
                children: "child",
                value: "id",
                emitPath: false,
            },
            shiftsOptions: [],
            shiftsForm: {
                idList: [],
                organId: "",
            },
            shiftsRules: {
                organId: [
                    {
                        required: true,
                        message: "请选择班级",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            shiftsDialogObj: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            total: 0,
            treeData: {
                type: "selectTree",
                label: "班级",
                value: "",
                placeholder: "请选择班级",
                show: true,
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                dataList: [],
                showAlways: true,
                filter(data) {
                    return data.organType != 1;
                },
            },

            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    studentName: "",
                },
                data: [],
                btnList: [
                    // {
                    //     type: "primary",
                    //     text: "查询",
                    //     fn: "primary",
                    //     auth: ["studentBase:list"],
                    // },
                    // {
                    //     type: "enquiry",
                    //     text: "重置",
                    //     fn: "reset",
                    // },
                ],
            },
            //售卖机头部筛选
            screen1: [
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "姓名",
                    key: "studentName",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "身份证号",
                    key: "idCard",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "学号",
                    key: "studyNo",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "唯一号",
                    key: "onlyCode",
                },
                {
                    type: "select",
                    label: "",
                    value: "",
                    placeholder: "性别",
                    key: "sex",
                    list: [
                        {
                            label: "男",
                            value: 1,
                        },
                        {
                            label: "女",
                            value: 2,
                        },
                    ],
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "校园地点",
                    key: "campusName",
                },
                {
                    type: "datePick",
                    label: "绑定日期",
                    key: "bindingDate",
                    value: "",
                    picker: true,
                    format: "yyyy-MM-dd",
                },
            ],
            //普通学校头部筛选
            screen2: [
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "姓名",
                    key: "studentName",
                },
                {
                    label: "",
                    placeholder: "班级",
                    type: "cascader",
                    value: "",
                    list: [],
                    cascaderProps: {
                        label: "name",
                        multiple: true,
                        value: "id",
                        checkStrictly: true,
                    },
                    filter(data) {
                        return data.organType != 1;
                    },
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "身份证号",
                    key: "idCard",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "学号",
                    key: "studyNo",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "唯一号",
                    key: "onlyCode",
                },
                {
                    type: "select",
                    label: "",
                    value: "",
                    placeholder: "性别",
                    key: "sex",
                    list: [
                        {
                            label: "男",
                            value: 1,
                        },
                        {
                            label: "女",
                            value: 2,
                        },
                    ],
                },
                {
                    type: "select",
                    label: "",
                    value: 1,
                    placeholder: "状态",
                    key: "status",
                    list: [
                        {
                            label: "在籍",
                            value: 1,
                        },
                        {
                            label: "毕业",
                            value: 2,
                        },
                        {
                            label: "转学",
                            value: 3,
                        },
                    ],
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "档案进度小于",
                    key: "archivesCompleteness",
                    appendText: "%",
                },
                {
                    type: "select",
                    label: "",
                    value: "",
                    placeholder: "头像",
                    key: "headImg",
                    list: [
                        {
                            label: "有",
                            value: 1,
                        },
                        {
                            label: "无",
                            value: 2,
                        },
                    ],
                },
            ],
            // 表格配置
            table_config: {
                thead: [],
                check: true,
                rowkey: "id",
                height: "",
                isCloseRow: {key: "status", value: "2"},
            },
            // 表格数据
            table_data: [],
            table_data_ori: [],
            // 弹出框配置
            dialogObj: {
                title: "添加组织",
                dialogVisible: false,
                width: "50%",
            },
            defaultProps: {
                children: "children",
                label: "name",
            },
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,

            template_info: [],

            // 新增
            ruleForm: {
                isNeedFaceLibrary: "",
                studentName: "",
                remark: "",
                id: "",
                schoolId: "",
                idCard: "",
                studyNo: "",
                onlyCode: "",
                status: 1,
                sex: "1",
                classType: 1,
                organId: "",
                courseSelection: "",
            },
            rules: {
                studentName: [
                    {
                        required: true,
                        message: "请输入学生姓名",
                        trigger: ["change", "blur"],
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: ["change", "blur"],
                    },
                ],
                onlyCode: [
                    {
                        required: true,
                        message: "请输入唯一号",
                        trigger: ["change", "blur"],
                    },
                    // {
                    //     validator: (rule, value, callback) => {
                    //         if (!/^[A-Za-z0-9]{9,}$/.test(value)) {
                    //             callback('唯一号只能包含数字和字母,长度最少9位');
                    //         } else {
                    //             callback();
                    //         }
                    //     },
                    //     trigger: ['blur']
                    // }
                ],
                studyNo: [
                    {
                        required: true,
                        message: "请输入学号",
                        trigger: ["change", "blur"],
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (!/^[A-Za-z0-9]+$/.test(value)) {
                                callback("学号必须包含字母和数字");
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                idCard: [
                    {
                        required: true,
                        message: "请输入身份证号",
                        trigger: ["change", "blur"],
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (
                                !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
                                    value,
                                )
                            ) {
                                callback("身份证号不正确");
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
            },
            pageType: "add",
            showCourseSelection: false,
            tableListSel: [],
            organList: [],
            courseSelectionList: [],
            // 导入步骤
            importActive: 0,
            showDownload: true,
            keyMapCache: {},
            keyMap: {},
            // 上传表头数据
            uploadExcelHeader: [],
            // 系统标准字段名称
            systemExcelHeader: [
                {
                    label: "姓名",
                    required: true,
                },
                {
                    label: "性别",
                    required: true,
                },
                {
                    label: "学号",
                    required: true,
                },
                {
                    label: "唯一号",
                    required: true,
                },
                {
                    label: "消费账号",
                    required: false,
                },
                {
                    label: "校区编号",
                    required: false,
                },
                {
                    label: "身份证号",
                    required: false,
                },
                {
                    label: "序列号",
                    required: false,
                },
                {
                    label: "学生类型",
                    required: false
                }
            ],
            // 导入数据
            importForm: {
                file: "",
                errType: "1", // 1继续执行  2终止导入
                sameType: "1", // 1覆盖导入  2追加导入
            },
            // 导入弹窗配置
            importDialogObj: {
                title: "导入",
                dialogVisible: false,
                width: "900px",
            },
            tableJsonData: [],
            importInfo: {
                tips: "上传的文件必须包含：学生所在年级、班级、学生姓名、性别、学号",
                tips2: "直接上传已有的学生信息，下一步建立对应关系",
                downloadTemplate: "下载学生信息导入表",
                uploadText: "上传填好的学生信息表",
                sameInfo: "覆盖导入：更新相同唯一号学生的基础信息",
            },
            isHasStu: false,
            successData: 0,
            importLoading: false,
            submitFormLoading: false,
            oriThead: [
                {
                    type: "images",
                    label: "头像",
                    prop: "stuImg",
                    labelWidth: '155px',
                    imgErrorAdr: require('@/assets/images/imgErrorAdr.png'),
                    className: 'text-spacing-reduction'
                },
                {
                    label: "姓名",
                    sortable: "custom",
                    labelWidth: "100px",
                    prop: "studentName",
                    type: "function",
                    clickAuth: ['studentBase:details'],
                    className: 'text-spacing-reduction',
                    callBack(row) {
                        return `<div style="color:#3c7efe;overflow: hidden;text-overflow: ellipsis;cursor:pointer;">${row.studentName}</div>`;
                    },
                },
                {
                    label: "性别",
                    prop: "sex",
                    type: "function",
                    callBack(row) {
                        return {
                            1: "男",
                            2: "女",
                        }[+row.sex];
                    },
                    className: 'text-spacing-reduction'
                },
                {
                    label: "学号",
                    prop: "studyNo",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "唯一号",
                    prop: "onlyCode",
                    sortable: "custom",
                },
                {
                    label: "身份证号",
                    prop: "idCard",
                    labelWidth: "200px",
                    type: "desensitize",
                    auth: ["studentBase:desensitized"],
                    callBack: (row) => {
                        if (row.idCard.indexOf("*") !== -1) {
                            this._fet(
                                `/school/schoolStudent/getStudentIdCard/${row.id}`,
                                {
                                    id: row.id,
                                },
                            ).then((res) => {
                                if (res.data.code === "200") {
                                    row.idCard = res.data.data;
                                } else if (res.data && res.data.msg) {
                                    this.$message.error(res.data.msg);
                                }
                            });
                        } else {
                            row.idCard = `${row.idCard.slice(
                                0,
                                3,
                            )}***********${row.idCard.slice(-4)}`;
                        }
                    },
                },
                {
                    label: "班级",
                    prop: "organName",
                    sortable: "custom",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "状态",
                    labelWidth: "90px",
                    prop: "status",
                    type: "dropdown",
                    list: [
                        {label: "在籍", value: "1"},
                        {label: "毕业", value: "2"},
                        {label: "转学", value: "3"},
                    ],
                    disabled: !hasPermission(["studentBase:edit"]),
                    className: 'text-spacing-reduction'
                },
                // {
                //   label: "入职时间",
                //   labelWidth:'120px',
                //   prop: "entryTime",
                // },
                {
                    label: "档案进度",
                    labelWidth: "120px",
                    prop: "archivesCompleteness",
                    type: "function",
                    callBack(row) {
                        return row.archivesCompleteness
                            ? row.archivesCompleteness + "%"
                            : "-";
                    },
                },
                {
                    label: "操作",
                    type: "slotAndIcon",
                    labelWidth: "170",
                    slotName: "operation",
                    renderHeader: null,
                    className: 'text-spacing-reduction'
                },
            ],
            // 余额提醒设置
            balanceDialog: {
                title: "余额提醒设置",
                dialogVisible: false,
                width: "600px",
            },
            balanceForm: {
                id: "",
                schoolId: this.schoolId,
                balance: "",
                isSwitch: "",
            },
            balanceRules: {
                balance: [
                    {
                        required: true,
                        message: "请输入余额最低值",
                        trigger: ["blur"],
                    },
                    {
                        pattern: /(^[1-9]\d*$)/,
                        message: "余额最低值只允许输入正整数",
                    },
                ],
            },
            // 余额提现
            withdrawalDialog: {
                title: "修改余额",
                dialogVisible: false,
                width: "650px",
            },
            withdrawalForm: {
                schoolId: "",
                studentId: "",
                onlyCode: "",
                campusId: "",
                transactionAmount: "",
                vendingBalance: "",
                payeePhone: "",
                voucherPhoto: [],
                idCard: "",
                studentName: "",
                sexName: "",
                campusName: "",
                money: "",
            },
            withdrawalRules: {
                idCard: [
                    {
                        required: true,
                        message: "请输入身份证号",
                        trigger: ["change", "blur"],
                    },
                    {
                        pattern:
                            /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/,
                        message: "请输入正确的身份证号",
                    },
                ],
                transactionAmount: [
                    {
                        validator: (rule, value, callback) => {
                            if (value === "") {
                                callback("请输入提现金额");
                            } else if (
                                !/^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/.test(
                                    value,
                                )
                            ) {
                                callback("请输入正确的提现金额");
                            } else if (
                                value > this.withdrawalForm.vendingBalance
                            ) {
                                callback("请输入正确的提现金额");
                            } else {
                                callback();
                            }
                        },
                        required: true,
                        trigger: ["change", "blur"],
                    },
                ],
                payeePhone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: ["change", "blur"],
                    },
                    {
                        pattern: regular.phoneNumber,
                        message: "请输入正确的手机号",
                    },
                ],
                voucherPhoto: [
                    {
                        required: true,
                        message: "请上传凭证",
                        trigger: ["change", "blur"],
                    },
                ],
            },

            /**
             * 导入已有信息弹窗配置
             *
             * */
            importExistingDialogObj: {
                title: "导入已有档案信息",
                dialogVisible: false,
                width: "900px",
            },
            activeMore: 0, // 导入已有档案信息
            // 导入数据
            importMoreForm: {
                file: "",
                errType: "1", // 1继续执行  2终止导入
                archivesPersonType: "1", // 1学生档案  2教师档案
            },
            importMoreInfo: {
                tips: "选择上传模版内容，下载模版，根据模版内容填充数据，上传填写好的文件（如已有填写好的文件，可仅选择跟文档之中对应的模版，上传文件皆可）",
                options: [],
                value: "",
                downloadTemplate: "下载教师信息导入表",
                sameInfo: "覆盖导入：更新相同姓名和手机号老师的基础信息",
            },
            tableJsonDataMore: [],
            // 上传表头数据
            uploadExcelHeaderMore: [],
            systemExcelHeaderMore: [],
            keyMapMore: {},
            importSuccessDataMore: 0,
            // 自定义筛选项及列表
            treeScreeningData: [],  // 筛选项
            chaeckIdArr: [], // 已勾选的  isOff为1
            restaurantes: [],
            // 表格设置面板
            tableSettingShow: false,
            tableDensity: '1',
            tableHeaderAll: [],
            exportLoading: false,
            // 生成头像弹框配置
            createHeadImgDialogObj: {
                title: "选择头像命名格式",
                dialogVisible: false,
                width: "600px",
            },
            // 头像命名格式
            headImgFileNameOptions:[
                {
                    id:"name",
                    name:"姓名"
                },
                {
                    id:"onlyCode",
                    name:"唯一号"
                },
                {
                    id:"no",
                    name:"学号"
                },
                {
                    id:"nameAndCode",
                    name:"姓名-唯一号"
                },
                {
                    id:"nameAndNo",
                    name:"姓名-学号"
                },
            ],
            headImgFormData:{
                id:"name",
                isStrongDown:"2",//是否强制重新生成，1强制重新生成，2不重新生成
            },
            headImgFormRules:{},
            createHeadImgButtonLoading:false,
            currentfamilyCodeList: [],
            //亲情号弹框数据
			familyCodeDialogFormData: {
				schoolId: "",
				onlyCode: "",
                productId: "",
                numLimit: "",
				list: [],
			},
            familyRules: {
				familyCode: [{required: true, message: "请输入亲情号", trigger: blur}],
			},
            // 亲情号弹框
			familyCodeDialogFormDataList: [],
            // 单学生添加亲情号弹出框配置
			familyCodeObj: {
				title: "亲情号",
				dialogVisible: false,
				width: "520px",
			},
            startPhoneLimit: '0',
            isDistance: false,
            numLimit: '',
            rowStyle: {
                height: '48px'
            },
            checkedAll: false,
            indeterminate: false,
            templateList: [],
            exportMoreDialogObj: {
                title: "导出更多信息",
                dialogVisible: false,
                width: "480px",
                loading: false,
                t: '',
                formData: {
                    templateId: "",
                },
                formRules: {
                    templateId: [
                        { required: true, message: '请选择', trigger: 'change' }
                    ],
                }
            },
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            templateInfo: (state) => state.templateInfo,
            gradeLevelName: (state) => state.gradeLevelName,
        }),
        exportUrl() {
            return require("@/assets/images/export.png");
        },

        //导入、导出、添加按钮加权限
        importBtn() {
            return ["studentBase:import"];
        },
        exportBtn() {
            return ["studentBase:export"];
        },
        createHeadBtn() {
            return ["studentBase:generate:head"];
        },
        exportHeadBtn() {
            return ["studentBase:export:head"];
        },
        addBtn() {
            return ["studentBase:add"];
        },
    },
    async created() {
        await this.init();
        this.ruleForm.schoolId = this.listQuery.schoolId =
            this.$store.state.schoolId;

        this.updateForm.schoolId = this.$store.state.schoolId;
        // await this.getDynamicHeader();
        // 路由参数不该携带参数 清空由于路由跳转携带的参数
        if (this.$route.query.formData) {
            this.$router.push({query: {}});
        }
        let recordsList = new RecordsList();
        let records = recordsList.get(this.$route.name);
        if(records) {
            console.log('records-------',records)
            if(records.formData && records.formData.data){
                this.formData.data.forEach(item => {
                    item.value = records.formData.data.find(i=>i.key==item.key)?.value;
                })
            }
            if(records.listQuery) {
                this.listQuery = records.listQuery;
            }
        }
        this.getFileCateGory();
        this.getList();
        this.getOrg();
        this.getCourseSelection();
        this.getSystemExcelHeader();
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.$refs.QueryFiltering && this.$refs.QueryFiltering.bindResize()
            }, 1000)
        })
    },
    methods: {
        /**
         * @Description: 获取亲情号配置信息
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-04 19:37:52
         */
        getFamilyCodeConfig() {
            this._fet("/operate/operatePhoneConfig/getPhoneConfigByOnlyCode", {
                schoolId: this.familyCodeDialogFormData.schoolId,
                onlyCode: this.familyCodeDialogFormData.onlyCode,
                productId: 'cloud_campus'
            }).then((res) => {
                this.handleRes(res, () => {
                    console.log('亲情号配置信息获取成功', res)
                    this.startPhoneLimit = res.data.data.longCheck
                    this.numLimit = res.data.data.numLimit
                    this.familyCodeDialogFormData.numLimit = this.numLimit
                })
            })
        },
        /**
         * @Description: 校验手机号是否是长途
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-04 20:01:24
         */
        async checkIsLongDistance(data) {
            await this._fet("/operate/operatePhoneConfig/checkIsLongDistance", {
                schoolId: this.familyCodeDialogFormData.schoolId,
                phone: data.tel,
            }).then((res) => {
                this.handleRes(res, () => {
                    if (res.data.data) {
                        this.$message.warning("不能设置异地手机号为亲情号，请修改");
                    }
                    this.isDistance = res.data.data
                })
            })
        },
        /**
         * @Description: 获取亲情号列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-03 20:44:53
         */
        getFamilyCodeList(data) {
            if (data) {
                this.familyCodeDialogFormData.schoolId = data.schoolId
                this.familyCodeDialogFormData.onlyCode = data.onlyCode
                this.familyCodeDialogFormData.productId = 'cloud_campus'
            }
            this.getFamilyCodeConfig()
            this._fet("/parent/FamilyPhone/list4PageCloud", {
                schoolId: this.familyCodeDialogFormData.schoolId,
                onlyCode: this.familyCodeDialogFormData.onlyCode,
                pageNum: 1,
                pageRow: 10000
            }).then((res) => {
                this.handleRes(res, () => {
                    res.data.data.list.forEach((item, index) => {
                        if (item.guanXi == '班主任') {
                            res.data.data.list.splice(index, 1)
                            res.data.data.list.unshift(item)
                        }
                    })
                    this.familyCodeDialogFormDataList = res.data.data.list
                    this.familyCodeObj.dialogVisible = true;
                })
            })
        },
        /**
         * @Description: 编辑亲情号
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-03 21:07:41
         */
        async editFamilyCode(data) {
            if (this.startPhoneLimit == '1') {
                await this.checkIsLongDistance(data)
            } else {
                this.isDistance = false
            }
            if (this.startPhoneLimit == '0' || this.isDistance == false) {
                this._fet("/parent/FamilyPhone/editFamilyPhone", {
                    id: data.id,
                    schoolId: this.familyCodeDialogFormData.schoolId,
                    onlyCode: this.familyCodeDialogFormData.onlyCode,
                    guanXi: data.guanXi,
                    tel: data.tel,
                    productId: 'cloud_campus'
                }).then((res) => {
                    this.handleRes(res, () => {
                        console.log('亲情号编辑成功', res)
                        this.$refs.testSubjectDialogFormData.addDialogObj.dialogVisible = false
                        this.getFamilyCodeList()
                        this.familyCodeObj.dialogVisible = true;
                        this.$message.success('编辑成功');
                    })
                })
            }
        },
        /**
         * @Description: 打开亲情号弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-02 15:30:15
         * @param {*} data
         */
		familyCode(data) {
            this.getFamilyCodeList(data)
		},
        /**
         * @Description: 打开亲情号弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-02 18:06:55
         */
        openFamilyCode() {
            this.familyCodeObj.dialogVisible = true;
        },
        /**
         * @Description: 关闭亲情号弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-02 15:18:32
         */
		familyCodeClose() {
			// this.$refs.testSubjectDialogFormData.clearData();
			this.familyCodeObj.dialogVisible = false;
			// this.familyCodeDialogFormDataList = [];
			// this.getList();
		},
        /**
         * @Description: 添加亲情号
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-02 15:25:05
         * @param {*} data
         */
		familyCodeAdd(data) {
			// console.log(data, 'data');
            const numLimit = this.numLimit
			if (numLimit && this.familyCodeDialogFormDataList.length >= numLimit) {
				return this.$message.warning('已达到最大数量，无法再添加');
			} else {
                this.familyCodeSuccess(data)
            }
		},
        /**
         * @Description: 删除亲情号
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-02 15:24:30
         * @param {*} data
         * @param {*} index
         */
		familyCodeDel(id) {
            this.$confirm("删除之后不可恢复，是否确认删除？")
                .then((_) => {
                    this._fet("/parent/FamilyPhone/deleteFamilyPhone", {
                        id: id,
                        schoolId: this.familyCodeDialogFormData.schoolId,
                        onlyCode: this.familyCodeDialogFormData.onlyCode,
                    }).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success('删除成功');
                            console.log('亲情号列表获取删除成功', res)
                            this.getFamilyCodeList()
                        })
                    })
                })
                .catch((_) => {});
		},
        /**
         * @Description: 确定添加亲情号
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-02 15:23:55
         * @param {*} data
         */
		async familyCodeSuccess(data) {
            console.log(data, "data", this.familyCodeCheckoutRules(data));
			let a = this.familyCodeCheckoutRules(data);
			switch (a) {
				case "lack":
					return this.$message.warning("请填写完整");
				case "phoneError":
					return this.$message.warning("请输入正确的手机号");
				case "dataError":
					return this.$message.warning("输入数据有误");
				case "phoneNull":
					return this.$message.warning("请输入手机号");
				case "phonerepeat":
					return this.$message.warning("手机号重复");
				case "phone":
					return this.$message.warning("请填写手机号");
				case "correct":
                    if (this.startPhoneLimit == '1') {
                        await this.checkIsLongDistance(data)
                    } else {
                        this.isDistance = false
                    }
                    if (this.startPhoneLimit == '0' || !this.isDistance) {
                        this.familyCodeSend(data);
                    }
					break;
				default:
					break;
			}
		},
        /**
         * @Description: 添加亲情号校验规则
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-02 15:22:58
         * @param {*} data
         */
		familyCodeCheckoutRules(data) {
			let checkStatus = "correct";
			// reg   正则
			const reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
			if (data.tel != '') {
                if (!reg.test(data.tel)) {
                    checkStatus = "phoneError";
                }
                const index = this.familyCodeDialogFormDataList.findIndex(item => item.tel == data.tel)
                if (index != -1) {
                    checkStatus = "phonerepeat";
                }
			} else {
				// tips
				checkStatus = "phoneNull";
			}
			return checkStatus;
		},
        /**
         * @Description: 添加接口
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: pyl
         * @Date: 2024-07-02 18:57:11
         * @param {*} data
         */
        familyCodeSend(data) {
            console.log('学校id', this.familyCodeDialogFormData.schoolId)
            this._fet("/parent/FamilyPhone/addFamilyPhone", {
                schoolId: this.familyCodeDialogFormData.schoolId,
                onlyCode: this.familyCodeDialogFormData.onlyCode,
                guanXi: data.guanXi,
                tel: data.tel,
                productId: 'cloud_campus'
            }).then((res) => {
                this.handleRes(res, () => {
                    console.log('亲情号添加成功', res)
                    this.$refs.testSubjectDialogFormData.addDialogObj.dialogVisible = false
                    this.getFamilyCodeList()
                    this.familyCodeObj.dialogVisible = true;
                    this.$message.success('添加成功');
                })
            })
		},
        // ---------------------- 数据更新 -------------------
        updateData(){
            this.updateDialogObj.dialogVisible = true;
        },
        /**
		 * handleUpdateClose       更新弹窗关闭
		 * downloadUpdateTemplate  下载学生成绩模板
		 * updateUploadChange      已上传文件赋值
		 * updateUploadRemove      删除已上传文件
		 * handleUpdateNext        下一步
		 * updateGetKeyMap         获取keyMap
		 * handleUpdateBefore      上一步
		 * updateSelChange         表头字段下拉选择
		 * handleUpdateSave        保存导入信息
		 * updateGetNewData        获取新生成的数据
		 * updateReadFile          读取文件
		 */
		handleUpdateClose() {
			this.updateDialogObj.dialogVisible = false;
			this.updateShowDownload = true;
			this.updateActive = 0;
			Object.assign(this.$data.updateForm, this.$options.data().updateForm);
            this.updateForm.schoolId = this.$store.state.schoolId;
			this.$refs.updateRef.$refs.importRef.$refs.upload.clearFiles();
			this.$refs.updateRef.$refs.importRef.$data.fileName = "";

		},
		handleComplete() {
            setTimeout(() => {
                this.getList()
            }, 600);
		},
		downloadUpdateTemplate() {
			console.log("[更新-下载模板]this.updateForm", this.updateForm);
			if (this.updateForm.standardValueList.length == 0 || this.updateForm.renewValueList.length == 0) {
				this.$message.warning("请选择标准值及更新值");
				return;
			}
			this.updateForm.standardTextList = this.updateOptions.filter((e) => this.updateForm.standardValueList.includes(e.value)).map((e) => e.label);
			this.updateForm.renewTextList = this.updateOptions.filter((e) => this.updateForm.renewValueList.includes(e.value)).map((e) => e.label);
			console.log("更新-下载模板", this.updateForm.standardTextList, this.updateForm.renewTextList);
			downloadFile(
				{
					url: "/operate/operateStudent/studentUpdateTemplate",
					form: {
						standardTextList: this.updateForm.standardTextList?.join(","),
						renewTextList: this.updateForm.renewTextList?.join(","),
					},
					method: "post",
				},
				() => {},
				() => {},
			);
		},
		async updateUploadChange(file) {
			this.updateForm.file = file.raw;
			let dataBinary = await this.updateReadFile(file.raw);
			let workBook = XLSX2.read(dataBinary, {
				type: "binary",
				cellDates: true,
			});
			let workSheet = workBook.Sheets[workBook.SheetNames[0]];
			this.updateTableJsonData = XLSX2.utils.sheet_to_json(workSheet);
			this.updateUploadExcelHeader = [];
			let header = [];
			const range = XLSX2.utils.decode_range(workSheet["!ref"]);
			for (let c = range.s.c; c <= range.e.c; c++) {
				header.push(XLSX2.utils.encode_col(c) + "1");
			}
			header.forEach((item) => {
				this.updateUploadExcelHeader.push({
					label: workSheet[item].v,
					disabled: false,
				});
			});
		},
		updateUploadRemove() {
			this.updateForm.file = "";
		},
		handleUpdateNext() {
			if (this.updateForm.file === "") {
				return this.$message.warning(this.updateInfo.uploadText);
			}
			if (this.updateActive == 0) {
				if (!this.updateForm.schoolId) {
					this.$message.warning("请选择学校");
					return;
				}
				if (this.updateForm.standardValueList.length == 0 || this.updateForm.renewValueList.length == 0) {
					this.$message.warning("请选择标准值及更新值");
					return;
				}
				let standardTextList = this.updateOptions.filter((e) => this.updateForm.standardValueList.includes(e.value)).map((e) => e.label);
				let renewTextList = this.updateOptions.filter((e) => this.updateForm.renewValueList.includes(e.value)).map((e) => e.label);

				let selectHeader = standardTextList.concat(renewTextList);
				let excelHeader = this.updateUploadExcelHeader.map((e) => e.label);
				let result = selectHeader.length === excelHeader.length && selectHeader.every((a) => excelHeader.some((b) => a === b)) && excelHeader.every((_b) => selectHeader.some((_a) => _a === _b));
				if (!result) {
					this.$message.error("上传文件表头与标准值及更新值不一致");
					return;
				}

				this.updateForm.standardTextList = standardTextList;
				this.updateForm.renewTextList = renewTextList;
				this.updateGetKeyMap();
			}
			this.updateActive++;
		},
		updateGetKeyMap() {
			let requiredKeys = this.updateForm.standardTextList.concat(this.updateForm.renewTextList);
			this.updateSystemExcelHeader = this.updateOptions.map((e) => {
				return {
					label: e.label,
					required: requiredKeys.includes(e.label),
				};
			});

			this.updateSystemExcelHeader = this.updateSystemExcelHeader.filter((i) => requiredKeys.includes(i.label));

			this.updateKeyMap = {};
			this.updateSystemExcelHeader.forEach((item) => {
				let header = this.updateUploadExcelHeader.find((e) => e.label == item.label);
				this.updateKeyMap[item.label] = header?.label ?? "";
			});
		},
		handleUpdateBefore() {
			this.updateActive--;
			this.updateShowDownload = false;
		},
		updateSelChange(data) {
			this.updateUploadExcelHeader.forEach((item) => {
				let find = Object.keys(this.updateKeyMap).find((k) => this.updateKeyMap[k] === item.label);
				item.disabled = !!find;
			});
		},
		updateHeaderTypeChange(data) {
			this.updateForm.standardTextList = data.filter((e) => e.type == "标准值")?.map((e) => e.label) ?? [];
			this.updateForm.standardValueList = this.updateOptions.filter((e) => this.updateForm.standardTextList.includes(e.label))?.map((e) => e.value) ?? [];
			this.updateForm.renewTextList = data.filter((e) => e.type == "更新值")?.map((e) => e.label) ?? [];
			this.updateForm.renewValueList = this.updateOptions.filter((e) => this.updateForm.renewTextList.includes(e.label))?.map((e) => e.value) ?? [];
		},
		handleUpdateSave: debounce(
			function () {
				if (this.updateForm.standardTextList.length == 0 || this.updateForm.standardValueList.length == 0 || this.updateForm.renewTextList.length == 0 || this.updateForm.renewValueList.length == 0) {
					return this.$message.warning("请设置标准值及更新值");
				}
				// 系统必填标准字段
				const requiredField = this.updateSystemExcelHeader.filter((i) => i.required === true);
				// keyMap 选中的对应关系
				let find = requiredField.find((item) => this.updateKeyMap[item.label] === "");
				if (find) {
					return this.$message.warning("缺少必须导入字段名称");
				}
				const summaryModel = new SummaryStudentBase();
				// 根据选中的字段对应关系，生成新的excel文件
				const file = this.updateGetNewData();
				let formData = new FormData();
				formData.append("file", file);
				for (let key in this.updateForm) {
					if (key !== "file") {
						if (["standardTextList", "standardValueList", "renewTextList", "renewValueList"].includes(key)) {
							formData.append(key, this.updateForm[key].join(","));
						} else {
							formData.append(key, this.updateForm[key]);
						}
					}
				}
				this.loading = true;
                formData.append("type", "1");
				summaryModel.studentUpdateImport(formData).then((res) => {
					if (res.data.code === "200") {
						this.loading = false;
						this.updateActive++;
						this.updateSuccessData = res.data.data.rightCount;
                        this.getList()
					} else if (res.data.code === "400") {
						this.$message.error(res.data.msg);
					} else {
						this.loading = false;
						this.downloadErrorListUpdate(formData);
					}
				});
			},
			300,
			true,
		),
		downloadErrorListUpdate(formData) {
			this.$confirm("Excel中存在错误数据，请下载并修改", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					downloadFile(
						{
							url: "/operate/operateStudent/studentUpdateImport",
							method: "post",
							fileName: "信息更新错误表.xlsx",
							form: formData,
						},
						() => {},
						() => {},
					);
				})
				.catch(() => {});
		},
		updateGetNewData() {
			let newData = [];
			// 上传原excel文件数据   updateTableJsonData
			// 上传原excel表头数据   updateUploadExcelHeader
			this.updateTableJsonData.forEach((item) => {
				let obj = {};
				this.updateUploadExcelHeader.forEach((itm) => {
					const find = Object.keys(this.updateKeyMap).find((i) => this.updateKeyMap[i] === itm.label);
					if (find) {
						obj[find] = item[this.updateKeyMap[find]];
					} else {
						obj[itm.label] = item[itm.label];
					}
				});
				newData.push(obj);
			});
			const ws = XLSX2.utils.json_to_sheet(newData);
			const wb = XLSX2.utils.book_new();
			XLSX2.utils.book_append_sheet(wb, ws, "学生信息更新导入模板.xlsx");
			let arrayBuffer = XLSX2.write(wb, {
				bookType: "xlsx",
				bookSST: true,
				type: "array",
			});
			return new File([arrayBuffer], "学生信息更新导入模板.xlsx");
		},
		updateReadFile(file) {
			return new Promise((resolve) => {
				let reader = new FileReader();
				reader.readAsBinaryString(file);
				reader.onload = (ev) => {
					resolve(ev.target.result);
				};
			});
		},
        // ----------------------------------------


        showTableSetting() {
            this.tableSettingShow = true;
        },

        // 表格排序
        handleSortChange({column, prop, order}) {
            const o1 = {
                'studentName': '1',
                'onlyCode': '2',
                'organName': '3'
            }
            const o2 = {
                'ascending': '1',//升序
                'descending': '2'
            }
            this.listQuery.sortFiled = o1[prop];//排序字段
            this.listQuery.sortType = o2[order];//排序方式
            this.getList();
        },
        async init() {
            // this.initAssignment()
            await this.getUserFilter()
            await this.getTableHeader();
        },
        initAssignment () {
            let _this = this;
            this.table_config.thead.forEach((item) => {
                if (item.type === "slotAndIcon") {
                    item.renderHeader = function (h, {column, $index}) {
                        return h("div", {}, [
                            h("span", "操作"),
                            h("span", {
                                class: "el-icon-setting",
                                style: {
                                    cursor: "pointer",
                                    "margin-left": "20px",
                                },
                                on: {
                                    click: function (e) {
                                        _this.tableFilter()
                                    },
                                },
                            }),
                        ]);
                    };
                }
            });
        },
        /**
         * @Description: 获取查询项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-08-23 09:50:20
         */
        async getUserFilter () {
            const summaryStudentBase = new SummaryStudentBase();
            await summaryStudentBase
            .getUserPreferences({
                menuId: this.$route.meta.menuId,
                configType:'1'
            }).then((res) => {
                if (res.data.code == "200") {
                    this.treeScreeningData = JSON.parse(res.data.data.config)
                    let checkSendList = this.treeScreeningData.filter(e => e.isOff == "1")
                    this.chaeckIdArr = checkSendList.map(ii=>ii.conditionId)
                    this.restaurantes = JSON.parse(res.data.data.config)
                    let filterFormData = [];
                    for (let e of checkSendList) {
                        let obj = {
                            placeholder: e.conditionName,
                            label:'',
                            value:''
                        }
                        if(e.archivesTypeId) {
                            obj.key = `archives-${e.design.type}-${e.conditionId}`;
                            obj.placeholder = e.design.label.slice(0,6);
                            obj.archivesTypeId = e.archivesTypeId;
                            obj.fileType = e.design.type;
                            // 更多信息中的筛选项
                            switch(e.design.type) {
                                case "input":
                                    obj.type = "input"
                                    break;
                                case "select":
                                case "radio":
                                case "checkbox":
                                    obj.type = "select"
                                    let options = JSON.parse(e.design.options)
                                    options.forEach((item) => {
                                        item.label = item.label.slice(0,8)
                                    })
                                    console.log('筛选项',options)
                                    obj.list = options
                                    break
                                case "date":
                                    obj.type = "datePick"
                                    obj.startPlaceholder = `${e.design.label}开始日期`
                                    obj.endPlaceholder = `${e.design.label}结束日期`
                                    break;
                            }
                        } else {
                            // 基础信息中的筛选项
                            obj.key = e.conditionId;
                            switch(e.conditionId) {
                                case "studentName": // 学生姓名
                                    obj.type = "input"
                                    break;
                                case "sex": // 性别
                                    obj.type = "select"
                                    obj.list = [
                                        {
                                            label: "男",
                                            value: 1,
                                        },
                                        {
                                            label: "女",
                                            value: 2,
                                        },
                                        {
                                            label: "未填写",
                                            value: '未填写',
                                        }
                                    ]
                                    break
                                case "organIds": // 班级
                                    obj.type = "cascader"
                                    obj.list = []
                                    obj.value = []
                                    obj.cascaderProps={
                                        label: "name",
                                        multiple: true,
                                        value: "id",
                                        // checkStrictly: false,
                                        emitPath:false
                                    }
                                    break
                                case "idCard": // 身份证号
                                    obj.type = "autocomplete"
                                    obj.list = [
                                        {
                                            label: "未填写",
                                            value: '未填写',
                                        }
                                    ]
                                    break;
                                case "studyNo": // 学号
                                    obj.type = "autocomplete"
                                    obj.list = [
                                        {
                                            label: "未填写",
                                            value: '未填写',
                                        }
                                    ]
                                    break;
                                case "onlyCode": // 唯一号
                                    obj.type = "input"
                                    break;
                                case "consumNumber": // 消费账号
                                    obj.type = "autocomplete"
                                    obj.list = [
                                        {
                                            label: "未填写",
                                            value: '未填写',
                                        }
                                    ]
                                    break;
                                case "archivesCompleteness": // 档案进度
                                    obj.type = "input"
                                    obj.placeholder = "档案进度小于"
                                    obj.appendText= "%"
                                    break
                                case "status": // 状态
                                    obj.type = "select"
                                    obj.value = (() => {
                                        let statusByFilter = this.formData.data.filter(item => item.key == "status");
                                        if(statusByFilter.length > 0){
                                        return statusByFilter[0].value;
                                        }else{
                                        return 1;
                                        }

                                    })()
                                    obj.list = [
                                        {
                                            "label": "在籍",
                                            "value": 1
                                        },
                                        {
                                            "label": "毕业",
                                            "value": 2
                                        },
                                        {
                                            "label": "转学",
                                            "value": 3
                                        }
                                    ]
                                    break
                                case "studentType": // 学生类型
                                    obj.type = "select"
                                    obj.list = [
                                        {
                                            "label": "住宿",
                                            "value": 0
                                        },
                                        {
                                            "label": "走读",
                                            "value": 1
                                        },
                                        {
                                            "label": "半读",
                                            "value": 2
                                        }
                                    ]
                                    break
                                case "headImg": // 头像
                                    obj.type = "select"
                                    obj.list= [
                                        {
                                            label: "有",
                                            value: '1',
                                        },
                                        {
                                            label: "无",
                                            value: '0'
                                        },
                                    ]
                                    break
                                case "schoolNumList": // 校区编号
                                    obj.type = "select"
                                    obj.value = []
                                    obj.multiple = true
                                    obj.collapseTags = true
                                    obj.list = Array.from({ length: 10 }, (_, index) => ({
                                        label: (index + 1).toString(),
                                        value: (index + 1).toString()
                                    }))
                                    break
                                default:
                                    break
                            }
                        }
                        filterFormData.push(obj)
                    }
                    this.formData.data = filterFormData
                    console.log(this.treeScreeningData,'==this.treeScreeningData111')
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 获取表格
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-08-23 10:07:08
         */
         async getTableHeader(all) {
            this.table_config.thead = []
            const summaryStudentBase = new SummaryStudentBase();
            try {
                let res = await summaryStudentBase.getUserPreferences({
                    menuId: this.$route.meta.menuId,
                    userId: this.$store.state.userId,
                    configType: '2'
                })
                console.log("[getTableHeader]res:", res)
                if (res.data.code == '200') {
                    this.tableDensity = res.data.data.displayDensity ? res.data.data.displayDensity : '1'
                    this.tableHeaderAll = JSON.parse(res.data.data.config)
                    if (all) {
                        this.tableHeaderAll.forEach(e => {
                            e.isOff =  '1'
                        })
                    }
                    console.log("[getTableHeader]this.tableHeaderAll:", this.tableHeaderAll)
                    let tableHeaderDisplayed = this.tableHeaderAll.filter(e => e.isOff == "1")
                    let labelWidth = document.getElementsByClassName('content-wrapper')[0].offsetWidth - 240
                    let numCalculated = tableHeaderDisplayed.length // 需要计算宽度的列数
                    let useFixedWidth = false // 个别列是否使用固定宽度
                    const fixedWidthHeader = { // 固定宽度列
                        'stuImg': 60,
                        'organName': 180,//班级要求全部展示
                    }
                    if (numCalculated > 1) {
                        let averageWidth = labelWidth / numCalculated // 平分的宽度
                        let maxFixedWidth = -1
                        for (let headerKey in fixedWidthHeader) {
                            if (tableHeaderDisplayed.find(e => e.conditionId === headerKey)) {
                                // 计算个别列使用固定宽度时其他列的总宽度及需要计算宽度的列数
                                labelWidth -= fixedWidthHeader[headerKey]
                                numCalculated--
                                // 计算最大的固定宽度
                                if (fixedWidthHeader[headerKey] > maxFixedWidth) {
                                    maxFixedWidth = fixedWidthHeader[headerKey]
                                }
                            }
                        }
                        // 如果平均宽度比最大的固定宽度还大就使用平均宽度
                        if (averageWidth >= maxFixedWidth) {
                            labelWidth = averageWidth
                            useFixedWidth = false
                        } else {
                            labelWidth = labelWidth / numCalculated
                            useFixedWidth = true
                        }
                    }
                    // 保证最小宽度80
                    labelWidth = Math.max(labelWidth, 80)
                    // console.log("[getTableHeader]useFixedWidth:", useFixedWidth, "labelWidth:", labelWidth)
                    let tableHeader = []
                    console.log("[getTableHeader]tableHeaderDisplayed:", tableHeaderDisplayed)
                    for (let e of tableHeaderDisplayed) {
                        let obj = {
                            label: e.conditionName,
                            prop: e.conditionId,
                            labelWidth: (useFixedWidth && fixedWidthHeader[e.conditionId] ? fixedWidthHeader[e.conditionId] : labelWidth) + 'px'
                        }
                        if (e.conditionInfo) {
                            obj.labelDescription = e.conditionInfo
                        }
                        if(e.design && e.design.type=='date') {
                            obj.labelWidth = "150"
                        }
                        switch(e.conditionId) {
                            case "stuImg": // 头像
                                obj.type = "images"
                                obj.imgErrorAdr = this.imgErrorAdr;
                                obj.align = 'center';
                                obj.className = "text-spacing-reduction";
                                obj.showPreview = true
                                // obj.labelWidth = "155"
                                break
                            case "studentName": // 姓名
                                obj.type = "function"
                                obj.clickAuth = ['studentBase:details']
                                obj.align = 'center';
                                obj.sortable = "custom";
                                obj.className = "text-spacing-reduction";
                                obj.minWidth = "100px"
                                obj.callBack = function(row) {
                                    return `<div style="color:#3c7efe;overflow: hidden;text-overflow: ellipsis;cursor:pointer;">${row.studentName}</div>`;
                                };
                                break;
                            case "sex": // 性别
                                obj.type = "function"
                                obj.align = 'center';
                                obj.className = "text-spacing-reduction"
                                obj.callBack = (row) => {
                                    return {
                                        1: "男",
                                        2: "女",
                                    }[+row.sex];
                                }
                                break
                            case "studyNo": // 学号
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.className = "text-spacing-reduction"
                                break
                            case "onlyCode": // 唯一号
                                // obj.labelWidth = "120px"
                                obj.align = 'center';
                                obj.sortable = "custom"
                                // obj.className = "text-spacing-reduction"
                                break
                            case "consumNumber": // 唯一号
                                // obj.labelWidth = "120px"
                                obj.align = 'center';
                                // obj.className = "text-spacing-reduction"
                                break
                            case "idCard": // 身份证号
                                obj.type = "desensitize"
                                obj.position = "left"
                                obj.align = 'center';
                                obj.auth = ["studentBase:desensitized"]
                                obj.callBack = (row) => {
                                    if (row.idCard.indexOf("*") !== -1) {
                                        this._fet(
                                            `/school/schoolStudent/getStudentIdCard/${row.id}`,
                                            {
                                                id: row.id,
                                            },
                                        ).then((res) => {
                                            if (res.data.code === "200") {
                                                row.idCard = res.data.data;
                                            } else if (res.data && res.data.msg) {
                                                this.$message.error(res.data.msg);
                                            }
                                        });
                                    } else {
                                        row.idCard = `${row.idCard.slice(
                                            0,
                                            3,
                                        )}***********${row.idCard.slice(-4)}`;
                                    }
                                }

                                break
                            case "organName": // 班级
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.sortable = "custom"
                                obj.className = "text-spacing-reduction"
                                // obj.labelWidth = "100px"
                                break
                            case "status": // 状态
                                obj.type = "dropdown"
                                obj.align = 'center';
                                // obj.labelWidth = "90";
                                obj.list = [
                                    {label: "在籍", value: "1"},
                                    {label: "毕业", value: "2"},
                                    {label: "转学", value: "3"},
                                ]
                                obj.className = "text-spacing-reduction"
                                obj.disabled = !hasPermission(["studentBase:edit"])
                                break
                            case "entryTime": // 入职时间
                                // obj.labelWidth = "100px"
                                obj.align = 'center';
                                break
                            case "schoolNum": // 校区编号
                                obj.align = 'center';
                                break
                            case "archivesCompleteness": // 档案进度
                                obj.type = "function"
                                obj.align = 'center';
                                // obj.labelWidth = "120";
                                obj.callBack = (row) => {
                                    return row.archivesCompleteness == null ?  "-" : `${row.archivesCompleteness}%`;
                                }
                                break
                            case "studentType": // 学生类型
                                obj.type = "function"
                                obj.align = 'center';
                                // obj.className = "text-spacing-reduction"
                                obj.callBack = (row) => {
                                    if(!row.studentType){
                                        return "-"
                                    }
                                    return {
                                        0: "住宿",
                                        1: "走读",
                                        2: "半读"
                                    }[+row.studentType];
                                }
                                break
                            default:
                                break
                        }
                        tableHeader.push(obj)
                    }
                    tableHeader.push({
                        label: "操作",
                        type: "slot",
                        labelWidth: "150",
                        slotName: "operation",
                        renderHeader: null,
                        align: 'center',
                        className:"text-spacing-reduction"
                    }, {
                        type: 'setting'
                    })
                    this.table_config.thead = tableHeader
                    this.setTableHeight(this.tableDensity);
                    // console.log("[getTableHeader]this.table_config.thead:", this._.cloneDeep(this.table_config.thead))
                    // this.$forceUpdate();
                    // this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
                    this.$nextTick(() => {
                        this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
                        setTimeout(() => {
                            this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
                            this.$forceUpdate();
                            // this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
                        }, 100);
                    });
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            } catch (error) {
                console.error(error)
            }
        },
        async handleSubmitData (data) {
            console.log("[handleSubmitData]data:", data)
            // 修改筛选项条件，重置列表
            this.formData.data.forEach((item) => {
                if (Array.isArray(item.value)) {
                    item.value = [];
                } else {
                    item.value = "";
                }
                this.listQuery[item.key] = item.value;
            });
            this.treeScreeningData.forEach(item=>{
                let index = data.findIndex(i => i.conditionId === item.conditionId)
                item.isOff = index > -1 ? '1' : '0'
            })

            let params = {
				schoolId: this.listQuery.schoolId,
                menuId: this.$route.meta.menuId,
                configType:'1',
				config: JSON.stringify(this.treeScreeningData),
                userId:this.$store.state.userId
			};
            // console.log('params',params.config);
            const summaryStudentBase = new SummaryStudentBase();
            await summaryStudentBase
            .saveUserPreferences(params)
            .then( async (res) => {
                if (res.data.code == "200") {
                    this.$message.success('保存成功');
                    await this.getUserFilter();
                    this.getOrg();
                    // 因为查询条件变了，listQuery需要重新赋值，重新获取列表
                    this.clickBtn({item:{fn: 'primary'}});
                    await this.getNewCloseWidth();
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                });
        },
        getFileCateGory() {
            const summaryTeacherBase = new SummaryTeacherBase();
            summaryTeacherBase
                .getSelectFileCategory({
                    schoolId: this.$store.state.schoolId,
                    archivesPersonType: "1",
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        console.log(res.data.data, "res.data+++");
                        res.data.data.forEach((item) => {
                            this.$set(item, "label", item.archivesName);
                            this.$set(item, "value", item.id);
                        });
                        this.importMoreInfo.options = res.data.data;
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        getDesignList(e) {
            const summaryTeacherBase = new SummaryTeacherBase();
            summaryTeacherBase
                .getDesignList({
                    archivesTypeId: e,
                    archivesPersonType: "1",
                    schoolId: this.$store.state.schoolId,
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        this.systemExcelHeaderMore = [];
                        res.data.data.forEach((item) => {
                            let obj;
                            if (
                                item.label == "唯一号" ||
                                item.label == "学生姓名"
                            ) {
                                obj = {
                                    label: item.label,
                                    required: true,
                                };
                            } else {
                                obj = {
                                    label: item.label,
                                    required: false,
                                };
                            }

                            this.systemExcelHeaderMore.push(obj);
                        });
                        console.log(res.data.data, "data");
                    } else if (res.data.msg) {
                        this.$message.errType(res.data.msg);
                    }
                });
        },
        /**
         * @Descripttion:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2022-11-28 18:16:46
         */
        changeSel(data) {
            let list = [];
            if (data.ref.getCheckedNodes()[0]) {
                list = data.ref.getCheckedNodes()[0].data;
            }
            this.nodeCLick(list);
            data.ref.dropDownVisible = false;
        },
        /*
         * 表头颜色
         */
        initTabColor() {
            this.template_info = this.templateInfo || [];
            console.log("this.templateInfo:",this.templateInfo)
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    console.log("p.textColor:",p.textColor)
                    this.headerColor = p.textColor;
                }
            });
        },
        // getDynamicHeader        获取学生管理动态表头
        async getDynamicHeader() {
            await this._fet("/school/schoolStudent/getDynamicHeader", {
                schoolId: this.listQuery.schoolId,
                // dictKey: 'courseSelection'
            }).then((res) => {
                if (res.data.code === "200") {
                    if (res.data.data.identification == "2") {
                        this.identification = "2";
                        res.data.data.list.forEach((item) => {
                            if (item.prop === "idCard") {
                                item.type = "desensitize";
                                item.auth = ["studentBase:desensitized"];
                                item.callBack = (row) => {
                                    if (row.idCard.indexOf("*") !== -1) {
                                        this._fet(
                                            `/school/schoolStudent/getStudentIdCard/${row.id}`,
                                            {
                                                id: row.id,
                                            },
                                        ).then((res) => {
                                            if (res.data.code === "200") {
                                                row.idCard = res.data.data;
                                            } else if (
                                                res.data &&
                                                res.data.msg
                                            ) {
                                                this.$message.error(
                                                    res.data.msg,
                                                );
                                            }
                                        });
                                    } else {
                                        row.idCard = `${row.idCard.slice(
                                            0,
                                            3,
                                        )}***********${row.idCard.slice(-4)}`;
                                    }
                                };
                            }
                        });
                        res.data.data.list.forEach((item) => {
                            this.$set(item, 'align', 'center')
                        })
                        this.table_config.thead = res.data.data.list;
                        // this.formData.data = this.screen1;
                    } else {
                        this.oriThead.forEach((item) => {
                            this.$set(item, 'align', 'center')
                        })
                        this.table_config.thead = this.oriThead;
                        this.formData.data = this.screen2;
                    }
                } else {
                    this.formData.data = this.screen2;
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },

        getSystemExcelHeader() {
            this.gradeLevelName
                .split("&lt;")
                .reverse()
                .forEach((item) => {
                    this.systemExcelHeader.unshift({
                        label: item,
                        required: true,
                    });
                });
            this.systemExcelHeader.forEach((item) => {
                this.keyMap[item.label] = "";
            });
        },
        changeGetList(){
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0
            });
            this.getList()
        },
        sortScore(key) {
            return function (a, b) {
                return b[key] - a[key];
            };
        },
        /*
         *   初始化表格
         */

        //售卖机学生列表  identification  为 “1”时，表示普通学校；为“2”时，表示售卖机学生列表
        getList(t) {
            const desensitization = new Desensitization();
            this.$nextTick(() => {
                if(this.$refs.table && this.$refs.table.$refs.multipleTable){
                    this.$refs.table.$refs.multipleTable.doLayout();
                }
            });
            if (this.identification == "1") {
                if (t === 1) {
                    this.listQuery.pageNum = 1;
                }
                this.loadingTable = true;
                console.log(this.listQuery);
                let params = this._.cloneDeep(this.listQuery);
                params.specSearch = [];
                for(let key in params){
                    if(key.includes("archives-")) {
                        params.specSearch.push({
                            field: key.split("-")[2],
                            type: key.split("-")[1],
                            value: key.split("-")[1]==="date" ? (params[key] ? params[key].join(",") : "") : params[key]
                        })
                        delete params[key]
                    }
                }
                this._fet(
                    "/school/schoolStudent/list4Page",
                    params,
                ).then((res) => {
                    if (res.data.code === "200") {
                        this.table_data = desensitization.idCard(
                            res.data.data.list,
                        );
                        // let idCardList = desensitization.idCard(
                        //     res.data.data.list,
                        // );
                        // this.table_data = idCardList.sort(
                        //     this.sortScore("studentName"),
                        // );
                        this.table_data_ori = this._.cloneDeep(
                            res.data.data.list,
                        );
                        this.total = res.data.data.totalCount;
                        this.loadingTable = false;
                        this.paginationIdx += 1;
                        //获取--存储筛选项及滚动条高度
                        this.$nextTick(() => {
                            let recordsList =  new RecordsList()
                            if(recordsList.get(this.$route.name)){
                                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                            }
                            console.log(recordsList.get(this.$route.name),'获取--存储筛选项及滚动条高度');

                            //移除--存储筛选项及滚动条高度
                            recordsList.remove(this.$route.name)
                        });
                    } else {
                        this.$message.error("数据请求失败，请重试");
                        this.loadingTable = false;
                        this.paginationIdx += 1;
                    }
                });
            } else {
                if (t === 1) {
                    this.listQuery.pageNum = 1;
                }
                this.loadingTable = true;
                this._fet(
                    "/school/schoolStudent/listByVending",
                    this.listQuery,
                ).then((res) => {
                    if (res.data.code === "200") {
                        res.data.data.list.forEach((item) => {
                            if (item.sex) {
                                item.sex = item.sex === "1" ? "男" : "女";
                            }
                            if (item.status) {
                                item.status =
                                    item.status === "1"
                                        ? "在籍"
                                        : item.status === "2"
                                        ? "毕业"
                                        : "转学";
                            }
                        });
                        this.table_data = res.data.data.list;
                        this.total = res.data.data.total;
                        this.loadingTable = false;
                    } else {
                        this.$message.error("数据请求失败，请重试");
                        this.loadingTable = false;
                    }
                });
            }
        },
        getCourseSelection() {
            this._fet("/school/schoolDictValue/listByCondition", {
                schoolId: this.listQuery.schoolId,
                dictKey: "courseSelection",
            }).then((res) => {
                if (res.data.code === "200") {
                    this.courseSelectionList = res.data.data;
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        /**
         * @Description: 获取学生档案部门下拉列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-06-20 09:23:08
         */
        getOrg() {
            let obj = {...this.listQuery};
            Object.assign(obj, {jobScope: ["1", "2", "3"],onlyClassType1: 1});
            console.log(obj, "[[[[objobj]]]]1111111111111111111111111",this.listQuery);
            this._fet(
                // "/school/schoolOrgan/listByCondition",
                "/school/schoolOrgan/listAuthByCondition",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.organList = res.data.data.list;
                    const treeData = listToTree(res.data.data.list, {
                        parentKey: "parentOrg",
                    });
                    this.treeData.dataList = treeData.filter(
                        (i) => i.organType !== "1",
                    );
                    console.log(
                        this.treeData.dataList,
                        "this.treeData.dataList",
                    );
                    let jsonList = JSON.stringify(this.treeData.dataList);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.formData.data.forEach((item) => {
                        let keyAboutClassArr = ["organIds"]
                        if (keyAboutClassArr.includes(item.key)) {
                            item.list = JSON.parse(jsonData);
                        }
                    });

                    // this.list = this.treeData.dataList
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
            this.loadingShow = false;
        },
        clickCourseSelection() {
            /*this.$router.push(
        "/system/baseSetting/SystemDict?dictKey=courseSelection"
      );*/
            this.$router.push({
                name: "SystemDict",
                query: {
                    dictKey: "courseSelection",
                },
            });
        },

        /*
         *   功能按钮
         *   selAll 全选
         *   counterSel 反选
         *   cleanSel 清除
         *   add 新增
         *   importAll 导出
         */
        handlerTableLinkClick(ev, item, index) {
            console.log('进来了')
            // console.log("[handlerTableLinkClick]", ev, item, index)
            if (item.prop === "studentName") {
                this.edit(ev, 'view')
            }
        },
        dropDownClick(data) {
            this.$confirm(
                `确定要改为${
                    data[1].value == 1
                        ? "在籍"
                        : data[1].value == 2
                        ? "毕业"
                        : "转学"
                }状态吗?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    // .map(it => it.id)
                    this._fet("/school/schoolStudent/updateStatus", {
                        list: [data[0]],
                        status: data[1].value,
                    }).then((r) => {
                        if (r.data.code == 200) {
                            this.$message.success("操作成功");
                            this.getList();
                        } else {
                            this.$message.error(r.data.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        handleSelectionChange(data) {
            this.tableListSel = data;

            const selectedCount = data.length;
            const totalCount = this.table_data.length;
            this.checkedAll = selectedCount === totalCount;
            this.indeterminate = selectedCount > 0 && selectedCount < totalCount;
        },
        handleCheckedAll() {
            this.$refs.table.$refs.multipleTable.toggleAllSelection()
        },
        selAll() {
            let ref = this.$refs["table"].$refs.multipleTable;
            // ResourcingOpenOperations.selAll(this.table_data, ref)
            this.table_data.forEach(function (element, index) {
                ref.toggleRowSelection(element, true);
            });
        },
        counterSel() {
            let ref = this.$refs["table"].$refs.multipleTable;
            this.tableListSel = this.counter(
                this.table_data,
                this.tableListSel,
                ref,
            );
        },
        counter(data, selData, ref) {
            let arr = [];
            let arr2 = [];
            data.forEach(function (element, index) {
                const find = selData.find((it) => it.id == element.id);
                if (find) {
                    arr.push(find);
                } else {
                    arr2.push(element);
                }
            });
            this.selAll(data, ref);
            arr.forEach(function (element, index) {
                ref.toggleRowSelection(element, false);
            });
            return arr2;
        },
        cleanSel() {
            let ref = this.$refs["table"].$refs.multipleTable;
            this.tableListSel = [];
            this.table_data.forEach(function (element, index) {
                ref.clearSelection();
            });
        },
        importAll() {
            this.importDialogObj.dialogVisible = true;
        },
        importMore() {
            console.log("ceshiyi");
            this.importExistingDialogObj.dialogVisible = true;
        },
        // 导出--基础信息
        exportAll(t) {
            this.$confirm(`您将导出${t === 2 ? this.tableListSel.length : this.total}条数据，是否导出？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then((_) => {
                    let obj = {
                        schoolId: this.listQuery.schoolId,
                        menuId: this.$route.meta.menuId,
                        specSearch: [],
                    };
                    if (t === 2) {
                        obj.ids = this.tableListSel.map((i) => i.id);
                        obj.identification = 2;
                    } else {
                        let filter = this.formData.data.filter((i) => i.value !== "");
                        if (filter.length === 0) {
                            obj.identification = 3;
                        } else {
                            obj.identification = 1;
                            filter.forEach(item=>{
                                if(item.key.includes("archives-")) {
                                    obj.specSearch.push({
                                        field: item.key.split("-")[2],
                                        type: item.key.split("-")[1],
                                        value: item.key.split("-")[1]==="date" ? (item.value ? item.value.join(",") : "") : item.value
                                    })
                                } else {
                                    obj[item.key] = item.value
                                }
                            })
                        }
                    }
                    this.exportLoading = true;
                    downloadFile(
                        {url: "/school/schoolStudent/exportStu", form: obj},
                        () => {
                            this.$message.success("导出成功");
                            this.exportLoading = false;
                        },
                        () => {},
                    );
                })
                .catch((_) => {});
        },
        // 获取模板列表
        getTemplateList() {
            this._fet("/school/schoolArchivesType/listByCondition", {
                schoolId: this.schoolId,
                archivesPersonType: this.type === "teacher" ? "2" : "1", // 档案应用人类型 1学生档案 2教师档案
            }).then((res) => {
                console.log("[获取模板列表]接口返回：", res);
                this.handleRes(res, () => {
                    this.templateList = res.data.data;
                });
            });
        },
        /**
         * @Description: 导出--更多信息
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-10-23 16:32:16
         */
        async exportMore(t){
            await this.getTemplateList();
            this.exportMoreDialogObj.t = t;
            this.exportMoreDialogObj.dialogVisible = true;
            this.exportMoreDialogObj.formData.templateId = '';
            this.$nextTick(() => {
                this.$refs['exportMoreFormRef'].clearValidate();
            })
        },
        /**
         * @Description: 导出--更多信息--取消
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-10-23 16:32:16
         */
        exportMoreCancel(){
            this.exportMoreDialogObj.dialogVisible = false;
        },
        /**
         * @Description: 导出--更多信息--确定
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-10-23 16:31:03
         */
        exportMoreConfirm() {
            this.$refs['exportMoreFormRef'].validate((valid) => {
                if (valid) {
                    this.$confirm(
                        `您将导出${this.exportMoreDialogObj.t === 2 ? this.tableListSel.length : this.total}条数据，是否导出？`,
                        "提示",
                        {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        }
                    )
                    .then((_) => {
                        let obj = {
                            schoolId: this.listQuery.schoolId,
                            menuId: this.$route.meta.menuId,
                            archivesTypeId: this.exportMoreDialogObj.formData.templateId,
                            specSearch: [],
                        };
                        if (this.exportMoreDialogObj.t === 2) {
                            obj.ids = this.tableListSel.map((i) => i.id);
                            obj.identification = 2;
                        } else {
                            let filter = this.formData.data.filter((i) => i.value !== "");
                            if (filter.length === 0) {
                                obj.identification = 3;
                            } else {
                                obj.identification = 1;
                                filter.forEach(item=>{
                                    if(item.key.includes("archives-")) {
                                        obj.specSearch.push({
                                            field: item.key.split("-")[2],
                                            type: item.key.split("-")[1],
                                            value: item.key.split("-")[1]==="date" ? (item.value ? item.value.join(",") : "") : item.value
                                        })
                                    } else {
                                        obj[item.key] = item.value
                                    }
                                })
                            }
                        }
                        downloadFile(
                            {
                                url: "/school/schoolStudent/exportMoreInfo",
                                fileName: `${this.templateList.find((i) => i.id === this.exportMoreDialogObj.formData.templateId).archivesName}.xlsx`,
                                form: obj
                            },
                            () => {
                                this.$message.success("导出成功");
                                this.exportMoreDialogObj.dialogVisible = false;
                            },
                            () => {},
                        );
                    })
                    .catch((err) => {
                        console.log(err)
                    });
                }
            });
        },
        acts(data) {
            if (this.tableListSel.length === 0) {
                return this.$message.warning("未选择人员信息！");
            }
            let msg =
                data === 4
                    ? "确定要删除您所选中的学生吗？"
                    : `确定要改为${
                          data === 1 ? "在籍" : data === 2 ? "毕业" : "转学"
                      }状态吗?`;
            this.$confirm(msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // .map(it => it.id)
                    if (data !== 4) {
                        this._fet("/school/schoolStudent/updateStatus", {
                            list: this.tableListSel,
                            status: data,
                        }).then((r) => {
                            if (r.data.code == 200) {
                                this.$message.success("操作成功");
                                this.getList();
                            } else {
                                this.$message.error(r.data.msg);
                            }
                        });
                    } else {
                        this._fet("/school/schoolStudent/batchDelete", {
                            idList: this.tableListSel.map((v) => v.id),
                            modify: "5",
                        }).then((r) => {
                            if (r.data.code == 200) {
                                this.$message.success("操作成功");
                                this.getList();
                            } else {
                                this.$message.error(r.data.msg);
                            }
                        });
                    }
                })
                .catch(() => {});
        },
        // 一键删除
        // oneClickDelete() {
        //   this.$confirm("确定要一键删除所选学生吗?", "提示", {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     type: "warning",
        //   })
        //     .then(() => {
        //       this._fet(
        //         "/school/schoolStudent/oneClickDelete",
        //         this.listQuery
        //       ).then((r) => {
        //         if (r.data.code == 200) {
        //           this.$message.success("操作成功");
        //           this.getList();
        //         } else {
        //           this.$message.error(r.data.msg);
        //         }
        //       });
        //     })
        //     .catch(() => {});
        // },

        nodeCLick(data) {
            this.showCourseSelection = data.greadStage === "1";
            this.listQuery.organId = data.id;
            this.listQuery.organType = data.organType;
            // 查询状态为在籍
            this.listQuery.status = 1;
            this.formData.data.forEach((item) => {
                if (item.key === "status") {
                    item.value = 1;
                }
            });
            if (data.organType === "4") {
                this.ruleForm.organId = data.id;
                this.ruleForm.organType = data.organType;
            } else {
                this.ruleForm.organId = "";
                this.ruleForm.organType = "";
            }
            this.getList(1);
        },

        /*
         *       clickBtn 表格筛选
         *    edit表格编辑
         *
         * */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    let text = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
                    this.formData.data.forEach((item) => {
                        if (item.key === "bindingDate") {
                            if (item.value && item.value.length === 2) {
                                this.listQuery.startTime =
                                    item.value[0] + " 00:00:00";
                                this.listQuery.endTime =
                                    item.value[1] + " 23:59:59";
                            } else {
                                this.listQuery.startTime = "";
                                this.listQuery.endTime = "";
                            }
                        } else {
                            this.listQuery[item.key] = item.value;
                        }
                    });
                    if (
                        this.listQuery.archivesCompleteness &&
                        !text.test(this.listQuery.archivesCompleteness)
                    ) {
                        return this.$message.error("档案填写进度请输入正数～");
                    }
                    // this.formData.data.forEach((item) => {
                    //   this.listQuery[item.key] = item.value;
                    // });
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        if(item.key == "organIds"){
                            item.value = [];
                            this.listQuery[item.key] = [];
                        }else{
                            item.value = "";
                            this.listQuery[item.key] = "";
                        }
                    });
                    this.getList(1);
                    break;
            }
        },
        // 批量调班
        handleBatchShifts() {
            if (this.tableListSel.length === 0) {
                return this.$message.warning("未选择学生信息！");
            }
            this.getShiftsList()
            this.shiftsForm.idList = this.tableListSel.map(i => i.id);
            this.shiftsDialogObj.title = "批量调班"
            this.shiftsDialogObj.dialogVisible = true;
        },
        // 调班
        handleShifts(data) {
            this.getShiftsList()
            this.shiftsForm.idList = [data.id];
            this.shiftsDialogObj.title = "调班"
            this.shiftsDialogObj.dialogVisible = true;
        },
        // 获取调班列表
        getShiftsList() {
            if (this.shiftsOptions.length === 0) {
                this._fet("/school/schoolOrgan/getOrganByBatchMoveClass", {
                    schoolId: this.schoolId,
                    onlyClassType1: 1
                }).then((res) => {
                    if (res.data.code === "200") {
                        function setIsLeaf(data) {
                            data.forEach((item) => {
                                if (item.organType === "4") {
                                    delete item.child;
                                    item.disabled = item.classType !== "1";
                                } else if (item.child) {
                                    setIsLeaf(item.child);
                                }
                            });
                        }
                        setIsLeaf(res.data.data);
                        this.shiftsOptions = res.data.data;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        handleShiftsSubmit() {
            this.$refs.changeRef.validate((val) => {
                if (val) {
                    let message
                    if (this.shiftsDialogObj.title === "调班") {
                        message = "是否确认调整学生班级？"
                    } else {
                        message = "是否确认批量调整学生班级？"
                    }
                    this.$confirm(message, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消调班",
                        type: "warning"
                    }).then(() => {
                        this.shiftsLoading = true
                        this._fet("/school/schoolStudent/batchUpdateClass", this.shiftsForm).then((res) => {
                            if (res.data.code === "200") {
                                this.handleShiftsClose()
                                this.getList();
                                this.$message.success("调班成功");
                            } else {
                                this.$message.error("调班失败");
                            }
                        }).finally(() => {
                            this.shiftsLoading = false
                        })
                    }).catch(() => {
                    })
                }
            });
        },
        handleShiftsClose() {
            this.shiftsDialogObj.dialogVisible = false
            this.shiftsForm = this.$options.data.call(this).shiftsForm
            this.shiftsCascaderKey++
            this.$nextTick(() => {
                this.$refs.changeRef.clearValidate();
            })
        },

        add() {
            this.pageType = "add";
            this.dialogObj.title = "添加";
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$router.push({
                // path: "/summary/archives/summaryStudentInfo",
                name: "SummaryStudentInfo",
                query: {
                    pageType: this.pageType,
                    schoolId: this.listQuery.schoolId,
                    showCourseSelection: this.showCourseSelection,
                    basicForm: JSON.stringify(this.ruleForm),
                },
            });
        },
        edit(id, pageType) {
            this.pageType = pageType;
            this.showCourseSelection = id.greadStage === "1";
            this.dialogObj.title = "更新";
            Object.assign(this.ruleForm, id);
            this.ruleForm.organId = id.classes;
            // this.dialogObj.dialogVisible = true;
            // if (this.ruleForm.idCard) {
            //   this.ruleForm.idCard = this.ruleForm._idCard;
            // }
            console.log("[edit]this.ruleForm:", this.ruleForm);
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$router.push({
                // path: "/summary/archives/summaryStudentInfo",
                name: "SummaryStudentInfo",
                query: {
                    pageType: this.pageType,
                    schoolId: this.listQuery.schoolId,
                    showCourseSelection: this.showCourseSelection,
                    basicForm: JSON.stringify(this.ruleForm),
                },
            });
        },
        // 重置档案密码
        resetInfoPassword(row) {
            console.log("[重置档案密码]row:", row);
            this.$confirm("确定重置此学生档案密码?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 确定
                    this._fet("/school/schoolStudent/resetPassword", {
                        id: row.id,
                    }).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success(res.data.data);
                        });
                    });
                })
                .catch(() => {
                    // 取消
                });
        },
        deleteInfo(row){
            this.$confirm("删除后不能恢复，您确定要删除该学生吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 确定
                    this.$confirm("删除该学生后，本系统中已经生成的统计类、记录类数据可能不能保留，并且不能恢复，确认删除该学生吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        // 确定
                    // alert('删除')
                        this._fet("/school/schoolStudent/remove", {
                            id: row.id,
                            schoolId:row.schoolId,
                            onlyCode:row.onlyCode
                        }).then((res) => {
                            if (res.data.code == "200") {
                                this.getList();
                                this.$message.success("删除成功");
                            } else {
                                this.$message.error("删除失败" );
                            }
                        });
                    })
                    .catch(() => {
                        // 取消
                    });
                })
                .catch(() => {
                    // 取消
                });
        },
        handleClose() {
            this.dialogObj.dialogVisible = false;
            this.isHasStu = false;
            this.resetData();
            // this.$refs.ruleForm.resetFields()
        },
        submitForm: debounce(function (formName) {
            this.submitFormLoading = true;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.ruleForm.organId) {
                        this.addNewSector(this.ruleForm);
                    } else {
                        this.$message.error("请选择班级");
                        this.submitFormLoading = false;
                    }
                } else {
                    this.submitFormLoading = false;
                    return false;
                }
            });
        }, 300),
        stuNoChange(data) {
            if (data !== "") {
                this._fet("/school/schoolStudent/getStudentByStudyNo", {
                    schoolId: this.listQuery.schoolId,
                    studyNo: data,
                }).then((res) => {
                    this.handleRes(res, () => {
                        Object.keys(this.ruleForm).forEach((key) => {
                            if (res.data.data.length > 0 && key !== "id") {
                                this.ruleForm[key] =
                                    res.data.data[0][key] !== undefined
                                        ? res.data.data[0][key]
                                        : this.ruleForm[key];
                            }
                        });
                        this.isHasStu = res.data.data.length > 0;
                    });
                });
            } else {
                this.isHasStu = false;
            }
        },
        addNewSector(ruleForm) {
            this._fet("/school/schoolStudent/save", ruleForm).then((res) => {
                this.handleRes(res, () => {
                    this.resetData();
                    this.getList();
                    this.$message.success("成功");
                    this.handleClose();
                    this.submitFormLoading = false;
                });
            });
        },

        addc() {},
        // // 新增取消
        // resetForm(formName) {
        //   this.$refs[formName].resetFields();
        //   // this.handleClose();
        // },

        resetData() {
            this.$refs.ruleForm.resetFields();
            this.ruleForm = {
                studentName: "",
                remark: "",
                id: "",
                schoolId: this.ruleForm.schoolId,
                idCard: "",
                studyNo: "",
                onlyCode: "",
                organId: this.ruleForm.organId,
                status: 1,
                classType: 1,
                sex: "1",
                courseSelection: "",
            };
        },
        /**
         * downloadImportTemplate  下载学生成绩模板
         * importUploadRemove      删除已上传文件
         * importUploadChange      已上传文件赋值
         * handleImportNext        下一步
         * handleImportBefore      上一步
         * handleImportClose       导入弹窗关闭
         * handleImportSave        保存导入信息
         * importSelChange         表头字段下拉选择
         * getNewData              获取新生成的数据
         * readFile                读取文件
         *
         *
         */

        importSelChange(data) {
            this.uploadExcelHeader.forEach((item) => {
                let find = Object.keys(this.keyMap).find(
                    (k) => this.keyMap[k] === item.label,
                );
                item.disabled = !!find;
            });
        },
        importUploadRemove() {
            this.importForm.file = "";
        },
        async importUploadChange(file) {
            this.importForm.file = file.raw;
            let dataBinary = await this.readFile(file.raw);
            let workBook = XLSX2.read(dataBinary, {
                type: "binary",
                cellDates: true,
            });
            let workSheet = workBook.Sheets[workBook.SheetNames[0]];
            this.tableJsonData = XLSX2.utils.sheet_to_json(workSheet);
            this.uploadExcelHeader = [];
            let header = [];
            const range = XLSX2.utils.decode_range(workSheet["!ref"]);
            for (let c = range.s.c; c <= range.e.c; c++) {
                header.push(XLSX2.utils.encode_col(c) + "1");
            }
            header.forEach((item) => {
                this.uploadExcelHeader.push({
                    label: workSheet[item].v,
                    disabled: false,
                });
            });
            // if (this.tableJsonData.length > 0) {
            //   this.uploadExcelHeader = []
            //   Object.keys(this.tableJsonData[0]).forEach((label, index) => {
            //     this.uploadExcelHeader.push({label: label, disabled: false})
            //     // Object.keys(this.keyMap).forEach((key, i) => {
            //     //   if(i === index) this.keyMap[key] = label
            //     // })
            //   })
            // }
        },
        readFile(file) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = (ev) => {
                    resolve(ev.target.result);
                };
            });
        },
        downloadImportTemplate() {
            this._fet("/school/schoolStudent/template?schoolId", {
                schoolId: this.listQuery.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    downloadFile(
                        {
                            url: res.data.data,
                            method: "get",
                        },
                        () => {},
                        () => {},
                    );
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        handleImportNext() {
            if (this.importForm.file === "") {
                return this.$message.warning(this.importInfo.uploadText);
            }
            this.importActive++;
        },
        handleImportBefore() {
            this.importActive--;
            this.showDownload = false;
        },
        handleImportClose() {
            this.importDialogObj.dialogVisible = false;
            this.showDownload = true;
            this.importActive = 0;
            this.importForm.file = "";
            this.$refs.importRef.$refs.importRef.$refs.upload.clearFiles();
            this.$refs.importRef.$refs.importRef.$data.fileName = "";
            this.getList();
        },
        // handleImportSave () {
        //   // 系统必填标准字段
        //   const requiredField = this.systemExcelHeader.filter(
        //     (i) => i.required === true
        //   );
        //   // keyMap 选中的对应关系
        //   let find = requiredField.find((item) => this.keyMap[item.label] === "");
        //   if (find) {
        //     return this.$message.warning("缺少必须导入字段名称");
        //   }
        //   const testModel = new TestModel();
        //   // 根据选中的字段对应关系，生成新的excel文件
        //   const file = this.getNewData();
        //   let formData = new FormData();
        //   formData.append("file", file);
        //   formData.append("schoolId", this.listQuery.schoolId);
        //   formData.append("errType", this.importForm.errType);
        //   formData.append("sameType", this.importForm.sameType);
        //   testModel.studentImportInfo(formData).then((res) => {
        //     if (res.data.code === "200") {
        //       this.importActive++;
        //       this.successData = res.data.data;
        //     } else if (res.data.code === "400") {
        //       this.$message.error(res.data.msg);
        //     } else {
        //       this.downloadErrorList(res.data);
        //     }
        //   });
        // },
        downloadErrorListMore(data) {
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: "/school/schoolArchivesDesign/errorExport",
                            method: "post",
                            form: {
                                paramData: data.info.paramData,
                                archivesTypeId: "1",
                            },
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        getNewData() {
            let newData = [];
            // 上传原excel文件数据   tableJsonData
            // 上传原excel表头数据   uploadExcelHeader
            this.tableJsonData.forEach((item) => {
                let obj = {};
                this.uploadExcelHeader.forEach((itm) => {
                    const find = Object.keys(this.keyMap).find(
                        (i) => this.keyMap[i] === itm.label,
                    );
                    if (find) {
                        obj[find] = item[this.keyMap[find]];
                    } else {
                        obj[itm.label] = item[itm.label];
                    }
                });
                newData.push(obj);
            });
            const ws = XLSX2.utils.json_to_sheet(newData);
            const wb = XLSX2.utils.book_new();
            XLSX2.utils.book_append_sheet(wb, ws, "学生信息导入模板.xlsx");
            let arrayBuffer = XLSX2.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            return new File([arrayBuffer], "学生信息导入模板.xlsx");
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        // 编辑学生档案模板
        editStuInfoTemplate() {
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$router.push({
                name: "SummaryStudentInfoTemplate",
                query: {
                    schoolId: this.listQuery.schoolId,
                    type: "student",
                },
            });
        },
        // 学生填写档案地址分享
        stuFillFormLinkShare() {
            this.$router.push({
                // path: "/summary/archives/summaryStudentInfoShare",
                name: "SummaryStudentInfoShare",
                query: {
                    schoolId: this.listQuery.schoolId,
                    type: "student",
                    formData: JSON.stringify(this.formData), // 保存当前查询条件
                    listQuery: JSON.stringify(this.listQuery), // 保存当前查询条件
                },
            });
        },
        /*
            余额提醒设置
            getSchoolBalance     获取余额提醒设置详情
            saveSchoolBalance    保存余额提醒设置
            closeSchoolBalance   关闭余额提醒设置弹窗
         */
        getSchoolBalance() {
            const schoolId = this.$store.state.schoolId;
            this._fet("/school/schoolBalanceReminderSetting/listByCondition", {
                schoolId: schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    const data = res.data.data[0];
                    this.balanceForm.id = data.id || "";
                    this.balanceForm.balance = data.balance || "";
                    this.balanceForm.isSwitch = data.isSwitch;
                    this.balanceForm.schoolId = schoolId;
                    this.balanceDialog.dialogVisible = true;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        saveSchoolBalance() {
            this.$refs.balanceRef.validate((val) => {
                if (val) {
                    this._fet(
                        "/school/schoolBalanceReminderSetting/save",
                        this.balanceForm,
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("余额提醒设置保存成功");
                            this.closeSchoolBalance();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                }
            });
        },
        closeSchoolBalance() {
            this.balanceDialog.dialogVisible = false;
        },
        showWithdrawalDialog() {
            this.withdrawalDialog.dialogVisible = true;
            this.withdrawalForm.schoolId = this.schoolId;
        },
        saveWithdrawal() {
            let form = this._.cloneDeep(this.withdrawalForm);
            form.voucherPhoto = form.voucherPhoto
                .map((i) => i.response.data.url)
                .toString();
            this._fet("/school/vendingRecord/cashOutRecord", form).then(
                (res) => {
                    if (res.data.code === "200") {
                        this.$message.success("修改成功");
                        this.closeWithdrawal();
                        this.getList();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                },
            );
        },
        closeWithdrawal() {
            this.withdrawalDialog.dialogVisible = false;
            this.withdrawalForm = this.$options.data().withdrawalForm;
            this.$nextTick(() => {
                this.$refs.withdrawalRef.$refs.formRef.clearValidate();
            });
        },
        /**
         * downloadImportTemplate  下载学生成绩模板
         * importUploadRemove      删除已上传文件
         * importUploadChange      已上传文件赋值
         * handleImportNext        下一步
         * handleImportBefore      上一步
         * handleImportClose       导入弹窗关闭
         * handleImportSave        保存导入信息
         * importSelChange         表头字段下拉选择
         * getNewData              获取新生成的数据
         * readFile                读取文件
         */
        importSelChange(data) {
            this.uploadExcelHeader.forEach((item) => {
                let index = Object.keys(this.keyMap).findIndex(
                    (k) => this.keyMap[k] === item.label,
                );
                item.disabled = index > -1;
            });
        },
        importUploadRemove() {
            this.importForm.file = "";
        },
        async importUploadChange(file) {
            this.importForm.file = file.raw;
            let dataBinary = await this.readFile(file.raw);
            let workBook = XLSX2.read(dataBinary, {
                type: "binary",
                cellDates: true,
            });
            let workSheet = workBook.Sheets[workBook.SheetNames[0]];
            this.tableJsonData = XLSX2.utils.sheet_to_json(workSheet);
            let header = [];
            const range = XLSX2.utils.decode_range(workSheet["!ref"]);
            // console.log("[importUploadChange]range:", this._.cloneDeep(range))
            for (let c = range.s.c; c <= range.e.c; c++) {
                let headerCode = XLSX2.utils.encode_col(c) + "1"
                if (workSheet[headerCode]) {
                    header.push(headerCode);
                }
            }
            // console.log("[importUploadChange]workSheet:", this._.cloneDeep(workSheet), "header:", this._.cloneDeep(header))
            // 上传文件表头
            this.uploadExcelHeader = header.map((item) => ({
                label: workSheet[item].v,
                disabled: false,
            }));
            // 系统表头
            this.systemExcelHeader.forEach((item) => {
                const index = this.uploadExcelHeader.findIndex(
                    (i) => i.label === item.label,
                );
                if (index > -1) {
                    this.keyMap[item.label] =
                        this.uploadExcelHeader[index].label;
                    this.uploadExcelHeader[index].disabled = true;
                } else {
                    this.keyMap[item.label] = "";
                }
            });
        },
        readFile(file) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = (ev) => {
                    resolve(ev.target.result);
                };
            });
        },
        downloadImportTemplate() {
            this._fet("/school/schoolStudent/template", {
                schoolId: this.listQuery.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    downloadFile(
                        {
                            url: res.data.data,
                            method: "get",
                        },
                        () => {},
                        () => {},
                    );
                }
            });
        },
        handleImportNext() {
            if (this.importForm.file === "") {
                return this.$message.warning("上传填好的教师信息表");
            }
            this.importActive++;
        },
        handleImportBefore() {
            this.importActive--;
        },
        handleImportClose() {
            this.importDialogObj.dialogVisible = false;
            this.importActive = 0;
            this.importForm.file = "";
            this.$refs.importRef.$refs.importRef.$refs.upload.clearFiles();
            this.$refs.importRef.$refs.importRef.$data.fileName = "";
            this.getList();
        },
        /**
         * @Description: 关闭已有
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-04 10:26:31
         */
        handleImportExistingClose() {
            //this.importExistingDialogObj.dialogVisible = false;
            this.closeMore();
        },
        handleImportSave() {
            const requireList = this.systemExcelHeader.filter(
                (i) => i.required === true,
            );
            const index = requireList.findIndex(
                (item) => this.keyMap[item.label] === "",
            );
            if (index > -1) {
                return this.$message.warning("缺少必须导入字段名称");
            }
            this.importLoading = true
            const testModel = new TestModel();
            const file = this.getNewData();
            let formData = new FormData();
            formData.append("file", file);
            formData.append("schoolId", this.listQuery.schoolId);
            formData.append("errType", this.importForm.errType);
            formData.append("sameType", this.importForm.sameType);

            testModel.studentImportInfo(formData).then((res) => {
                if (res.data.code === "200") {
                    this.importActive++;
                    console.log(res.data.data, "res.data.data111");
                    this.successData = res.data.data;
                    console.log(this.successData, "res.data.data222");
                } else if (res.data.code === "400") {
                    this.$message.error(res.data.msg);
                } else {
                    this.downloadErrorList(res.data);
                }
            }).finally(() => {
                this.importLoading = false
            });
        },
        downloadErrorList(data) {
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: "/school/schoolStudent/studentErrorExport",
                            method: "post",
                            fileName: "导入出错信息表.xlsx",
                            form: {paramData: data.info.paramData},
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        getNewData() {
            let newData = [];
            this.tableJsonData.forEach((item) => {
                let obj = {};
                this.uploadExcelHeader.forEach((itm) => {
                    const find = Object.keys(this.keyMap).find(
                        (i) => this.keyMap[i] === itm.label,
                    );
                    if (find) {
                        obj[find] = item[this.keyMap[find]];
                    } else {
                        obj[itm.label] = item[itm.label];
                    }
                });
                newData.push(obj);
            });
            const ws = XLSX2.utils.json_to_sheet(newData);
            const wb = XLSX2.utils.book_new();
            XLSX2.utils.book_append_sheet(wb, ws, "教师信息导入模板.xlsx");
            let arrayBuffer = XLSX2.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            // XLSX2.writeFile(wb, '成绩导入模板.xlsx')
            return new File([arrayBuffer], "教师信息导入模板.xlsx");
        },

        /**
         * downloadMore 下载模版
         * nextMore 下一步
         * closeMore 关闭
         * uploadChangeMore
         * uploadRemoveMore
         * saveMore 保存导入信息
         * */
        downloadMore() {
            if (this.importMoreInfo.value) {
                downloadFile(
                    {
                        url: "/school/schoolArchivesDesign/template",
                        form: {
                            archivesTypeId: this.importMoreInfo.value,
                            archivesPersonType: "1",
                        },
                    },
                    () => {},
                );
            } else {
                this.$message.error("请选择档案类别");
            }
        },
        nextMore() {
            console.log(
                this.importMoreInfo.value,
                "valuethis.importMoreForm.value",
            );
            if (!this.importMoreInfo.value) {
                return this.$message.warning("请选择档案类别");
            }
            if (this.importMoreForm.file === "") {
                return this.$message.warning("上传填好的学生信息表");
            }
            this.activeMore++;
        },
        closeMore() {
            this.importExistingDialogObj.dialogVisible = false;
            this.activeMore = 0;
            this.importMoreForm.file = "";
            this.importMoreInfo.value = "";
            this.$refs.importRefMore.$refs.importMoreRef.$refs.upload.clearFiles();
            this.$refs.importRefMore.$refs.importMoreRef.$data.fileName = "";
            this.getList();
        },
        async uploadChangeMore(file) {
            this.importMoreForm.file = file.raw;
            let dataBinary = await this.readFile(file.raw);
            let workBook = XLSX2.read(dataBinary, {
                type: "binary",
                cellDates: true,
            });
            let workSheet = workBook.Sheets[workBook.SheetNames[0]];
            this.tableJsonDataMore = XLSX2.utils.sheet_to_json(workSheet);
            let header = [];
            const range = XLSX2.utils.decode_range(workSheet["!ref"]);
            for (let c = range.s.c; c <= range.e.c; c++) {
                header.push(XLSX2.utils.encode_col(c) + "1");
            }
            // 上传文件表头
            this.uploadExcelHeaderMore = header.map((item) => {
                return {
                    label: workSheet[item] ? workSheet[item].v : "",
                    disabled: false,
                };
            });
            // 系统表头
            this.systemExcelHeaderMore.forEach((item) => {
                const index = this.uploadExcelHeaderMore.findIndex(
                    (i) => i.label === item.label,
                );
                if (index > -1) {
                    this.keyMapMore[item.label] =
                        this.uploadExcelHeaderMore[index].label;
                    this.uploadExcelHeaderMore[index].disabled = true;
                } else {
                    this.keyMapMore[item.label] = "";
                }
            });
        },
        uploadRemoveMore() {
            this.importMoreForm.file = "";
        },
        saveMore() {
            let studentName = "学生姓名",
                uniqueNumber = "唯一号";
            if (!this.importMoreInfo.value) {
                return this.$message.warning("请选择档案类别");
            }

            if (
                !this.keyMapMore[studentName] ||
                !this.keyMapMore[uniqueNumber]
            ) {
                return this.$message.warning("缺少必须导入字段名称");
            }

            const requireList = this.systemExcelHeaderMore.filter(
                (i) => i.required === true,
            );
            const index = requireList.findIndex(
                (item) => this.keyMapMore[item.label] === "",
            );
            if (index > -1) {
                return this.$message.warning("缺少必须导入字段名称");
            }
            const summaryTeacherBase = new SummaryTeacherBase();

            const file = this.getNewDataMore();
            let formData = new FormData();
            formData.append("file", file);
            formData.append("schoolId", this.listQuery.schoolId);
            formData.append("archivesTypeId", this.importMoreInfo.value);
            formData.append("errType", this.importMoreForm.errType);
            formData.append(
                "archivesPersonType",
                this.importMoreForm.archivesPersonType,
            );
            console.log(formData, "formData");
            summaryTeacherBase.schoolArchivesDesign(formData).then((res) => {
                if (res.data.code === "200") {
                    this.activeMore++;
                    this.importSuccessDataMore = res.data.data.rightCount
                        ? res.data.data.rightCount
                        : 0;
                } else if (res.data.code === "400") {
                    this.$message.error(res.data.msg);
                } else {
                    this.downloadErrorListMore(res.data);
                }
            });
        },
        beforeMore() {
            this.activeMore--;
        },
        getNewDataMore() {
            let newData = [];
            this.tableJsonDataMore.forEach((item) => {
                let obj = {};
                this.uploadExcelHeaderMore.forEach((itm) => {
                    const find = Object.keys(this.keyMapMore).find(
                        (i) => this.keyMapMore[i] === itm.label,
                    );
                    if (find) {
                        obj[find] = item[this.keyMapMore[find]];
                    } else {
                        obj[itm.label] = item[itm.label];
                    }
                });
                newData.push(obj);
            });
            const ws = XLSX2.utils.json_to_sheet(newData);
            const wb = XLSX2.utils.book_new();
            XLSX2.utils.book_append_sheet(wb, ws, "教师信息导入模板.xlsx");
            let arrayBuffer = XLSX2.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            // XLSX2.writeFile(wb, '成绩导入模板.xlsx')
            return new File([arrayBuffer], "教师信息导入模板.xlsx");
        },
        importSelChangeMore(data) {
            this.uploadExcelHeaderMore.forEach((item) => {
                let index = Object.keys(this.keyMapMore).findIndex(
                    (k) => this.keyMapMore[k] === item.label,
                );
                item.disabled = index > -1;
            });
        },
        handlerSelectFileCategoryChange(e) {
            this.getDesignList(e);
        },
        // 设置表格每列宽度
        setTableColumnsWidth() {
            this.$nextTick(() => {
                let tableHeaderDisplayed = this.tableHeaderAll.filter(e => e.isOff == "1").map(e => e.conditionId)
                tableHeaderDisplayed = this.table_config.thead.filter(e => tableHeaderDisplayed.includes(e.prop))
                let headerKeyName = tableHeaderDisplayed[0].hasOwnProperty('conditionId') ? 'conditionId' : 'prop'
                // console.log("[setTableColumnsWidth]tableHeaderDisplayed:", tableHeaderDisplayed)
                let tableContainerWidth = document.getElementsByClassName('content-wrapper')[0].offsetWidth
                // console.log("[setTableColumnsWidth]tableContainerWidth:", tableContainerWidth)
                let labelWidth = tableContainerWidth - 170
                let numCalculated = tableHeaderDisplayed.length // 需要计算宽度的列数
                let useFixedWidth = false // 个别列是否使用固定宽度
                const fixedWidthHeader = { // 固定宽度列
                    'stuImg': 60,
                }
                if (numCalculated > 1) {
                    let averageWidth = labelWidth / numCalculated // 平分的宽度
                    let maxFixedWidth = -1
                    for (let headerKey in fixedWidthHeader) {
                        if (tableHeaderDisplayed.find(e => e[headerKeyName] === headerKey)) {
                            // 计算个别列使用固定宽度时其他列的总宽度及需要计算宽度的列数
                            labelWidth -= fixedWidthHeader[headerKey]
                            numCalculated--
                            // 计算最大的固定宽度
                            if (fixedWidthHeader[headerKey] > maxFixedWidth) {
                                maxFixedWidth = fixedWidthHeader[headerKey]
                            }
                        }
                    }
                    // 如果平均宽度比最大的固定宽度还大就使用平均宽度
                    if (averageWidth >= maxFixedWidth) {
                        labelWidth = averageWidth
                        useFixedWidth = false
                    } else {
                        labelWidth = labelWidth / numCalculated
                        useFixedWidth = true
                    }
                }
                // 保证最小宽度80
                labelWidth = Math.max(labelWidth, 80)
                for (let e of tableHeaderDisplayed) {
                    e.labelWidth = (useFixedWidth && fixedWidthHeader[e[headerKeyName]] ? fixedWidthHeader[e[headerKeyName]] : labelWidth) + 'px'
                }
                this.$forceUpdate();
                this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
            })
        },
        /**
         * @Description: 获取最新closeWidth
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-17 14:35:20
         */
         async getNewCloseWidth () {
            this.$refs.expandFilter.setShow && this.$refs.expandFilter.setShow()
        },
        batchUploadFace() {
             if (this.tableListSel.length === 0) {
                 this.$message.warning('请选择需要上传人脸库的学生')
                 return
             }
             this.uploadFaceLoading = true
             this._fet('/school/schoolStudent/batchUploadFaceLibrary', {
                 schoolId: this.schoolId,
                 ids: this.tableListSel.map(i => i.id)
             }).then((res) => {
                 if (res.data.code === "200") {
                     this.$message.success("批量上传人脸库成功")
                 } else if (res.data && res.data.msg) {
                     this.$message.error(res.data.msg)
                 }
             }).finally(() => {
                 this.uploadFaceLoading = false
             })
        },
        // 表格设置保存
        async handlerTableSettingSave() {
            console.log("[handlerTableSettingSave]this.tableHeaderAll:", this.tableHeaderAll);
            this.tableSettingShow = false;
            const summaryStudentBase = new SummaryStudentBase();
            try {
                let res = await summaryStudentBase.saveUserPreferences({
                    schoolId: this.$store.state.schoolId,
                    userId: this.$store.state.userId,
                    menuId: this.$route.meta.menuId,
                    configType: '2',
                    displayDensity: this.tableDensity,
                    config: JSON.stringify(this.tableHeaderAll)
                })
                if (res.data.code == '200') {
                    this.getTableHeader()
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            } catch (error) {
                console.error(error)
            }
        },
        // 表格密度修改
        handlerTableSettingDensityChange(density) {
            this.setTableHeight(density);
            this.tableDensity = density;
        },
        /**
         * @Description: 设置表格高度
         * @Author: 武东超
         * @Date: 2024-08-14 09:48:18
         */
        setTableHeight(density) {
            switch (density) {
                case '1':
                    this.rowStyle.height = '56px';
                    break;
                case '2':
                    this.rowStyle.height = '48px';
                    break;
                default:
                    break;
            }
        },
        // 表格表头顺序恢复默认
        handlerTableSettingReset() {
             this.getTableHeader(true)
        },
        // 表格表头顺序改变
        handlerTableSettingSort() {
            console.log("[handlerTableSettingUpdate]this.tableHeaderAll:", this.tableHeaderAll)
            // this.getTableHeader()
            const order = this.tableHeaderAll.map(e => e.conditionId)
            this.table_config.thead.sort((a, b)=>{
                return order.indexOf(a.prop) - order.indexOf(b.prop);
            })
            console.log("[handlerTableSettingUpdate]this.table_config.thead:", this.table_config.thead)
            this.$forceUpdate();
        },
        // 表格设置关闭
        handlerTableSettingClose() {
            this.tableSettingShow = false;
        },
        async createHeadImg() {

            this.$confirm(`您将生成${this.tableListSel.length ? this.tableListSel.length : this.total}条数据，是否生成？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async() => {

                     // 检测头像生成状态
            let headImgCreateStatus = await this.getHeadImgCreateStatus();
            switch (headImgCreateStatus) {
                case 'notStarted':
                    // 未生成则打开弹框
                    this.createHeadImgDialogObj.dialogVisible = true;
                    this.headImgFormData.isStrongDown = "2"
                    break;
                case 'progressing':
                    this.$message({
                        message: '头像生成中，请稍后',
                        type: 'warning'
                    });
                    this.headImgFormData.isStrongDown = "2"
                    break;
                case 'complete':
                    this.$confirm('已生成头像可点击导出，是否确认重新生成?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.headImgFormData.isStrongDown = "1"
                        this.createHeadImgDialogObj.dialogVisible = true;
                    }).catch(() => {
                        this.headImgFormData.isStrongDown = "2"
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        });
                    });
                    break;

                default:
                    break;
            }

                })
                .catch((_) => {});




         },
         handleHeadImgClose() {
            this.createHeadImgDialogObj.dialogVisible = false;
         },
         async handleImgHeadSubmit() {
            this.createHeadImgButtonLoading = true;
            let obj = {
                schoolId: this.listQuery.schoolId,
                menuId: this.$route.meta.menuId,
                nameType: this.headImgFormData.id,
                isStrongDown: this.headImgFormData.isStrongDown
            };
            let filter = this.formData.data.filter((i) => i.value !== "");
            if (this.tableListSel.length > 0) {
                obj.ids = this.tableListSel.map((i) => i.id);
                obj.identification = 2;
            } else {
                if (filter.length === 0) {
                    obj.identification = 3;
                } else {
                    obj.identification = 1;
                    filter.forEach((item) => (obj[item.key] = item.value));
                }
            }

            // 新逻辑
            let res = await this._fet(`/school/schoolStudent/exportHeadImg`,obj)

            if(res.data.code === "200"){
                this.$message.success("头像图片生成中，请稍后");
                this.createHeadImgButtonLoading = false;
                this.handleHeadImgClose();
            }


            // 旧下载
            // downloadFile(
            //     {url: "/school/schoolStudent/ex-portHeadImg", form: obj},
            //     () => {
            //         this.$message.success("导出成功");
            //         this.createHeadImgButtonLoading = false;
            //         this.handleHeadImgClose();
            //     },
            //     () => {},
            // );
            // setTimeout(() => {
            //     this.createHeadImgButtonLoading = false;
            //     this.handleHeadImgClose();
            // },2000)
         },
        //  导出头像
         async exportHeadImg(){

            this.$confirm(`您将导出${this.tableListSel.length ? this.tableListSel.length : this.total}条数据，是否导出？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async() => {

            // 检测头像生成状态
            let headImgCreateStatus = await this.getHeadImgCreateStatus();
                switch (headImgCreateStatus) {
                    case 'notStarted':
                        // 未生成
                        this.$message({
                            message: '请先生成头像，再导出',
                            type: 'warning'
                        });
                        break;
                    case 'progressing':
                        this.$message({
                            message: '头像生成中，请稍后',
                            type: 'warning'
                        });
                        break;
                    case 'complete':
                        let res = await this._fet("/school/schoolStudent/downloadStuImg",{schoolId:this.schoolId})
                        if(res.data.code === "200"){
                            window.open(res.data.data)
                        }
                        break;

                    default:
                        break;
                }

                })
                .catch((_) => {});


         },
        /**
         * 检测头像生成状态
         */
        async getHeadImgCreateStatus(){
            let res = await  this._fet(`/school/schoolStudent/checkDownStatus`,{schoolId:this.schoolId})
            let code = res.data.code;
            // 未完成、进行中、已完成
            let switchObj = {
                "200":"notStarted",//未开始
                "210002":"progressing",//进行中
                "210003":"complete"//已完成
            }

            let finalStatus = switchObj[code]
            return finalStatus
        }
        // toStudentDetail(data) {
        //     this.edit(data, 'view')
        // },
        // handleCellClick(row, column, cell, event) {
        //     console.log('222222222222222走的这里？？？？');
        //     if(column.property == 'studentName') {
        //         this.toStudentDetail(row)
        //     }
        // }
    },
    watch: {
        templateInfo: {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true,
        },
        'mainContainer.sideBarWd': {
            handler() {
                setTimeout(() => {
                    this.setTableColumnsWidth()
                }, 300)
            }
        }
    },
    deactivated() {
        this.shiftsOptions = []
    }
};
</script>

<style scoped lang="scss">
.inline {
    margin: 4px 10px;
    width: 1px;
    height: 24px;
    background-color: #E6E6E6;
}
.export {
    box-sizing: border-box;
    padding: 0 30px 10px 20px;
    overflow: hidden;
    text-align: right;
    background-color: #fff;
}

.summary-student-base {
    padding-right: 10px;

    .table-data {
        margin-top: 10px;
    }
}

.box-card {
    height: calc(100vh - 180px);
    width: 230px;
    float: left;
    border-radius: 6px;
    overflow: auto;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /deep/ .el-card__body {
        height: 100%;
    }
    /* IE 10+ */
}

/*webkit内核*/
.box-card::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* o内核 */
.box-card .-o-scrollbar {
    -moz-appearance: none !important;
    background: rgba(0, 255, 0, 0) !important;
}

/*IE10,IE11,IE12*/
.box-card {
    -ms-scroll-chaining: chained;
    -ms-overflow-style: none;
    -ms-content-zooming: zoom;
    -ms-scroll-rails: none;
    -ms-content-zoom-limit-min: 100%;
    -ms-content-zoom-limit-max: 500%;
    -ms-scroll-snap-type: proximity;
    -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
    -ms-overflow-style: none;
    overflow: auto;
}

::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
}

.right-panel {
    // width: calc(100% - 240px);
    // margin-left: 240px;
}

.w2 {
    width: 50%;
    display: inline-block;
}

.lt {
    width: 30%;
    padding-right: 20px;
    box-sizing: border-box;
    float: left;
}

.rt {
    width: 70%;
    float: left;
}

.tac {
    text-align: right;
}

.el-dropdown-menu {
    width: 111px;
}

.el-dropdown-menu__item {
    padding: 0 10px;

    &:hover {
        background-color: #fff;
    }
}

/deep/ .el-card__body {
    padding: 0;
}

.stu-info {
    margin-left: 30px;
    text-align: center;
}

.operate-wrap {
    background-color: #fff;
    text-align: left;
    padding-right: 10px;
    padding-bottom: 10px;
}

.table-setting {
    ::v-deep .el-drawer__header {
        display: none;
    }
}
.studentNameBox {
    color: #3c7efe;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.pagination-box {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 42px;

    .pagination-left {
        position: absolute;
        left: 0;
        top: 0;
        padding: 2px 18px;
        height: 32px;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .pagination-left-label {
            margin-right: 16px;
            font-size: 14px;
            color: #333333;
        }

        @each $type, $color in (default: #3C7FFF, primary: #3C7FFF, danger: #F57171, warning: #EFBE28) {
            .custom-button-#{$type} {
                height: 28px;
                padding: 0 16px !important;
                border-color: if($type == default, #C7C7C7, $color) !important;

                ::v-deep span {
                    color: if($type == default, #333333, $color);
                }

                &:hover {
                    background-color: if($type == default, #ffffff, $color);
                    border-color: $color !important;

                    ::v-deep span {
                        color: if($type == default, $color, #ffffff);
                    }
                }
            }
        }
    }

    .pagination-container {
        height: auto;
    }
}

.import-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto
}
</style>
<style>
.student-import-popover {
    padding: 0 !important;
    min-width: 0 !important;
}
</style>
