<template>
    <div class="batch-generation-dialog">
        <div class="tip">
            <div class="icon">
                <i class="el-icon-warning-outline"></i>
            </div>
            <div class="text">批量导出内容为学生成长档案</div>
        </div>

        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="140px"
            class="ruleForm"
        >
            <el-form-item
                label="请选择班级"
                prop="organIds"
                class="class-cascader"
            >
                <el-cascader
                    ref="classCascader"
                    v-model="ruleForm.organIds"
                    placeholder="请选择班级（最多可选择5个）"
                    :options="organOptions"
                    filterable
                    popper-class='batch-generation-dialog-cascader-popper'
                    :props="{
                        checkStrictly: false,
                        emitPath: false,
                        label: 'name',
                        value: 'id',
                        multiple: true,
                        children: 'children',
                    }"
                    collapse-tags
                    clearable
                    @change="handleClassChange"
                >

                    <!-- <template slot-scope="{ node, data }">
                        <el-checkbox
                            v-if="data.organType == '4'" 
                            v-model="node.checked"
                            :disabled="data.disabled" 
                            @change="(newData) => handleCheckChange(newData ,node, data)"
                        ></el-checkbox>
                        <span :style="{ marginLeft:  10 + 'px'}">{{ data.name }}</span>
                    </template> -->
                </el-cascader>
            </el-form-item>
            <el-form-item
                label="请选择学生范围"
                prop="downloadAll"
            >
                <el-radio-group v-model="ruleForm.downloadAll">
                    <el-radio
                        v-for="item in rangeOption"
                        :key="item.value"
                        :label="item.value"
                    >
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>

        <div class="bottom">
            <div class="button-group">
                <el-button @click="cancel">取消</el-button>
                <el-button 
                    type="primary"
                    @click="submitForm"
                >确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BatchGenerationDialog",
    props: {
        organOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            ruleForm: {
                organIds: [],
                downloadAll: "2",
            },
            rules: {
                organIds: [
                    {required: true, message: "请选择班级", trigger: "change"},
                ],
                downloadAll: [
                    {
                        required: true,
                        message: "请选择学生范围",
                        trigger: "change",
                    },
                ],
            },
            rangeOption: [
                {
                    value: "2",
                    label: "所选班级有考核记录的学生",
                },
                {
                    value: "1",
                    label: "所选班级全部学生",
                },
            ],
        };
    },
    methods: {
        submitForm() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    console.log(this.ruleForm);
                    this.$emit("submit", this.ruleForm);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            })
        },
        cancel() {
            this.$emit("cancel");
        },
        // 初始化ruleForm
        initRuleForm() {
            this.ruleForm = {
                organIds: [],
                downloadAll: "2",
            };
        },

        handleClassChange(data) {
            console.log('data', data);
            const MAX = 5;
            // 遍历树形结构，如果到达最大值，则禁用所有未选中和organType值为4的节点
            const disableNodes = (nodes) => {
                nodes.forEach((node) => {
                    if (node.children) {
                        disableNodes(node.children);
                    }
                    this.$set(node, "disabled", false);
                    if (this.ruleForm.organIds.length >= MAX && !this.ruleForm.organIds.includes(node.id) && node.organType == 4) {
                        this.$set(node, "disabled", true);
                    } 
                });
            };
            disableNodes(this.organOptions);
            // 这里是为了解决建议中的节点点击后，整个建议列表不更新或者更新慢的问题；
            this.$nextTick(() => {
                // 获取到el-cascader组件的filtering属性和inputValue属性
                let filtering = this.$refs.classCascader.filtering;
                let inputValue = this.$refs.classCascader.inputValue;
                // 如果filtering为true，则重新获取建议
                !!filtering && this.$refs.classCascader.getSuggestions();
                this.$nextTick( () => {
                    // 这里再赋值是为了点击建议中的节点后，原来inputValue的值被清空了，所以需要重新赋值
                    this.$refs.classCascader.inputValue = inputValue;
                })
            })
            
        },
        handleCheckChange(newData,node,data) {
            this.ruleForm.organIds = this.ruleForm.organIds.includes(data.id) ? this.ruleForm.organIds.filter((item) => item !== data.id) : [...this.ruleForm.organIds, data.id];
            const MAX = 5;
            // 遍历树形结构，如果到达最大值，则禁用所有未选中和organType值为4的节点
            const disableNodes = (nodes) => {
                nodes.forEach((node) => {
                    if (node.children) {
                        disableNodes(node.children);
                    }
                    if (this.ruleForm.organIds.length >= MAX && !this.ruleForm.organIds.includes(node.id)) {
                        node.disabled = true;
                        if (node.checked) {
                            node.checked = false;
                            // this.ruleForm.organIds = this.ruleForm.organIds.filter((item) => item !== node.id);
                        }
                    } else {
                        node.disabled = false;
                    }
                });
            };
            disableNodes(this.organOptions);

        }
    },
};
</script>

<style lang="scss">
    .batch-generation-dialog-cascader-popper {
        li[aria-haspopup="true"] {
            .el-checkbox {
                display: none;
            }
        }
    }
</style>

<style lang="scss" scoped>
.batch-generation-dialog {
    width: 100%;
    height: 100%;

    .tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #878787;

        .text {
            margin-left: 8px;
        }
    }

    .ruleForm {
        margin-top: 20px;

        ::v-deep {
            .el-form-item {
                margin-bottom: 20px;
            }
            .class-cascader {
                .el-cascader--small {
                    width: 100%;
                }
            }
            
            .el-radio__label {
                line-height: 33px;
            }
        }
    }

    .bottom {
        .button-group {
            text-align: right;
        }
    }
}
</style>
