<template>
    <!-- 协同办公 > 校园OA > 访客统计 -->
    <visitor-statistics-scroll-wrapper></visitor-statistics-scroll-wrapper>
</template>

<script>
import VisitorStatisticsScrollWrapper from "@/components/scrollWrapper/VisitorStatistics";

export default {
    name: "visitorStatistics",
    components: {
        VisitorStatisticsScrollWrapper,
    },
};
</script>

<style scoped>

</style>
