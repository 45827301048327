<template>
    <!-- 家长绑定审批 -->
    <div class="summary-parent-approve">
        <global-page-back
            detailPageTitle="家长管理"
            @handlerGlobalType="handlerGlobalType"
        ></global-page-back>
        <div class="filter-wrap">
            <!-- 筛选栏 -->
            <expand-filter
                :formData="formData"
                @clickBtn="clickBtn"
                @changeBtnFormType="changeBtnFormType"
                marginBottom="0px"
            >
                <!-- 操作栏 -->
                <template slot="other-btn">
                    <div class="page-operation-wrap">
                        <el-dropdown
                            trigger="click"
                            style="margin-left: 10px"
                        >
                            <!-- 批量操作 -->
                            <el-button
                                type="info"
                                v-has-permi="['parentBase:batch']"
                            >
                                批量操作
                                <i class="el-icon-arrow-down el-icon--right" />
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    @click.native="bulkOperation('2')"
                                >
                                    <el-button
                                        type="success"
                                        plain
                                        size="mini"
                                        style="width: 100%; margin-bottom: 10px"
                                    >
                                        通过
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    @click.native="bulkOperation('3')"
                                >
                                    <el-button
                                        type="danger"
                                        plain
                                        size="mini"
                                        style="width: 100%"
                                    >
                                        拒绝
                                    </el-button>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </expand-filter>
        </div>
        <!-- 表格 -->
        <table-data
            ref="table"
            v-loading="loadingTable"
            :config="tableConfig"
            :tableData="tableData"
            @handleSelectionChange="handleSelectionChange"
        >
            <template v-slot:operation="slotData">
                <div v-if="slotData.data.approveStats == 1">
                    <el-button
                        type="text success"
                        @click="tableClick('2', slotData.data)"
                        >通过</el-button
                    >
                    <el-button
                        type="text danger"
                        @click="tableClick('3', slotData.data)"
                        >拒绝</el-button
                    >
                </div>
                <div v-else>-</div>
            </template>
        </table-data>
        <!-- 分页 -->
        <pagination
            :total="pagingData.total"
            :page.sync="pagingData.pageNum"
            :limit.sync="pagingData.pageRow"
            @pagination="getList()"
        />
    </div>
</template>

<script>
import {mapState} from "vuex";
import GlobalPageBack from "./Sub/GlobalPageBack/index.vue";
import ExpandFilter from "./Sub/ExpandFilter.vue";
import TableData from "./Sub/TableData";
import {Pagination, DialogWrapper} from "common-local";
import {formatTreeData} from "@/libs/utils";
import onResize from "@/mixins/onResize";

export default {
    name: "SummaryParentApprove",
    mixins: [onResize],
    components: {
        GlobalPageBack,
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
    },
    data() {
        return {
            form: {
                parentOrStuName: "",
                phone: "",
                classIdList: [],
                approveStats: "",
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "家长姓名/学生姓名",
                        key: "parentOrStuName",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "手机号",
                        key: "phone",
                    },
                    {
                        placeholder: "班级",
                        type: "cascader",
                        key: "classIdList",
                        value: "",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            emitPath: false,
                        },
                        clearable: false,
                        filter(data) {
                            return data.organType !== "1";
                        },
                    },
                    {
                        placeholder: "状态",
                        type: "select",
                        key: "approveStats",
                        value: "",
                        list: [
                            {
                                label: "审批中",
                                value: "1",
                            },
                            {
                                label: "审批通过",
                                value: "2",
                            },
                            {
                                label: "审批拒绝",
                                value: "3",
                            },
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    },
                    {
                        type: "default",
                        text: "重置",
                        fn: "reset",
                    },
                ],
            },
            //表格加载状态
            loadingTable: false,
            // 表格配置
            tableConfig: {
                thead: [
                    {
                        label: "家长姓名",
                        prop: "parentName",
                        align: "center",
                    },
                    {
                        label: "手机号",
                        prop: "phone",
                        align: "center",
                    },
                    {
                        label: "申请绑定学生姓名",
                        prop: "studentName",
                        align: "center",
                        labelWidth: "150px",
                    },
                    {
                        label: "学生唯一号",
                        prop: "onlyCode",
                        align: "center",
                    },
                    {
                        label: "学号",
                        prop: "studyNo",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "发起申请时间",
                        prop: "createTime",
                        align: "center",
                        labelWidth: "180px",
                    },
                    {
                        label: "审批状态",
                        prop: "approveStats",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            if (row.approveStats == "1") {
                                return "审批中";
                            } else if (row.approveStats == "2") {
                                return "审批通过";
                            } else if (row.approveStats == "3") {
                                return "审批拒绝";
                            }
                        },
                    },
                    {
                        label: "审批人",
                        prop: "approveName",
                        align: "center",
                    },
                    {
                        label: "审批完成时间",
                        prop: "updateTime",
                        align: "center",
                        labelWidth: "180px",
                    },
                    {
                        label: "操作",
                        align: "center",
                        className: "text-spacing-reduction",
                        type: "slot",
                        slotName: "operation",
                    },
                ],
                check: true,
                height: "",
            },
            // 表格数据
            tableData: [
                // {
                //     id: "6777aebf81259204921832e1",
                //     schoolId: "afc58106ce6ee9f5327dd116fe63887a",
                //     parentId: "2164682",
                //     parentName: "刘斌",
                //     phone: "15612818037",
                //     studentId: "603dc62d4f1544a4bd9bc1227a8b130b",
                //     studentName: "周欣然",
                //     onlyCode: "2022G0381",
                //     studyNo: "2022G0381",
                //     classId: "92b8f83f36d04c2880697171deac1f37",
                //     className: "1806",
                //     approveStats: "3",
                //     approveId: "d34a459ca9f74b8e90e46bfaac80482d",
                //     approveName: "周浩",
                //     approveIds:
                //         "30681dda767848a5b63d4aa156a0f995,79c03bd9576c4eb2933673abcc631a93,b9e71d9a835945fe85b7b139eab8cf84,067a50e7ec194435ab6bd2135c4c1145,9c3d191eb80d4810806f72e18abe9660,15c8c6b12d02407fa8dc66dda3dede85,d34a459ca9f74b8e90e46bfaac80482d",
                //     deleteStatus: 0,
                //     createTime: "2025-01-03 17:32:47",
                //     createBy: "2164682",
                //     updateTime: "2025-01-03 17:33:14",
                //     updateBy: "59bf377156c44b7d874c5788be5d9f59",
                //     remark: null,
                //     pageNum: null,
                //     pageRow: null,
                //     parentOrStuName: null,
                //     approveIdList: null,
                // },
                // {
                //     id: "6777ad8d295cc539a2e8eaa8",
                //     schoolId: "afc58106ce6ee9f5327dd116fe63887a",
                //     parentId: "2164682",
                //     parentName: "刘斌",
                //     phone: "15612818037",
                //     studentId: "de590476b64b463286cc3257d347522d",
                //     studentName: "范跃飞",
                //     onlyCode: "2022G2034",
                //     studyNo: "2022G2034",
                //     classId: "cebd25bdf1c54580a2733068d4b06cdc",
                //     className: "1807",
                //     approveStats: "2",
                //     approveId: "d34a459ca9f74b8e90e46bfaac80482d",
                //     approveName: "周浩",
                //     approveIds:
                //         "bb28132e95734fad8baf78eed2da0902,79c03bd9576c4eb2933673abcc631a93,15c8c6b12d02407fa8dc66dda3dede85,d34a459ca9f74b8e90e46bfaac80482d",
                //     deleteStatus: 0,
                //     createTime: "2025-01-03 17:27:41",
                //     createBy: "2164682",
                //     updateTime: "2025-01-03 17:31:00",
                //     updateBy: "59bf377156c44b7d874c5788be5d9f59",
                //     remark: null,
                //     pageNum: null,
                //     pageRow: null,
                //     parentOrStuName: null,
                //     approveIdList: null,
                // },
                // {
                //     id: "6777ad0e295cc539a2e8eaa7",
                //     schoolId: "afc58106ce6ee9f5327dd116fe63887a",
                //     parentId: "2164682",
                //     parentName: "刘斌",
                //     phone: "15612818037",
                //     studentId: "012bb967b35c405aa37dfd3ed4701e4c",
                //     studentName: "杜雨涵",
                //     onlyCode: "2022G2001",
                //     studyNo: "2022G2001",
                //     classId: "64c8fcd8074844e4b48390de16ebfb0b",
                //     className: "1809",
                //     approveStats: "1",
                //     approveId: null,
                //     approveName: "樊荣/袁书湘/张天真/周浩",
                //     approveIds:
                //         "79c03bd9576c4eb2933673abcc631a93,aec8f5af87b7407ab7430c3238f39f3f,15c8c6b12d02407fa8dc66dda3dede85,d34a459ca9f74b8e90e46bfaac80482d",
                //     deleteStatus: 0,
                //     createTime: "2025-01-03 17:25:34",
                //     createBy: "2164682",
                //     updateTime: null,
                //     updateBy: null,
                //     remark: null,
                //     pageNum: null,
                //     pageRow: null,
                //     parentOrStuName: null,
                //     approveIdList: null,
                // },
            ],
            // 分页数据
            pagingData: {
                total: 0,
                pageNum: 1,
                pageRow: 20,
            },
            tableListSel: [],
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    async created() {
        this.getList(true);
        this.getSchoolList();
    },
    methods: {
        handlerGlobalType() {
            this.$emit("changePage", "parentManage");
        },
        // 获取审批列表
        getList(pageInit) {
            
            if (this.form.phone !== "" && this.form.phone.length !== 11) {
                this.$message.warning("请输入 11 位手机号码，进行查询！");
                return;
            }
            this.loadingTable = true;
            if (typeof pageInit !== "undefined") {
                this.pagingData.pageNum = 1;
            }
            this._fet("/school/schoolStudentBindRecord/list", {
                ...this.form,
                schoolId: this.schoolId,
                pageNum: this.pagingData.pageNum,
                pageRow: this.pagingData.pageRow,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.pagingData.total = res.data.data.totalCount;
                    this.tableData = res.data.data.list;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                this.loadingTable = false;
            });
        },
        // 获取学校列表
        getSchoolList() {
            this._fet("/school/schoolOrgan/listAuthByCondition", {
                schoolId: this.$store.state.schoolId,
                jobScope: [3],
            }).then((res) => {
                if (res.data.code === "200") {
                    const index = this.formData.data.findIndex(
                        (i) => i.type === "cascader",
                    );
                    this.formData.data[index].list = formatTreeData(
                        res.data.data.list,
                        "id",
                        "parentOrg",
                    );
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 递归班级架构数据
        getTreeData(data, key) {
            for (let i = 0; i < data.length; i++) {
                if (!data[i][key] || data[i][key].length === 0) {
                    data[i][key] = undefined;
                } else {
                    this.getTreeData(data[i][key], key);
                }
            }
            return data;
        },
        // 操作栏按钮点击
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary":
                    console.log("查询条件", this.formData.data);
                    this.formData.data.forEach((e) => {
                        if (e.key === "classIdList") {
                            this.form[e.key] = e.value ? e.value : [];
                        } else {
                            this.form[e.key] = e.value;
                        }
                    });
                    this.getList(true);
                    break;
                case "reset":
                    this.formData.data.forEach((e) => {
                        if (e.key === "classIdList") {
                            e.value = [];
                            this.form[e.key] = [];
                        } else {
                            e.value = "";
                            this.form[e.key] = "";
                        }
                    });
                    this.getList(true);
                    break;
            }
        },
        // 表格勾选
        handleSelectionChange(data) {
            this.tableListSel = data
        },
        // 批量操作
        bulkOperation(typeMold) {
            if (this.tableListSel.length !== 0) {
                let idList = this.tableListSel.map((e) => e.id);
                if(typeMold == 3){
                    this.$confirm('确认要拒绝审批吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.approve(typeMold, idList);
                    }).catch((err) => {
                        console.log(err)
                    })
                } else {
                    this.approve(typeMold, idList);
                }
            } else {
                this.$message.warning("未选择内容");
            }
        },
        // 表格按钮操作
        async tableClick(typeMold, itemData) {
            if(typeMold == 3){
                this.$confirm('确认要拒绝审批吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.approve(typeMold, [itemData.id]);
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                this.approve(typeMold, [itemData.id]);
            } 
        },
        /**
         * @Description: 审批
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2025-01-01 12:11:46
         * @param {*} idList
         */
        approve(type, idList) {
            this._fet("/school/schoolStudentBindRecord/approve", {
                approveIdList: idList,
                approveStats: type,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.getList(true);
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                    this.getList(true);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
    margin: 10px 0;
}
</style>
