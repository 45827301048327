<template>
    <div class="login">
        <div
            class="login-title-left"
            v-if="signInLogo"
        >
            <img
                :src="signInLogo"
                alt="logo"
            />
            <span>{{ signInSchoolName }}</span>
        </div>
        <preview-view
            v-if="loginPageShow"
            style="width: 100%; height: 100%"
            mode="login"
            :viewComparedStandardZoomRatio="loginPageZoomRatio"
            :templateInfo="templateInfo"
            :loginPageInfo="loginPageInfo"
            :wheelZoomRatio="wheelZoomRatio || 100"
            :loginForm="loginForm"
            :loginRules="loginRules"
            :loginButtonLoading="loading"
            @smsVerificationCodeClick="smsCodeSend"
            @pictureVerificationCodeClick="refreshCode"
            @login="handleLogin"
            @otherLoginClick="handleOtherLoginClick"
        ></preview-view>

        <!-- 绑定账号弹框 -->
        <dialog-wrapper
            :dialogObj="bindDialogObj"
            @handleClose="handleBindDialogClose"
        >
            <!-- 第三方登录失败界面 -->
            <div
                v-if="!bindFormShow"
                class="login-failed"
            >
                <div class="tip">
                    <i class="el-icon-warning"></i>
                    <span>登录失败</span>
                </div>
                <div class="tip2">
                    该{{
                        bindForm.authType == "2" ? "钉钉" : "微信"
                    }}未绑定本系统账号，无法快速登录。
                </div>
                <div class="btn">
                    <el-button
                        type="primary"
                        @click="
                            bindFormShow = true;
                            bindDialogObj.title = '请绑定账号';
                        "
                        >已有系统账号，去绑定</el-button
                    >
                </div>
                <div class="tip3">未注册系统账号？</div>
                <div class="tip4">联系管理员，为您注册系统账号。</div>
            </div>
            <!-- 绑定账号界面 -->
            <el-form
                v-else
                ref="bindForm"
                :model="bindForm"
                :rules="bindRules"
                class="login__form"
            >
                <el-form-item prop="userName">
                    <div style="display: flex; align-items: center">
                        <img
                            style="margin-right: 5px"
                            :src="accountImg"
                            alt=""
                        />
                        <el-input
                            v-model="bindForm.userName"
                            type="text"
                            auto-complete="off"
                            placeholder="账户"
                            size="medium"
                        >
                            <template #prepend> </template>
                        </el-input>
                    </div>
                </el-form-item>

                <el-form-item prop="password">
                    <div style="display: flex; align-items: center">
                        <img
                            style="margin-right: 5px"
                            :src="passwordImg"
                            alt=""
                        />
                        <el-input
                            v-model="bindForm.password"
                            type="password"
                            auto-complete="off"
                            placeholder="密码"
                            size="medium"
                            show-password
                        >
                            <template #prepend> </template>
                        </el-input>
                    </div>
                </el-form-item>

                <div>
                    当前的{{
                        bindForm.authType == "2" ? "钉钉" : "微信"
                    }}用户名是：{{ bindForm.nickName }}
                </div>

                <div style="display: flex; margin: 10px auto">
                    <el-button
                        type="primary"
                        size="medium"
                        @click="handleBindAccount"
                        >绑定</el-button
                    >
                    <el-button
                        size="medium"
                        @click="handleBindDialogClose"
                        >取消</el-button
                    >
                </div>
            </el-form>
        </dialog-wrapper>
    </div>
</template>

<script>
import {DialogWrapper, debounce} from "common-local";
import PreviewView from "@/components/scrollWrapper/LoginPageTemplate/PreviewView.vue";
import {LoginModel} from "@/models/Login.js";
import {
    standardWidth,
    defaultTheme,
    themeList,
} from "@/assets/data/LoginPageTemplate/themeList.js";
import {title} from "@/libs/settings.js";
import {serviceDownload} from "../services/index.js";
import {setToken, removeToken, getToken} from "@/libs/auth";
import {
    set7DayToken,
    get7DayToken,
    remove7DayToken,
} from "@/libs/rememberPasswords";
import {Encrypt, Decrypt} from "@/libs/secret";
import {param2Obj, getBrowserWheelZoomRatio} from "@/libs/utils";
import {getCode} from "@/libs/dingtalk";
import {mapMutations} from "vuex";
import * as dd from "dingtalk-jsapi";
import wheelZoom from "@/mixins/wheelZoom.js";

export default {
    name: "Login",
    mixins: [wheelZoom],
    components: {
        PreviewView,
        DialogWrapper,
    },
    props: {},
    data() {
        const checkPhone = (rule, value, callback) => {
            console.log("校验手机号", value);
            const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (value == null || String(value).trim() === "") {
                callback(new Error("请输入手机号"));
            } else if (!reg.test(value)) {
                callback(new Error("手机号格式不正确"));
            } else {
                callback();
            }
        };
        return {
            inDingTalk: false,
            loginPageShow: false,
            standardWidth,
            defaultTheme,
            themeList,
            loginPageWidth: 0,
            loginPageZoomRatio: 1,
            templateInfo: {
                loginMethod: ["1"],
                defaultLoginMethod: "1",
            },
            loginPageInfo: {},
            browserWheelZoomRatioInit: 100,
            browserWheelZoomRatio: 100,
            title,
            schoolName: "",
            schoolId: "",
            loginForm: {
                curLoginMethod: "", // 当前登录方式
                userName: "", // 账号
                password: "", // 密码
                phoneNumber: "", // 手机号
                smsCode: "", // 短信验证码
                smsCodeCountdown: 0, // 短信验证码倒计时
                code: "", // 图片验证码
                codeUrl: "", // 验证码图片
                rememberPassword: false, // 记住密码
                plat: "school", // 登录平台
                terminalType: "5", // 登录终端类型 5.pc
                projectId: "", // 项目id
                projectMark: "", // 项目标识
                paramDate: +new Date(), // 时间戳
            },
            loginRules: {
                userName: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入账号",
                    },
                ],
                password: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入密码",
                    },
                ],
                phoneNumber: [
                    {
                        required: true,
                        validator: checkPhone,
                        trigger: ["change", "blur"],
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入短信验证码",
                    },
                ],
                code: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入验证码",
                    },
                ],
            },
            smsCodeCountdownTimer: null, // 短信验证码倒计时定时器
            loading: false,
            // 绑定账号弹窗
            bindDialogObj: {
                title: "登录失败",
                dialogVisible: false,
                width: "500px",
            },
            bindFormShow: false,
            bindForm: {
                userName: "",
                password: "",
                projectMark: "",
                authType: "", // 1:微信；2：钉钉
                thirdId: "",
                nickName: "",
            },
            bindRules: {
                userName: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入账户名",
                    },
                ],
                password: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入密码",
                    },
                ],
            },
            signInLogo: "",
            signInSchoolName: "",
            isReg: true,
        };
    },
    watch: {},
    provide() {
        return {
            schoolId: () => this.schoolId,
        };
    },
    created() {
        // console.log("process.env.NODE_ENV:", process.env.NODE_ENV, "process.env.VUE_APP_ENV:", process.env.VUE_APP_ENV)
        this.inDingTalk = dd.env.platform !== "notInDingTalk";
        this.init();
    },
    mounted() {
        this.$_addWheelZoomEventListener();
        window.addEventListener("resize", this.initResizeEvent);
    },
    beforeDestroy() {
        this.$_removeWheelZoomEventListener();
        window.removeEventListener("resize", this.initResizeEvent);
    },
    methods: {
        async init() {
            await this.handleLogout();
            if (!this.inDingTalk) {
                const param = param2Obj(location.search);
                if (!param.projectMark) {
                    // 登录页配置设置默认值
                    this.setDefaultLoginPageInfo(this.loginPageInfo);
                    this.loginPageShow = true;
                    return this.$message.error("缺少项目标识");
                }
                if (param.authCode) {
                    this.fullScreenHeightLoadingShow();
                    // 将authCode从#前移到#后
                    let search = "?";
                    for (let key in param) {
                        if (key != "authCode") {
                            search = search + key + "=" + param[key] + "&";
                        }
                    }
                    search = search.slice(0, search.length - 1);
                    // console.log(search);
                    let query = "authCode=" + param.authCode;
                    if (location.hash.indexOf("?") > -1) {
                        if (!location.hash.endsWith("&")) {
                            query = "&" + query;
                        }
                    } else {
                        query = "?" + query;
                    }
                    // console.log(query);
                    location.href =
                        location.origin +
                        location.pathname +
                        search +
                        location.hash +
                        query;
                    return;
                }

                this.browserWheelZoomRatioInit = getBrowserWheelZoomRatio();
                this.browserWheelZoomRatio = getBrowserWheelZoomRatio();
                this.$store.state.projectMark = param.projectMark || "";
                this.loginForm.projectMark = param.projectMark;
                this.loginForm.codeUrl = await serviceDownload(
                    this.loginForm.paramDate,
                );

                // console.log("location:", location, "code:", this.$route.query.code, "authCode", this.$route.query.authCode);
                if (this.$route.query.code) {
                    // 第三方登录-微信
                    this.handleOtherLogin(
                        this.$route.query.code,
                        "1",
                        param.projectMark,
                    );
                } else if (this.$route.query.authCode) {
                    // 第三方登录-钉钉
                    this.handleOtherLogin(
                        this.$route.query.authCode,
                        "2",
                        param.projectMark,
                    );
                } else {
                    console.log("获取登录数据==");
                    const projectInfo = await this.getProjectInfo();
                    await this.getTemplateInfo();
                    console.log(projectInfo, "projectInfo===");
                    const hasRemePassword = await this.automaticLogin(
                        projectInfo,
                    );
                    // 如果模板设置的是允许记住密码，看是否有记住的密码
                    if (
                        this.templateInfo.rememberme == "1" &&
                        !hasRemePassword
                    ) {
                        let passwordList = localStorage.getItem("passwordList");
                        if (passwordList) {
                            passwordList = JSON.parse(passwordList);
                            console.log("[init]passwordList:", passwordList);
                            let rememberedPassword = passwordList.find(
                                (e) =>
                                    e.projectMark ===
                                    this.loginForm.projectMark,
                            );
                            if (rememberedPassword) {
                                this.loginForm.userName =
                                    rememberedPassword.userName;
                                this.loginForm.password = Decrypt(
                                    rememberedPassword.password,
                                );
                                this.loginForm.rememberPassword = true;
                            }
                        }
                    }
                }
            } else {
                // 钉钉登录
                this.dingLogin();
            }
        },
        async automaticLogin(projectInfo) {
            const token = await get7DayToken(projectInfo);
            this.goNext(token);
            return token;
        },
        // 全屏loading展示
        fullScreenHeightLoadingShow(text, background) {
            const loading = this.$loading({
                lock: true,
                text: text ? text : "登录中...",
                spinner: "el-icon-loading",
                background: background ? background : "rgba(255, 255, 255, 1)",
            });
            return loading;
        },
        // 接口返回数据统一处理
        handleRes(res, fn) {
            if (res && res.data) {
                if (res.data.code == "200") {
                    fn();
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            }
        },
        // 获取项目信息
        async getProjectInfo() {
            let res = await this._fet("/auth/sc/getDetailByProjectMark", {
                productId: "cloud_campus",
                projectMark: this.loginForm.projectMark,
            });
            if (res.data.code === "200" && res.data.data) {
                this.schoolName =
                    res.data.data.systemName || res.data.data.schoolName;
                document.title = this.schoolName;
                if (this.schoolName) {
                    document.title = this.schoolName;
                }
                this.schoolId = res.data.data.id;
                this.loginForm.projectId = res.data.data.id;
                return res.data.data.projectMark;
            } else if (res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        // 登录页配置设置默认值
        setDefaultLoginPageInfo(loginPageInfo) {
            let theme = this.themeList[this.defaultTheme];
            // 主题
            this.$set(loginPageInfo, "theme", theme.id);
            // 背景
            let background = this._.cloneDeep(theme.background);
            this.$set(loginPageInfo, "background", background);
            // 登录框
            let loginBox = this._.cloneDeep(theme.loginBox);
            this.$set(loginPageInfo, "loginBox", loginBox);
            // 组件
            this.$set(loginPageInfo, "comps", []);
            // 处理数据
            this.handleLoginPageInfoData(loginPageInfo);
        },
        // 处理登录页配置数据
        handleLoginPageInfoData(loginPageInfo) {
            let loginPageWidth = window.innerWidth;
            console.log(
                "[handleLoginPageInfoData]loginPageWidth:",
                loginPageWidth,
            );
            // 计算中间预览区域视图宽度相较于标准宽度的缩放比率
            let zoomRatio = loginPageWidth / this.standardWidth;
            console.log("[handleLoginPageInfoData]zoomRatio:", zoomRatio);
            // 处理登录框数据
            if (loginPageInfo.loginBox) {
                Object.assign(loginPageInfo.loginBox, {
                    top: Math.round(loginPageInfo.loginBox.top * zoomRatio),
                    left: Math.round(loginPageInfo.loginBox.left * zoomRatio),
                    width: Math.round(loginPageInfo.loginBox.width * zoomRatio),
                    height: Math.round(
                        loginPageInfo.loginBox.height * zoomRatio,
                    ),
                    timestamp: +new Date(),
                });
            }
            // 处理组件数据
            if (loginPageInfo.comps) {
                for (let comp of loginPageInfo.comps) {
                    Object.assign(comp, {
                        top: Math.round(comp.top * zoomRatio),
                        left: Math.round(comp.left * zoomRatio),
                        width: Math.round(comp.width * zoomRatio),
                        height: Math.round(comp.height * zoomRatio),
                        timestamp: +new Date(),
                    });
                    if (comp.type === "Text") {
                        comp.fontSize = Math.round(comp.fontSize * zoomRatio);
                    }
                }
            }
            this.loginPageWidth = loginPageWidth;
            this.loginPageZoomRatio = zoomRatio;
            this.loginPageInfo = loginPageInfo;

            console.log("==", this.loginPageInfo, "三数据");
        },
        // 获取模板信息
        async getTemplateInfo() {
            let res = {data: {}};
            if (this.schoolId) {
                const loginModel = new LoginModel();
                try {
                    res = await loginModel.getLoginPageTemplateDetailBySchoolId(
                        {
                            schoolId: this.schoolId,
                        },
                    );
                } catch (error) {
                    console.error(
                        "[getTemplateInfo]getLoginPageTemplateDetail error:",
                        error,
                    );
                    this.setDefaultLoginPageInfo(this.loginPageInfo);
                    return;
                }
            } else {
                console.error("[getTemplateInfo]未获取到schoolId");
            }
            if (res.data && res.data.code == "200") {
                this.setDocumentTitle(res.data);
                this.setDocumentLogo(res.data);
                res.data.data.loginMethod =
                    res.data.data.loginMethod.split(",");
                // 开始处理模板数据
                let loginPageInfo = JSON.parse(res.data.data.loginPageInfo);
                if (loginPageInfo.theme) {
                    this.handleLoginPageInfoData(loginPageInfo);
                } else {
                    // 防止初始化数据不规范
                    this.setDefaultLoginPageInfo(this.loginPageInfo);
                }
                this.templateInfo = res.data.data;
                console.log(this.templateInfo, "-this.templateInfo-");
            } else {
                if (res.error && res.error.message) {
                    this.$message.error(res.error.message);
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                this.setDefaultLoginPageInfo(this.loginPageInfo);
            }
            this.loginPageShow = true;
        },
        // 写入系统名称
        setDocumentTitle(data) {
            const templateInfo = data.data;
            if (
                Object.hasOwnProperty.call(templateInfo, "portalName") &&
                templateInfo.portalName
            ) {
                document.title = templateInfo.portalName;
                this.signInSchoolName = templateInfo.portalName;
            }
        },
        // 写入系统logo
        setDocumentLogo(data) {
            const templateInfo = data.data;
            if (
                Object.hasOwnProperty.call(templateInfo, "logo") &&
                templateInfo.logo
            ) {
                this.signInLogo = templateInfo.logo;
            }
        },
        // 发送短信验证码
        smsCodeSend() {
            const _this = this;
            let loginForm = this._.cloneDeep(this.loginForm);
            console.log(loginForm, "loginForm=loginForm");
            if (!this.loginForm.projectMark) {
                this.$message.error("缺少项目标识");
                return;
            }
            const loginModel = new LoginModel();
            loginModel
                .sendSms({
                    mobile: this.loginForm.phoneNumber,
                    plat: loginForm.plat,
                    terminalType: loginForm.terminalType,
                    projectId: loginForm.projectId,
                    loginType:"2",
                    smsType: 3,
                })
                .then((res) => {
                    console.log("[sendSmsCode]res:", res);
                    this.handleRes(res, () => {
                        if (res.data.msg) {
                            res.data.data.code == "200"
                                ? this.$message.success(res.data.msg)
                                : this.$message.error(res.data.msg);
                        }
                        // 短信验证码倒计时
                        if (res.data.data.time && res.data.data.time > 0) {
                            this.loginForm.smsCodeCountdown =
                                res.data.data.time;
                            this.smsCodeCountdown();
                        }
                    });
                });
        },
        // 短信验证码倒计时
        smsCodeCountdown() {
            let _this = this;
            if (_this.smsCodeCountdownTimer) {
                clearInterval(_this.smsCodeCountdownTimer); //清除定时器
                _this.smsCodeCountdownTimer = null;
            }
            _this.smsCodeCountdownTimer = setInterval(function () {
                if (_this.loginForm.smsCodeCountdown == 0) {
                    clearInterval(_this.smsCodeCountdownTimer); //清除定时器
                    _this.smsCodeCountdownTimer = null;
                } else {
                    _this.loginForm.smsCodeCountdown--;
                }
                // console.log("[smsCodeCountdown]this.loginForm.smsCodeCountdown:", _this.loginForm.smsCodeCountdown)
            }, 1000);
        },
        // 刷新图片验证码
        async refreshCode() {
            this.loginForm.code = "";
            this.loginForm.paramDate = +new Date();
            this.loginForm.codeUrl = await serviceDownload(
                this.loginForm.paramDate,
            );
        },
        // 登录
        async handleLogin() {
            await this.moibleIsReg();
            if (!this.loginForm.projectMark) {
                this.$message.error("缺少项目标识");
                return;
            }
            this.loading = true;
            const _this = this;
            const loginModel = new LoginModel();
            let loginForm = this._.cloneDeep(this.loginForm);
            console.log(loginForm, "loginForm=loginForm");
            let loginParams = {
                plat: loginForm.plat,
                terminalType: loginForm.terminalType,
                projectId: loginForm.projectId,
                projectMark: loginForm.projectMark,
                paramDate: loginForm.paramDate,
                rememberPassword: loginForm.rememberPassword ? "1" : "2",
            };
            if (loginForm.curLoginMethod === "1") {
                // 账号密码登录
                loginParams.loginType = "1"; // 登录方式 1.密码
                loginParams.userName = loginForm.userName; // 账号
                loginParams.password = Encrypt(loginForm.password); // 密码
                loginParams.code = loginForm.code; // 图片验证码
            } else if (loginForm.curLoginMethod === "4") {
                // 短信验证码登录
                loginParams.loginType = "2"; // 登录方式 2.验证码
                loginParams.phoneNumber = loginForm.phoneNumber; // 手机号
                loginParams.code = loginForm.smsCode; // 短信验证码
                loginParams.pictureCode = loginForm.code; // 图片验证码
            }

            let res = null;
            try {
                res = await loginModel.loginEntrance(loginParams);
            } catch (error) {
                this.loading = false;
                console.error("login error:", error);
                return;
            }
            if (res.data && res.data.code === "200") {
                // 密码登录处理是否记住密码
                if (loginForm.curLoginMethod === "1") {
                    let passwordList = localStorage.getItem("passwordList");
                    passwordList = passwordList ? JSON.parse(passwordList) : [];
                    console.log("[handleLogin]passwordList:", passwordList);
                    if (loginForm.rememberPassword) {
                        // 记住密码
                        set7DayToken(loginParams.projectMark, res.data.data);
                        let rememberedPassword = passwordList.find(
                            (e) => e.projectMark === loginParams.projectMark,
                        );
                        if (rememberedPassword) {
                            rememberedPassword.userName = loginParams.userName;
                            rememberedPassword.password = loginParams.password;
                        } else {
                            passwordList.push({
                                projectMark: loginParams.projectMark,
                                userName: loginParams.userName,
                                password: loginParams.password,
                            });
                        }
                        console.log(
                            "[handleLogin][记住密码]passwordList:",
                            passwordList,
                        );
                    } else {
                        // 不记住密码
                        remove7DayToken(loginParams.projectMark);
                        passwordList = passwordList.filter(
                            (e) => e.projectMark != loginParams.projectMark,
                        );
                        console.log(
                            "[handleLogin][不记住密码]passwordList:",
                            passwordList,
                        );
                    }
                    localStorage.setItem(
                        "passwordList",
                        JSON.stringify(passwordList),
                    );
                }

                let token = res.data.data.token.token;
                _this.goNext(token);
                this.loading = false;
            } else {
                console.log(res, "res----res");
                this.refreshCode();

                if (res.error && res.error.message) {
                    this.$message.error(res.error.message);
                } else if (res.data && res.data.msg) {
                    console.log(this.isReg, "this.isReg");
                    if (this.isReg) {
                        if (
                            res.data.code === "512" ||
                            res.data.code === "510" ||
                            res.data.code === "20012" ||
                            res.data.code === "503" ||
                            res.data.code === "513" ||
                            res.data.code === "60002"
                        ) {
                            this.$message.error(res.data.msg);
                        } else {
                            this.$message.error(
                                "您输入的密码错误，请重新输入！",
                            );
                        }
                    } else {
                        this.$message.error("该账号未注册，请联系管理员！");
                    }
                }
                this.loading = false;
            }
        },
        async moibleIsReg() {
            const loginModel = new LoginModel();
            await loginModel
                .isReg({
                    phoneNumber: this.loginForm.userName,
                })
                .then(async (res) => {
                    this.isReg = res.data.data;
                })
                .catch(async () => {});
        },
        goNext(token) {
            if (!token) return;
            const _this = this;
            _this.loading = true;
            setToken(token);
            if (_this.$route.query.name) {
                let url = _this.$route.query.url;
                let startDate = _this.$route.query.startDate;
                let endDate = _this.$route.query.endDate;
                location.replace(
                    url +
                        "?startDate=" +
                        startDate +
                        "&endDate=" +
                        endDate +
                        "&token=" +
                        token,
                );
            } else {
                _this.$router.push({name: "FirstPage"});
            }
            _this.loading = false;
        },
        // 钉钉登录
        dingLogin() {
            const projectMark = param2Obj(location.search).projectMark || "";
            getCode((code) => {
                this._fet("/auth/sc/dingLoginHszx", {
                    code: code,
                    projectMark: projectMark,
                    mode: "pc",
                }).then((res) => {
                    if (res.data.code === "200") {
                        setToken(res.data.data.token);
                        if (this.$route.query.name) {
                            // _this.$router.push({
                            //     // name: _this.$route.query.redirect,
                            //     path:'/sManager/oa/meeting/Attendance',
                            //     query: {
                            //         name:_this.$route.query.redirect,
                            //         startDate: _this.$route.query.startDate,
                            //         endDate: _this.$route.query.endDate,
                            //     }
                            // });
                            let url = this.$route.query.url;
                            let startDate = this.$route.query.startDate;
                            let endDate = this.$route.query.endDate;
                            location.replace(
                                url +
                                    "?startDate=" +
                                    startDate +
                                    "&endDate=" +
                                    endDate +
                                    "&token=" +
                                    res.data.data.token,
                            );
                            // window.open(url+"?startDate=" + startDate + "&endDate=" + endDate+ "&token=" +res.data.data.token, "myWindow")
                            // this.$router.push({
                            //     // name: this.$route.query.redirect,
                            //     path:'/sManager/oa/meeting/Attendance',
                            // });
                            // this.$router.push({ query: {} });
                        } else {
                            this.$router.push({
                                path: "/",
                                query: {
                                    source: "login",
                                },
                            });
                        }

                        this.loading = false;
                    } else {
                        this.referhCode();
                        this.$message.error(res.data.msg);
                        this.loading = false;
                    }
                });
            }, projectMark);
        },
        // 退出登录
        async handleLogout() {
            const token = getToken();
            if (token === undefined) {
            } else {
                removeToken();
                // const loginModel = new LoginModel();
                // await loginModel.logout({}).then((res) => {
                //     if (res.data.code === "200") {
                //         removeToken();
                //     } else {
                //         this.$message.error(res.data.msg);
                //     }
                // });
            }
        },

        // 第三方登录按钮点击事件
        handleOtherLoginClick(type) {
            if (!this.loginForm.projectMark) {
                this.$message.error("缺少项目标识");
                return;
            }
            // let url = 'https://cloudcampus.xinkaoyun.com:40003/?projectMark=SJZSSSZYSSY'
            // console.log(location)
            // let url = location.origin + '/' + location.search
            let url =
                location.origin +
                location.pathname +
                location.search +
                "#/login?";
            switch (type) {
                case "DingTalk": // 钉钉
                    let clientId = process.env.VUE_APP_DINGTALK_THIRD_ID;
                    this.loginPageShow = false;
                    location.href = `https://login.dingtalk.com/oauth2/auth?redirect_uri=${encodeURIComponent(
                        url,
                    )}&response_type=code&client_id=${clientId}&scope=openid&prompt=consent`;
                    break;
                case "WeChat": // 微信
                    let appId = process.env.VUE_APP_WECHAT_THIRD_ID;
                    this.loginPageShow = false;
                    location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${encodeURIComponent(
                        url,
                    )}&response_type=code&scope=snsapi_login#wechat_redirect`;
                    break;
            }
        },
        // 第三方登录
        handleOtherLogin(authCode, authType, projectMark) {
            this.loading = true;
            const loading = this.fullScreenHeightLoadingShow();
            const loginModel = new LoginModel();
            loginModel
                .loginEntrance({
                    authCode,
                    authType,
                    projectMark,
                    loginType: authType === "2" ? "4" : "3",
                    plat: this.loginForm.plat,
                    terminalType: this.loginForm.terminalType,
                })
                .then(async (res) => {
                    console.log("第三方登录接口返回：", res);
                    if (res.data.code == "200") {
                        this.$message.success("登录成功");
                        setToken(res.data.data.token);
                        this.$router.push({
                            path: "/",
                            query: {
                                source: "login",
                            },
                        });
                        this.loading = false;
                        loading.close();
                    } else {
                        await this.getProjectInfo();
                        this.getTemplateInfo();
                        this.loading = false;
                        loading.close();
                        if (res.data.code == "100605") {
                            this.bindForm.projectMark =
                                this.loginForm.projectMark;
                            this.bindForm.authType = res.data.data.authType;
                            this.bindForm.thirdId = res.data.data.thirdId;
                            this.bindForm.nickName = res.data.data.nickName;
                            this.bindDialogObj.dialogVisible = true;
                            // setTimeout(() => {
                            //   this.$message.warning(res.data.msg)
                            // }, 0);
                        } else {
                            this.$message.error(res.data.msg);
                            this.delRouteQueryCode();
                        }
                    }
                })
                .catch(async () => {
                    await this.getProjectInfo();
                    this.getTemplateInfo();
                    this.loading = false;
                    loading.close();
                });
        },
        delRouteQueryCode() {
            // console.log("[delRouteQueryCode]")
            let query = JSON.parse(JSON.stringify(this.$route.query));
            if (query.code) {
                delete query.code;
            }
            if (query.authCode) {
                delete query.authCode;
            }
            this.$router.replace({
                path: "/login",
                query: query,
            });
        },
        // 绑定账号弹窗关闭
        handleBindDialogClose() {
            this.bindDialogObj.dialogVisible = false;
        },
        // 绑定账号
        handleBindAccount() {
            this.$refs.bindForm.validate((valid) => {
                if (!valid) return false;
                this.loading = true;
                console.log("bindForm:", this.bindForm);
                let obj = JSON.parse(JSON.stringify(this.bindForm));
                obj.password = Encrypt(obj.password);
                this._fet("/auth/sc/bindUnionIdAndLogin", obj)
                    .then((res) => {
                        if (res.data.code == "200") {
                            this.$message.success("绑定成功");
                            setToken(res.data.data.token);
                            this.$router.push({
                                path: "/",
                                query: {
                                    source: "login",
                                },
                            });
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        /**
         * 窗口大小改变事件
         * initResizeEvent 窗口大小改变事件入口
         * handleLoginPageBackgroundResize 窗口大小改变背景大小改变
         * handleLoginPageResize 窗口大小改变重新计算布局
         */
        // 窗口大小改变事件入口
        initResizeEvent(e) {
            let browserWheelZoomRatio = getBrowserWheelZoomRatio();
            // 方案A
            if (browserWheelZoomRatio === this.browserWheelZoomRatio) {
                this.handleLoginPageResize();
            } else {
                // 浏览器工具栏缩放无效
                this.browserWheelZoomRatio = browserWheelZoomRatio;
                let dom = document.getElementById("preview_view_login");
                dom.style.zoom =
                    this.browserWheelZoomRatioInit / browserWheelZoomRatio;
                /**
                 * 方案2：
                 * 浏览器工具栏缩放与自定义缩放同步，但自定义缩放无法与浏览器工具栏缩放同步
                 * 窗口拖动后再两种缩放都使用计算会错误
                 * 如要使用需要打开mixins wheelZoom.js created中的自定义缩放同步浏览器缩放
                 */
                this.wheelZoomRatio = browserWheelZoomRatio;
            }
            // 方案B
            // this.handleLoginPageBackgroundResize()
            // this.handleLoginPageResize()
            // if (browserWheelZoomRatio != this.browserWheelZoomRatio) {
            //     this.browserWheelZoomRatio = browserWheelZoomRatio
            //     this.$message.warning("请使用Ctrl+鼠标滚轮/+/-缩放")
            // }
        },
        getDomStyle(dom, attr) {
            if (dom.currentStyle) {
                // 兼容IE
                return dom.currentStyle[attr];
            } else {
                return getComputedStyle(dom, false)[attr];
            }
        },
        // 窗口大小改变重新计算布局
        handleLoginPageResize: debounce(function () {
            // 重新计算重置zoom
            let loginPageDom = document.getElementById("preview_view_login");
            if (loginPageDom && loginPageDom.style) {
                loginPageDom.style.zoom = 1;
            }

            this.browserWheelZoomRatioInit = getBrowserWheelZoomRatio();

            let fullScreenWidth = window.innerWidth;
            console.log(
                "[handleLoginPageResize]fullScreenWidth:",
                fullScreenWidth,
            );
            // 计算整体视图宽度相较于标准宽度的缩放比率
            this.loginPageZoomRatio = fullScreenWidth / this.standardWidth;
            // console.log("[handleLoginPageResize]this.loginPageZoomRatio:", this.loginPageZoomRatio)
            // 开始处理模板数据
            let loginPageInfo = this._.cloneDeep(this.loginPageInfo);
            // 计算拽后宽度相较于拖拽前宽度的缩放比例
            let zoomRatio = fullScreenWidth / this.loginPageWidth;
            // 处理登录框数据
            Object.assign(loginPageInfo.loginBox, {
                top: Math.round(loginPageInfo.loginBox.top * zoomRatio),
                left: Math.round(loginPageInfo.loginBox.left * zoomRatio),
                width: Math.round(loginPageInfo.loginBox.width * zoomRatio),
                height: Math.round(loginPageInfo.loginBox.height * zoomRatio),
                timestamp: +new Date(),
            });
            // 处理组件数据
            for (let comp of loginPageInfo.comps) {
                Object.assign(comp, {
                    top: Math.round(comp.top * zoomRatio),
                    left: Math.round(comp.left * zoomRatio),
                    width: Math.round(comp.width * zoomRatio),
                    height: Math.round(comp.height * zoomRatio),
                    timestamp: +new Date(),
                });
                if (comp.type === "Text") {
                    comp.fontSize = Math.round(comp.fontSize * zoomRatio);
                }
            }
            this.loginPageWidth = fullScreenWidth;
            this.loginPageInfo = loginPageInfo;
        }, 250),
    },
};
</script>

<style lang="scss" scoped>
.login {
    width: 100vw;
    height: 100vh;
    .login-title-left {
        position: absolute;
        top: 23px;
        left: 20px;
        z-index: 1;
        display: flex;
        align-items: center;
        img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
            border-radius: 50%;
        }

        span {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 700;
            color: #fff;
        }
    }
}

// 第三方登录失败界面
.login-failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;

    .tip {
        display: flex;
        align-items: center;
        margin-left: -16px;

        i {
            color: #e99d42;
            font-size: 26px;
        }

        span {
            margin-left: 8px;
            font-size: 18px;
        }
    }

    .tip2 {
        font-size: 16px;
        margin-top: 16px;
    }

    .btn {
        margin-top: 16px;
    }

    .tip3 {
        font-size: 15px;
        margin-top: 30px;
    }

    .tip4 {
        font-size: 15px;
        margin-top: 6px;
    }
}
</style>
