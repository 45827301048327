<template>
    <div
        class="expand-filter"
        :style="{
            'margin-top': marginTop,
            'margin-bottom': marginBottom,
            'border-radius': borderRadius,
            'padding-top': paddingTop
        }"
    >
        <el-form
            :inline="true"
            :model="form_data.formInline"
            :rules="form_data.rules"
            :class="{'expanded': !expanded}"
            @submit.native.prevent
            class="search-term"
        >
            <div>
                <div
                    ref="J_filterLeft"
                    style="max-width: 850px;"
                >
                    <el-form-item
                        v-for="(item, index) in form_data.data"
                        :label="`${item.label || ''}`"
                        :prop="item.prop"
                        :key="index"
                        @keyup.native.enter="expandFilter($event)"
                    >
                        <InputItem
                            v-if="item.type === 'input'"
                            :data="item"
                            :style="{width: item.width || '160px'}"
                            @inputInput="inputInput"
                        />
                        <Organization
                            v-if="item.type === 'organization'"
                            :data="item"
                            :style="{width: item.width || '160px'}"
                        />
                        <SelectItem
                            v-else-if="item.type === 'select'"
                            :style="{width: item.width || '160px'}"
                            :data="item"
                            @changeSel="changeSel"
                        />
                        <SelectXItem
                            v-else-if="item.type === 'selectX'"
                            :style="{width: item.width || '160px'}"
                            :data="item"
                            @changeSelX="changeSelX"
                        />
                        <NoRDateItem
                            v-else-if="item.type === 'noRestrictionsDate'"
                            :style="{width: '160px'}"
                            :data="item"
                        />
                        <DatePicker
                            v-else-if="item.type === 'datePick'"
                            :clearable="item.clearable == undefined ?  true : item.clearable"
                            :style="{width: '330px'}"
                            :data="item"
                            @datePickerChange="datePickerChange"
                        />
                        <DateItem
                            v-else-if="item.type === 'date'"
                            class="expand-filter-date"
                            :clearable="item.clearable == undefined ?  true : item.clearable"
                            :style="{width: '160px'}"
                            :data="item"
                        />
                        <tree-select-item
                            v-else-if="item.type === 'treeSelect'"
                            :style="{width: '160px'}"
                            :data="item"
                        />
                        <cascader-item
                            v-else-if="item.type === 'cascader'"
                            class="expand-filter-cascader"
                            :style="{width: item.width || '160px'}"
                            :data="item"
                            @changeCascader="changeCascader"
                        />
                        <input-autocomplete
                            v-else-if="item.type === 'autocomplete'"
                            :style="{width: item.width || '160px'}"
                            :data="item"
                            @autocompleteInput="autocompleteInput"
                        />
                        <div v-else-if="item.type === 'slot'">
                            <slot
                                :name="item.slotName"
                                :slotData="item"
                            ></slot>
                        </div>
                    </el-form-item>
                    <slot name="slotCom"></slot>
                </div>
            </div>
            <div class="filter-right">
                <div
                    @click="changeBtnFormType"
                    class="btn-form-type"
                    v-show="showExpandButton"
                >
                    <a
                        href="javascript:;"
                        class="expand-btn"
                        >{{ expanded ? "收起" : "展开" }}</a
                    >
                    <i
                        class="el-icon-caret-bottom"
                        :class="{arrowTransform: expanded}"
                    ></i>
                </div>
                <slot name="screening"></slot>
                <el-button
                    :type="item.type"
                    @click="submit(item, formData.data)"
                    v-for="(item, index) in form_data.btnList"
                    :key="index"
                    :style="{
                        backgroundColor: item['bgColor'] ? item['bgColor'] : '',
                        borderColor: item['bgColor'] ? item['bgColor'] : '',
                        color: item['color'] ? item['color'] : '',
                    }"
                    v-hasPermi="item.auth"
                    :disabled="item.disabled"
                    :loading="item.loading"
                >
                    {{ item.text }}
                </el-button>
                <slot name="other-btn"></slot>
                <slot name="dropdown"></slot>
            </div>
        </el-form>
        <slot name="pageOperation"></slot>
    </div>
</template>

<script>
import {
    // 工具函数
    debounce,
} from "common-local";
import NoRDateItem from "./FormData/NoRDate";
import InputItem from "./FormData/Input.vue";
import SelectItem from "./FormData/Select.vue";
import SelectXItem from "./FormData/SelectX.vue";
import DateItem from "./FormData/NoRDate.vue";
import DatePicker from "./FormData/DatePicker.vue";
import CascaderItem from "./FormData/Cascader.vue";
import Organization from "./FormData/Organization.vue";
import TreeSelectItem from "./FormData/TreeSelect.vue";
import ElementResizeDetectorMaker from "element-resize-detector";
import InputAutocomplete from "./FormData/InputAutocomplete.vue"

export default {
    name: "ExpandFilter",
    components: {
        NoRDateItem,
        InputItem,
        SelectItem,
        SelectXItem,
        DatePicker,
        DateItem,
        TreeSelectItem,
        CascaderItem,
        Organization,
        InputAutocomplete
    },
    props: {
        formData: Object,
        marginTop: {
            type: String,
            default: "0px",
        },
        marginBottom: {
            type: String,
            default: "10px",
        },
        borderRadius: {
            type: String,
            default: "4px",
        },
        paddingTop: {
            type: String,
        },
        hasQueryFiltering: {
            type: String,
            default: () => {
                return ''
            }
        }
    },
    data() {
        return {
            expanded: false,
            showExpandButton: false,
            form_data: {
                formInline: {},
                data: [],
                btnList: [],
                btnFormType: false,
            },
        };
    },
    computed: {
        processSelImg() {
            return require("../../../assets/images/stuInfoTemplate/triangle-down.png");
        },
    },
    mounted() {
        this.expanded = !this.form_data.btnFormType
        this.$nextTick(() => {
            this.bindEvent();
        });
    },
    methods: {
        init() {
            for (let key in this.formData) {
                if (Object.keys(this.form_data).includes(key)) {
                    this.form_data[key] = this.formData[key];
                }
            }
        },
        inputInput(data) {
            this.$emit('inputInput', data)
            this.dataChange();
        },
        autocompleteInput(data){
            this.$emit('autocompleteInput', data)
            this.dataChange();
        },
        changeSel(data) {
            this.$emit("changeSel", data);
            this.dataChange();
        },
        changeSelX(data) {
            this.$emit("changeSelX", data);
            this.dataChange();
        },
        datePickerChange() {
            this.dataChange();
        },
        changeCascader(data) {
            this.$emit("changeCascader", data);
            this.dataChange();
        },
        bindEvent() {
             let erd = ElementResizeDetectorMaker(),
                filterLeft = this.$refs.J_filterLeft
            if(filterLeft){
                erd.listenTo(filterLeft, (element) => {
                    this.showExpandButton = element.offsetHeight > 50
                });
            }
        },
        changeBtnFormType() {
            this.expanded = !this.expanded
            this.$emit("changeBtnFormType");
        },
        dataChange: debounce(function () {
            this.$emit("dataChange", this.formData.data);
        }, 200),
        submit(item, data) {
            console.log(item, data, "item, data");
            this.$emit("clickBtn", {item: item, data, data});
        },
        /**
         * @Description: 回车事件
         * @DoWhat: 方便用户操作,input回车可进行搜索，select回车默认选中第一项
         * @UseScenarios:
         * @Attention: 只支持input及select
         * @Author: 武东超
         * @Date: 2023-02-12 19:51:25
         */
        expandFilter(e) {
            const tar = e.target,
                type = "INPUT";
            if (type == tar.nodeName) {
                this.$emit("clickBtn", {
                    item: {
                        fn: "primary",
                    },
                    data: this.formData.data,
                });
            }
        },
    },
    watch: {
        formData: {
            handler() {
                this.init();
            },
            deep: true,
            immediate: true,
        }
    },
};
</script>

<style scoped lang="scss">
.expand-filter {
    background-color: #fff;
    padding-left: 10px;
    padding-top: 10px;
    .search-term {
        display: flex;
    }
    .filter-right {
        padding-right: 20px;
        flex-shrink: 0;
        display: flex;
        align-self: flex-start;
        flex: 1;
        .btn-form-type {
            height: 32px;
            margin: 0 10px 0 0;
            float: left;
            line-height: 32px;
            font-size: 14px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .expand-btn {
                display: block;
                text-decoration: none;
                color: #3c7fff;
                margin-right: 8px;
                white-space: nowrap;
                &:hover {
                    color: #3c7fff;
                }
            }

            .process-sel-img {
                width: 7px;
                height: 5px;
                transition: 0.2s;
                line-height: 32px;
                color: #3c7fff;
            }

            .arrowTransform {
                transform: rotateZ(180deg);
            }
            .el-icon-caret-bottom {
                color: #3c7fff;
            }
        }
    }
}

.expanded {
    height: 42px;
    overflow: hidden;
}

.expand-filter-date,
.expand-filter-cascader {
    ::v-deep .el-col-20 {
        width: 100%;
    }
}

::v-deep {
    .el-form-item--small.el-form-item {
        margin-bottom: 10px;
    }
    .el-input__inner {
        background: #f5f5f5;
        border: transparent 1px solid;
    }
    .el-input-group__append {
        padding: 0 10px;
    }
    .el-range-editor .el-range-input {
        background: #f5f5f5;
    }
    .el-cascader__search-input {
        background: #f5f5f5;
    }
    .el-select .el-input .el-select__caret {
        color: #999999;
    }
    .el-input-group__append {
        color: #999999;
        background: #f5f5f5;
        border: none;
    }
    .el-select-dropdown__list {
        border: 1px solid #d4d6d9;
    }
    .el-input__inner:hover {
        border: #d4d6d9 1px solid;
    }
    .el-input__inner:focus {
        border: #3c7fff 1px solid;
        background-color: #fff;
    }
    .el-input--suffix .el-input__inner {
        padding-right: 26px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
