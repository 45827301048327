<template>
    <div class="filter-wrapper">
        <div style="display: flex;align-items: center;height: 36px;">
            <div>
            <el-dropdown @command="handleChangeCourseType">
                <span class="el-dropdown-link">
                    {{ queryList.courseType | courseType}}<i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">班级课表</el-dropdown-item>
                    <el-dropdown-item command="2">教师课表</el-dropdown-item>
                    <el-dropdown-item command="3">年级课表</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="line"></div>
        <div  v-show="queryList.courseType == '1'">
            <span class="label"></span>
                <cascader :key="formData.defaultclassid" style="width: 200px;" :defaultclassid=formData.defaultclassid :data="formData.data[0]"  @changeCascader="changeCascader">
                </cascader>
        </div>
        <div class="select-class" v-show="queryList.courseType == '2'">
            <span class="label"></span>
                <select-tree
                    placeholder="选择教师"
                    v-if="queryList.courseType == '2'"
                    v-model="queryList.classTeacherId"
                    style="width: 200px;"
                    :data="teacherList"
                    :props="{
                        value: 'id',
                        label: (data) => data.name,
                        children: 'children'
                    }"
                    @handlerSupervisingTeacherChange="handlerTeacherChange"
                >
                </select-tree>
        </div>
        <div class="select-class" v-show="queryList.courseType == '3'">
            <span class="label"></span>
                <ElSelectTree
                    placeholder="选择年级"
                    v-model="queryList.scheduleGradeId"
                    :data="gradeList"
                    :props="{
                        value: 'id',
                        label: (data) => data.name,
                        children: 'child'
                    }"
                    filterable
                    @change="handlerGradeChange"
            />
        </div>
        <div :class="['select-week', { current: queryList.courseType == '3'}]">
            <span class="label"></span>
            <el-date-picker style="width: 200px;"
                    v-model="queryList.selectDay"
                    type="date"
                    :clearable="false"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
        </div>
        <div class="select-status" v-show="queryList.courseType !== '3'">
            <div
                v-for="(item) of statusList"
                :key="item.id"
                :class="['item',{ current: item.id ==  queryList.dayWeek}]"
                @click="handlerChangeStatus(item)"
            >
                {{ item.label }}
            </div>
        </div>
        </div>
        <div style="margin-left: 20px; display: flex; justify-content: nowrap;">
                <!-- v-hasPermi="['teachingResearchCourseScheduleTemp:save']" -->
            <div
                v-hasPermi="['teachingResearchCourseScheduleTemp:checkRepeat']"
                style="width: 100px; line-height: 32px; margin-right: 20px"
            >
                <el-checkbox v-model="isRepeat" @change="setIsRepeat" style="margin-left: -5px; margin-right: 5px">课表验重</el-checkbox>
                <el-tooltip class="item" effect="dark" content="勾选状态则默认一个教师在一个时间段不可重复排课" placement="top">
                    <i class="el-icon-question isRepeat"></i>
                </el-tooltip>
            </div>
            <el-button  style="background-color:#F5B942;border-color:#F5B942 ;" type="warning" @click="batchDeleteClick" v-hasPermi="['teachingResearchCourseScheduleTemp:deleteBatch']">批量删除</el-button>
            <el-button type="warning"  @click="emptyTableClick" v-hasPermi="['teachingResearchCourseScheduleTemp:clearBatch']">清空课表</el-button>
            <el-button plain @click="handlerImportClick" v-hasPermi="['teachingResearchCourseScheduleTemp:importCourse']">导入</el-button>
            <el-button plain @click="handlerExportClick" >导出</el-button>
            <el-button type="primary" @click="handlerAddClick" v-hasPermi="['teachingResearchCourseScheduleTemp:save']">添加</el-button>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import ElSelectTree from 'el-select-tree';
    Vue.use(ElSelectTree);

    import virtualList from 'vue-virtual-scroll-list'
    import ElOptionNode from './CourseScheduleView/VirtulaList/ElOptionNode.vue';
    import SelectTree from './Sub/SelectTree'
    import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";
    import Cascader from './Cascaderview/Cascader'

    import CourseSchedule from '@/filters/CourseSchedule.js'
    import {hasPermission} from "@/libs/utils.js";
    export default {
        name: "TeachingResearchCourseScheduleTempFilterWrapper",
        filters: CourseSchedule,
        components: {
            ElSelectTree,
            'virtual-list': virtualList,
            SelectTree,
            ExpandFilter,
            Cascader

        },
        props: {
            queryList: Object,
            formData:Object,
            statusList: Array,
            schedulingClassesList: Array,
            teacherList: Array,
            gradeList: Array
        },
        data () {
            return {
                itemComponent: ElOptionNode,
                isRepeat: true
            }
        },
        computed: {
            startIsRepeat(){
                return hasPermission(["teachingResearchCourseScheduleTemp:checkRepeat"])
            },
        },
        mounted(){
            console.log("1642-----周二",sessionStorage.getItem('isRepeat'))
            if (sessionStorage.getItem('isRepeat') == null || sessionStorage.getItem('isRepeat') == 'true') {
                this.isRepeat = true
            } else {
                this.isRepeat = false
            }
            if (!this.startIsRepeat) {
                this.isRepeat = true
                sessionStorage.setItem('isRepeat', this.isRepeat)
            }
        },
        methods: {
            /**
             * @Description: 传递课表验重设置给父级
             * @Author: pyl
             * @Date: 2024-11-26 14:11:58
             */            
            setIsRepeat() {
                sessionStorage.setItem('isRepeat', this.isRepeat)
                this.$emit('getIsRepeat', this.isRepeat)
            },
            handleChangeCourseType (e) {
                // console.log("周二下拉菜单",e)
                this.$emit('handleChangeCourseType', e)
            },
            handlerChangeStatus (data) {
                this.$emit('handlerChangeStatus', data)
            },
            handlerAddClick () {
                this.$emit('handlerAddClick', this.isRepeat)
            },
            /**
           * @Description: 批量删除、清空课表、课程导入
            * @DoWhat:
            * @UseScenarios:
            * @Attention:
            * @Author: 王园园
            * @Date: 2023/7/20 9:00
            */
            batchDeleteClick(){
                this.$emit('batchDeleteClick')
            },
            emptyTableClick(){
                this.$emit('emptyTableClick')
            },
            handlerImportClick () {
                this.$emit('handlerImportClick', this.isRepeat)
            },
            handlerExportClick () {
                this.$emit('handlerExportClick')
            },
            handlerScheduleClassIdChange () {
                this.$emit('handlerScheduleClassIdChange')
            },
            handlerClassTeacherChange () {
                this.$emit('handlerClassTeacherChange')
            },
            handlerTeacherChange () {
                this.$emit('handlerTeacherChange')
            },
            handlerGradeChange () {
                this.$emit('handlerGradeChange')
            },
                // 选中年级班级
        changeCascader(data) {
            console.log("选择的年纪班级",data)
            this.$emit('changeCascader',data)
            data.ref.dropDownVisible = false;
        },
        // 筛选条件-选择时间
        datePickerBlur(value) {
            this.$emit('datePickerBlur', value)
            // let [startTime, endTime] = value;
            // let dateData=[];
            // let status = this.checkTime(startTime, endTime);
            // console.log("状态",status)
            // if (status) {
            //     console.log("状态02",status)
            //     dateData = [startTime,endTime]
            //     this.$emit('datePickerBlur', dateData)
            // } else {

            //     console.log("周五",startTime,endTime)
            //     dateData = []
            //     this.$emit('datePickerBlur', [])
            // }
        },
        checkTime(startTime, endTime) {
            let start = new Date(startTime);
            let end = new Date(endTime);
            let status = true;
            let endFullYear = end.getFullYear();
            let startFullYear = start.getFullYear();
            let endMonth = end.getMonth();
            let startMonth = start.getMonth();

            if (endFullYear != startFullYear) {
                if (endMonth + 12 - startMonth > 6) {
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    status = false;
                }

                if (endMonth + 12 - startMonth == 6) {
                    if (end.getDate() > start.getDate()) {
                        this.$message.error("开始时间和结束时间不能超过6个月");
                        status = false;
                    }
                }
            }
            if (end.getMonth() - start.getMonth() > 6) {
                this.$message.error("开始时间和结束时间不能超过6个月");
                return (status = false);
            } else if (end.getMonth() - start.getMonth() == 6) {
                if (end.getDate() > start.getDate()) {
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    return (status = false);
                }
            }
            return status;
        }
     }
    }
</script>

<style lang="scss" scoped>
    .filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 36px;
        // height: 36px;
        // height: 100px;
        // background-color: red;
        .el-dropdown-link {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #878787;
            .el-icon-caret-bottom {
                color: #878787;
            }
        }
        .line {
            width: 1px;
            height: 24px;
            margin: 0 24px;
            background-color: #DBDBDB;

        }
        .select-class {
            display: flex;
            align-items: center;
            .label {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #878787;
                margin-right: 10px;
            }
        }
        .select-week {
            display: flex;
            align-items: center;
            .label {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #878787;
                margin-right: 10px;
            }
        }
        .select-week.current {
            margin-right: auto;
        }
        .select-status {
            display: flex;
            width: 68px;
            height: 32px;
            align-items: center;
            box-sizing: border-box;
            padding: 3px;
            background: #F2F2F2;
            border-radius: 6px;
            margin-left: 24px;
            margin-right: auto;
            .item {
                width: 30px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                border-radius: 2px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #A6A6A6;
                cursor: pointer;
            }
            .item.current {
                background-color: #fff;
                color: #595959;
            }
        }
        .isRepeat{
            color: gray;
        }
        .isRepeat:hover {
            color: #3C7FFF;
        }

    }

</style>
